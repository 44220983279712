import React, {useEffect, useMemo, useRef, useState} from "react";
import {
	Anchor,
	Box, Button,
	Center,
	Group, Highlight, Image, Modal,
	NumberFormatter, RangeSlider, ScrollArea,
	Space,
	Stack,
	Table,
	Tabs,
	Text, Transition, useMantineTheme
} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {
	extractMolecules,
	extractNodes,
	formatPc,
	getConnectedEdges,
	getEntityById,
	getColorByThreshold,
	INGREDIENTS_THRESHOLDS,
	MOLECULES_THRESHOLDS,
	getDataByThreshold,
	getNodeById,
	extractSharedMolecules,
	toEdge,
	extractEntitiesNotInNodes,
	extractEntityPairings, COMPATIBILITY_MARKS, getSharedMoleculesPc, searchEntities
} from "./StudioUtils";
import i18n, {localized} from "../../i18n";
import {
	Pie,
	PieChart,
	PolarAngleAxis,
	PolarGrid,
	PolarRadiusAxis,
	Radar,
	RadarChart,
	ResponsiveContainer,
	Sector
} from "recharts";
import {ScrollTab} from "../../components/scrollTab/ScrollTab";
import classes from "./Studio.module.css";
import {SelectionChangeMemo} from "./SelectionChangeMemo";
import {entityCategoryNavigate} from "../ingredient/EntityCategoryLink";
import {useNavigate} from "react-router-dom";
import {Icon} from "../../components/icons/Icons";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {SearchInput} from "../../components/search/Search";
import {alphabeticComparator} from "../../util/utils";
import {moleculeIdNavigate} from "../molecule/MoleculeLink";
import {ScrollableArea} from "./ScrollableArea";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGripVertical, faImage} from "@fortawesome/free-solid-svg-icons";
import {useDnDContext} from "./DnDContext";
import {Flexible} from "./Flexible";
import {ingredientNavigate, ingredientNavigateHref} from "../ingredient/IngredientLink";
import {Paginator} from "../../components/paginator/Paginator";
import {paginationEndSkip, paginationStartSkip} from "../../util/pagination";
import {useDisclosure} from "@mantine/hooks";
import useGenerateRecipe from "./useGenerateRecipe";
import {useReactFlow} from "@xyflow/react";
import useEntityCategoryPairingAll from "../ingredient/useEntityCategoryPairingAll";

/**
 * Details
 */
export const Details = ({ entities, nodes, edges, selection, maxItems = 10 }) => {

	const [tabValue, setTabValue] = useState("0");
	const [tabChange, setTabChange] = useState(Date.now());

	const {t} = useTranslation();

	const [entitiesTabTitle, setEntitiesTabTitle] = useState("");
	const [entityTabTitle, setEntityTabTitle] = useState("");
	const [moleculesTabTitle, setMoleculesTabTitle] = useState("");

	/**
	 * Node
	 */
	const node = useMemo(() => {
		const result = selection.nodes.length === 1 ? nodes.find((node) => node.id === selection.nodes[0].id) : undefined;

		if(result === undefined && tabValue === "1") {
			setTabValue("0");
		}

		if(result !== undefined && tabValue === "0") {
			setTabValue("1");
		}

		return result;
	}, [nodes, selection]);

	/**
	 * Edge
	 */
	const edge = useMemo(() => {
		return selection.edges.length === 1 && selection.nodes.length === 0 ? selection.edges[0] : undefined;
	}, [edges, selection]);

	/**
	 * SelectedNodes
	 */
	const selectedNodes = useMemo(() => {

		if(edge === undefined) {
			return selection.nodes?.length > 0 ? selection.nodes : nodes;
		}

		return [getNodeById(nodes, edge.source), getNodeById(nodes, edge.target)]
	}, [nodes, edges, selection]);

	/**
	 * Molecules
	 */
	const molecules = useMemo(() => {

		if(edge === undefined) {
			return extractMolecules(entities, nodes, selectedNodes);
		}

		return extractSharedMolecules(getEntityById(entities, edge.source), getEntityById(entities, edge.target));

	}, [entities, nodes, edges, selection]);

	/**
	 * onTabChange
	 */
	const onTabChange = (value) => {
		setTabValue(value);
		setTabChange(Date.now());
	}

	return (
		<Tabs defaultValue="0" value={tabValue} h={"100%"} pb={38}>

			<ScrollTab mt={0} height={38} bg={"var(--mantine-color-tertiary-light-hover)"} /*justify={"flex-start"}*/
					   scrollbarClassNames= {{
						   scrollbar: classes.scrollbar,
						   corner: classes.corner,
						   thumb:classes.thumb
					   }}
			>
				{node === undefined &&
					<ScrollTab.Tab selected={tabValue === "0"} onClick={() => onTabChange("0")} classNames={{root: classes.detailsscrolltabtabroot}}>
						{entitiesTabTitle}
					</ScrollTab.Tab>
				}
				{node !== undefined &&
					<ScrollTab.Tab selected={tabValue === "1"} onClick={() => onTabChange("1")} classNames={{root: classes.detailsscrolltabtabroot}}>
						{entityTabTitle}
					</ScrollTab.Tab>
				}
				<ScrollTab.Tab selected={tabValue === "2"} onClick={() => onTabChange("2")} classNames={{root: classes.detailsscrolltabtabroot}}>
					{moleculesTabTitle}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "emotions"} onClick={() => onTabChange("emotions")} classNames={{root: classes.detailsscrolltabtabroot}}>
					{t(`molecule.emotions`)}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "flavors"} onClick={() => onTabChange("flavors")} classNames={{root: classes.detailsscrolltabtabroot}}>
					{t(`molecule.flavors`)}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "odors"} onClick={() => onTabChange("odors")} classNames={{root: classes.detailsscrolltabtabroot}}>
					{t(`molecule.odors`)}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "tastes"} onClick={() => onTabChange("tastes")} classNames={{root: classes.detailsscrolltabtabroot}}>
					{t(`molecule.tastes`)}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "compounds"} onClick={() => onTabChange("compounds")} classNames={{root: classes.detailsscrolltabtabroot}}>
					{t(`molecule.compounds`)}
				</ScrollTab.Tab>
			</ScrollTab>

			<Tabs.Panel value="0" w={"100%"} h={"100%"}>
				<Entities entities={entities} nodes={nodes} edges={edges} selection={selection} selectedNodes={selectedNodes} node={node} edge={edge} tabChange={tabChange}
								  onChange={(value) => setEntitiesTabTitle(value)}/>
			</Tabs.Panel>

			<Tabs.Panel value="1" w={"100%"} h={"100%"}>
				<Entity entities={entities} nodes={nodes} edges={edges} selection={selection} node={node} tabChange={tabChange}
						onChange={(value) => setEntityTabTitle(value)}/>
			</Tabs.Panel>

			<Tabs.Panel value="2" w={"100%"} h={"100%"}>
				<SelectionChangeMemo selection={selection}>
					<Molecules molecules={molecules} entities={entities} nodes={nodes} selectedNodes={selectedNodes} edge={edge} tabChange={tabChange}
										 onChange={(value) => setMoleculesTabTitle(value)}/>
				</SelectionChangeMemo>
			</Tabs.Panel>

			<Tabs.Panel value="emotions" w={"100%"} h={"100%"}>
				<SelectionChangeMemo selection={selection} other={tabChange}>
					<MolecularChart molecules={molecules} attribute={"emotions"} tabChange={tabChange} />
				</SelectionChangeMemo>
			</Tabs.Panel>

			<Tabs.Panel value="flavors" w={"100%"} h={"100%"}>
				<SelectionChangeMemo selection={selection} other={tabChange}>
					<MolecularChart molecules={molecules} attribute={"flavors"} tabChange={tabChange} />
				</SelectionChangeMemo>
			</Tabs.Panel>

			<Tabs.Panel value="odors" w={"100%"} h={"100%"}>
				<SelectionChangeMemo selection={selection} other={tabChange}>
					<MolecularChart molecules={molecules} attribute={"odors"} tabChange={tabChange} />
				</SelectionChangeMemo>
			</Tabs.Panel>

			<Tabs.Panel value="tastes" w={"100%"} h={"100%"}>
				<SelectionChangeMemo selection={selection} other={tabChange}>
					<MolecularChart molecules={molecules} attribute={"tastes"} tabChange={tabChange} />
				</SelectionChangeMemo>
			</Tabs.Panel>

			<Tabs.Panel value="compounds" w={"100%"} h={"100%"}>
				<SelectionChangeMemo selection={selection} other={tabChange}>
					<MolecularChart molecules={molecules} attribute={"compounds"} tabChange={tabChange} />
				</SelectionChangeMemo>
			</Tabs.Panel>
		</Tabs>
	);
}

/**
 * simpleBackgroundStyle
 */
const simpleBackgroundStyle = {
	backgroundColor: "var(--mantine-color-tertiary-outline-hover)",
	borderRadius: "var(--mantine-radius-md)",
	padding: "var(--mantine-spacing-md)"
}

/**
 * calculateAverageSharedMoleculesPc
 */
function calculateAverageSharedMoleculesPc(edges) {

	if (!edges || edges.length === 0) {
		return 0;
	}

	// Sum all sharedMoleculesPc values
	const total = edges.reduce((sum, edge) => {
		const sharedMoleculesPc = edge?.data?.sharedMoleculesPc || 0; // Fallback a 0 se non esiste
		return sum + sharedMoleculesPc;
	}, 0);

	return total / edges.length;
}

/**
 * getAverageSharedMoleculesPcColor
 * Restituisce il colore basato sulla percentuale media di molecole condivise.
 *
 * @returns {string} - Il colore associato alla percentuale media di molecole condivise.
 */
function getAverageSharedMoleculesPcColor(averageSharedMoleculesPc) {
	return getColorByThreshold(averageSharedMoleculesPc, MOLECULES_THRESHOLDS);
}

/**
 * GenerateRecipe
 */
const GenerateRecipe = ({}) => {

	const [loading, { toggle }] = useDisclosure();

	const [opened, { open, close }] = useDisclosure(false, {
		// onOpen: () => console.log('Opened'),
		// onClose: () => console.log('Closed')
	});

	const [message, setMessage] = useState(undefined);
	const [comment, setComment] = useState(undefined);
	const [generatedRecipe, setGeneratedRecipe] = useState(undefined);

	const {t} = useTranslation();

	const { getNodes, getEdges } = useReactFlow();

	const {reset: resetGenerateRecipe, refetch: refetchGenerateRecipe} =
		useGenerateRecipe({
			message: message,
			onSuccess: (data) => {

				console.log(data);

				setMessage(undefined);
				setComment(undefined);
				setGeneratedRecipe(data);

				// Toggle loading
				toggle();

				// Open modal
				open();
			},
			onError: (error) => {

				// Check if another run is in progress
				if(error.status !== 409) {

					console.error(error);

					setMessage(undefined);
					setComment(undefined);
					setGeneratedRecipe(undefined);

					// Toggle loading
					toggle();
				}
			}
		})

	useEffect(() => {
		setMessage(undefined);
		setComment(undefined);
		setGeneratedRecipe(undefined);
	}, [getNodes, getEdges]);

	useEffect(() => {

		if(message !== undefined) {
			refetchGenerateRecipe();
		}

	}, [message]);

	/**
	 * generateRecipe
	 */
	function generateRecipe() {

		const message = {
			language: i18n.language,
			ingredients: getNodes()
				.map(node => node?.data?.entity?.ingredientName) // Extract ingredientName
				.sort((a, b) => a.localeCompare(b)),
			pairings: getEdges()
				.map(edge => {
					const sourceNode = getNodes().find(node => node.id === edge.source);
					const targetNode = getNodes().find(node => node.id === edge.target);

					return {
						source: sourceNode?.data?.entity?.ingredientName,
						target: targetNode?.data?.entity?.ingredientName,
						compatibility: edge.data.sharedMoleculesPc
					};
				}),
			prompt: `Use the provided ingredients and their pairings to create a recipe in schema.org format. Possible additional ingredients must come from the ingredients.json file.`,
			comment: comment || "usa una riduzione per olio di oliva e aglio" // TODO remove this text
		}

		setMessage(JSON.stringify(message));

		// Start loading
		toggle();
	}

	return (
		<>
			<Modal opened={opened}
				   color={"tertiary"}
				   centered
				   closeOnClickOutside
				   withCloseButton={false}
				   size={"xl"}
				   overlayProps={{color: `var(--mantine-color-tertiary-12)`, backgroundOpacity: 0.75, blur: 7}}
				   zIndex={404}
				   onClose={close}
				   classNames={{
					   // root: classes.modalroot,
					   header: classes.modalheader,
					   // content: classes.modalcontent,
					   // inner: classes.modalinner,
					   body: classes.modalbody,
				   }}
			>
				<ScrollArea h={"100%"}>
					<Text size={"xs"}>
						<pre style={{textWrap: "wrap"}}>{JSON.stringify(generatedRecipe, null, 4)}</pre>
					</Text>
				</ScrollArea>

			</Modal>


			<Button loading={loading} onClick={generateRecipe}>{t("studio.generateRecipe")}</Button>
		</>
	);
}

/**
 * Entities
 */
const Entities = ({ entities, nodes, edges, selection, selectedNodes, node, edge, tabChange, onChange = () => {}, maxItems = 10 }) => {

	const navigate = useNavigate();
	const { t } = useTranslation();

	const [selectedEntityCategories, setSelectedEntityCategories] = useState([]);
	const [selectedEntities, setSelectedEntities] = useState([]);

	const [selectedEdges, setSelectedEdges] = useState([]);
	const [averageSharedMoleculesPc, setAverageSharedMoleculesPc] = useState(0);

	const [activeIndex, setActiveIndex] = useState(undefined);

	const scrollAreaRef = useRef(null);

	console.debug("Details.Entities.update")

	/**
	 * Finds all selected entities that belong to a specific category ID.
	 * @param {number} entityCategoryId - The ID of the entity category to search for.
	 * @returns {Array} - The filtered array of entities belonging to the specified category.
	 */
	function findEntitiesByCategory(entityCategoryId) {
		return selectedEntities.filter(entity =>
			entity.categories.some(category => category.entityCategoryId === entityCategoryId)
		);
	}

	useEffect(() => {

		setActiveIndex(undefined);

		// Extract nodes from selected nodes
		const extractedNodes = extractNodes(nodes, selectedNodes);

		// Extract entities for the relevant nodes
		const selectedEntities = extractedNodes
			.map((node) => getEntityById(entities, node.id))
			.filter((entity) => !!entity);

		// Sort selected entities
		selectedEntities.sort((a, b) => {
			return localized(a.representativeIngredient, "name").localeCompare(localized(b.representativeIngredient, "name"));
		});

		// Create a Map to aggregate category counts
		const categoryMap = new Map();

		selectedEntities.forEach((entity) => {
			entity.categories.forEach((category) => {
				const categoryId = category.entityCategoryId;

				// If the category already exists in the map, update its count
				if (categoryMap.has(categoryId)) {
					const existing = categoryMap.get(categoryId);
					categoryMap.set(categoryId, {
						...existing,
						count: existing.count + 1,
					});
				}
				else {
					// Otherwise, initialize the category in the map
					categoryMap.set(categoryId, {
						entityCategoryId: categoryId,
						name: localized(category, "name"),
						count: 1,
						pc: 0, // Will calculate this later
					});
				}
			});
		});

		// Calculate the percentage for each category
		const totalEntities = selectedEntities.length;
		const selectedEntityCategories = Array.from(categoryMap.values()).map((category) => ({
			...category,
			pc: ((category.count / totalEntities) * 100),
		}))
			.sort((a, b) => a.name.localeCompare(b.name));

		// Update the state
		setSelectedEntityCategories(selectedEntityCategories);
		setSelectedEntities(selectedEntities);

		// Get selected edges or all edges
		let selectedEdges = edge === undefined ? getConnectedEdges(edges, extractedNodes) : [edge] ;

		// Update the state
		setSelectedEdges(selectedEdges);
		setAverageSharedMoleculesPc(calculateAverageSharedMoleculesPc(selectedEdges));

	}, [entities, nodes, edges, selectedNodes]);

	useEffect(() => {

		if(onChange) {
			onChange(getTitle());
		}

	}, [selectedEntityCategories, selectedEntities, edge]);

	useEffect(() => {
		scrollToTop();
	}, [tabChange]);

	/**
	 * @param props
	 * @returns {Element}
	 */
	const renderLabel = (props) => {
		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, index } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';

		const wrapText = (text, maxCharsPerLine = 10) => {
			const words = text.split(" ");
			let lines = [];
			let currentLine = "";

			words.forEach((word) => {
				// If adding the current word doesn't exceed the limit, let's add it to the current line.
				if ((currentLine + word).length <= maxCharsPerLine) {
					currentLine += word + " ";
				} else {
					// Otherwise, we close the current line and start a new one.
					lines.push(currentLine.trim());
					currentLine = word + " ";
				}
			});

			// We add the last line
			lines.push(currentLine.trim());

			return lines;
		};

		return (
			<g>
				{index === activeIndex &&

						<Sector
							cx={cx}
							cy={cy}
							startAngle={startAngle}
							endAngle={endAngle}
							innerRadius={outerRadius + 8}
							outerRadius={outerRadius + 10}
							fill={"var(--mantine-color-tertiary-6)"}
							style={{cursor: 'pointer'}}
							cornerRadius={1}
						/>

				}
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={index === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"} strokeWidth={1}
					  strokeDasharray="3 3" fill="none"/>
				{/*<circle cx={ex} cy={ey} r={3} fill={"white"} stroke="none"/>*/}
				<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={5} textAnchor={textAnchor} fontSize={14} fontWeight={700}
					  fill={index === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}>
					{payload.name}
				</text>
				<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={20} textAnchor={textAnchor} fontSize={12}
					  fill={index === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}>
					{formatPc(percent * 100)}
				</text>

			</g>
		);
	};

	/**
	 * getIngredients
	 */
	function getIngredients(index) {

		if(index === undefined) {
			return selectedEntities.length;
		}

		return selectedEntityCategories[index].count;
	}

	/**
	 * getIngredientDifficulty
	 */
	function getIngredientDifficulty() {
		return getDataByThreshold(getIngredients(), INGREDIENTS_THRESHOLDS).difficulty;
	}

	/**
	 * scrollToTop
	 */
	const scrollToTop = () => {

		if (scrollAreaRef?.current !== undefined) {
			setTimeout(() => {
				try {
					scrollAreaRef.current.scrollTop = 0;
				}
				catch(e) {
					// noop
				}
			}, 100);
		}
	};

	/**
	 * getTitle
	 */
	function getTitle() {

		if(node !== undefined) {
			return `${node.data.label}`;
		}

		if(edge !== undefined) {
			return `${selectedNodes[0].data.label} - ${selectedNodes[1].data.label}`;
		}

		if(selection.onPane) {
			return t("studio.workSurface");
		}

		return `${getIngredients()} ${t("common.ingredients")} ${getSubtitle() !== undefined ? getSubtitle() : ""}`;
	}

	/**
	 * getSubtitle
	 */
	function getSubtitle() {

		if(selection.nodes.length > 0) {
			return t("studio.selected");
		}

		if(edge !== undefined) {
			return t("studio.compatible")
		}

		return undefined;
	}

	/**
	 * Subtitle
	 */
	const Subtitle = () => {

		return getSubtitle() !== undefined ?
			<Text c={activeIndex !== undefined ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}>
				{getSubtitle()}
			</Text>
			:
			<Text>
				&nbsp;
			</Text>
	}

	return (
		<Stack justify="space-between" align={"stretch"} grow bg={"var(--mantine-color-tertiary-outline-hover)"} w={"100%"} h={"100%"} gap={"md"} p={"md"}>

			<Stack align="stretch" justify="space-between" h={"100%"}>

				<Stack flex={1} style={{...simpleBackgroundStyle, position: "relative"}}
					   onMouseLeave={() => setActiveIndex(undefined)}>

					<Stack align="center" justify="flex-start" gap={0}
						   style={{position: "absolute", top: "50%", left: "50%", transform:"translate(-50%, -50%)"}}>
						<Text size={"42px"} fw={700} c={activeIndex !== undefined ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}>
							<NumberFormatter thousandSeparator="'" value={getIngredients(activeIndex)}/>
						</Text>
						<Text c={activeIndex !== undefined ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}>
							{t("common.ingredients")}
						</Text>
						<Subtitle />
					</Stack>

					<ResponsiveContainer width="100%" height="100%">
						<PieChart>
							<Pie
								data={selectedEntityCategories}
								cx="50%"
								cy="50%"
								innerRadius={"58%"}
								outerRadius={"70%"}
								fill={"var(--mantine-color-tertiary-9)"}
								dataKey="count"
								paddingAngle={1}
								strokeWidth={0}
								isAnimationActive={false}
								startAngle={90}
								endAngle={-270}
								cornerRadius={1}
								activeIndex={activeIndex}
								activeShape={(props) => <Sector {...props} style={{cursor: 'pointer'}}
																fill="var(--mantine-color-tertiary-6)"/>}
								onMouseEnter={(_, index) => setActiveIndex(index)}
								onMouseLeave={() => setActiveIndex(undefined)}
								onClick={(data, index) => {
									entityCategoryNavigate(navigate, data, "_blank");
								}}
								label={renderLabel}
							/>
						</PieChart>
					</ResponsiveContainer>
				</Stack>

				<Group align="flex-start" justify="space-between" c={"var(--mantine-color-tertiary-9)"} wrap={"nowrap"}
					   style={simpleBackgroundStyle}>

					{edge === undefined &&
						<Stack align="stretch" justify="flex-start" gap={0} w={selection.onPane ? "33%" : "50%"}>
							<Text size={"42px"} fw={700} ta={"center"} lineClamp={1} c={"var(--mantine-color-tertiary-9)"}>{selectedEdges.length}</Text>
							<Text size={"sm"} ta={"center"} opacity={1} c={"var(--mantine-color-tertiary-9)"}>
								{t("studio.pairings")}
							</Text>
						</Stack>
					}
					<Stack align="stretch" justify="flex-start" gap={0} w={selection.onPane ? "33%" : edge === undefined ? "50%" : "100%"}>
						<Text size={"42px"} fw={700} ta={"center"} lineClamp={1} c={averageSharedMoleculesPc > 0 ? getAverageSharedMoleculesPcColor(averageSharedMoleculesPc) : "var(--mantine-color-tertiary-9)"}>
							{formatPc(averageSharedMoleculesPc)}
						</Text>
						<Text size={"sm"} ta={"center"} opacity={1} c={averageSharedMoleculesPc > 0 ? getAverageSharedMoleculesPcColor(averageSharedMoleculesPc) : "var(--mantine-color-tertiary-9)"}>
							{t("ingredient.compatibility")}
						</Text>
					</Stack>
					{selection.onPane &&
						<Stack align="stretch" justify="flex-start" gap={0} w={"33%"}>
							<Center>
								<Icon name={getIngredientDifficulty()} style={{width: "42px", height: "42px", padding: "6px", marginBottom: "-7px", fill: "var(--mantine-color-tertiary-9)"}}/>
							</Center>
							<Text size={"sm"} ta={"center"} opacity={1} c={"var(--mantine-color-tertiary-9)"}>
								{t("recipe.difficulty")}
							</Text>
						</Stack>
					}
				</Group>

			</Stack>

			<Flexible>

				{selection.onPane &&
					<Flexible.Fixed>
						<Stack align="flex-start" justify="center" gap={0} style={simpleBackgroundStyle}>
							<GenerateRecipe entities={entities} />
						</Stack>
						<Space h={"md"}/>
					</Flexible.Fixed>
				}

				<Flexible.Expandable>

					<ScrollableArea
						viewportRef={scrollAreaRef}
						style={simpleBackgroundStyle}
					>
						<StripedTable striped={false} stickyHeader className={classes.detailsentitiestable}>
							<Table.Thead>
								<Table.Tr>
									<Table.Th>
										{t("studio.entityCategoriesAndIngredients")}
									</Table.Th>
									<Table.Th ta={"right"} w={"25%"}>
										{t("studio.count")}
									</Table.Th>
									<Table.Th ta={"right"} w={"25%"}>
										%
									</Table.Th>
								</Table.Tr>
							</Table.Thead>
							<Table.Tbody>
								{selectedEntityCategories.map((selectedEntityCategory, selectedEntityCategoryIndex) => (
									<>
										<Table.Tr className={classes.detailsentitiestablecolored} style={{color: selectedEntityCategoryIndex === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}}>
											<Table.Td>
												{selectedEntityCategory.name}
											</Table.Td>
											<Table.Td ta={"right"}>
												{selectedEntityCategory.count}
											</Table.Td>
											<Table.Td ta={"right"}>
												{formatPc(selectedEntityCategory.pc)}
											</Table.Td>
										</Table.Tr>
										{findEntitiesByCategory(selectedEntityCategory.entityCategoryId).map((entity, entityIndex) => (
											<Table.Tr className={classes.detailsentitiestablesmall} style={{color: selectedEntityCategoryIndex === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}}>
												<Table.Td colSpan={3}>
													{localized(entity.representativeIngredient, "name")}
												</Table.Td>
											</Table.Tr>
										))}
									</>
								))}
							</Table.Tbody>
						</StripedTable>
					</ScrollableArea>
				</Flexible.Expandable>
			</Flexible>
		</Stack>
	);
};

/**
 * ImageWithZoom
 */
const ImageWithZoom = ({node}) => {

	const [hovered, setHovered] = useState(false);

	return !node || !node.data || !node.data?.wiki ? null :
		<Box className={classes.detailsimagewithzoomroot}
			 onMouseEnter={() => setHovered(node.data?.wiki.url ? true : false)}
			 onMouseLeave={() => setHovered(false)}
			 style={{cursor: node.data?.wiki.url ? "pointer" : "auto"}}
			 onClick={() => node.data?.wiki.url && window.open(node.data?.wiki.url, "_blank")}
			>
			{node.data?.wiki.image ?
				<Transition
					mounted={true}
					transition="scale"
					timingFunction="ease-in-out">
					{(styles) => (
						<Image
							src={node.data?.wiki.image}
							className={classes.detailsimagewithzoomimage}
							style={{
								transform: hovered? 'scale(1.1)' : 'scale(1)',  // Scale on hover
								transition: 'transform 0.15s ease-in-out',  // Smooth transition
							}}
						/>

					)}
				</Transition>
				:
				<Box className={classes.detailsimagewithzoomnoimage}>
					<FontAwesomeIcon icon={faImage} size={"8x"}/>
				</Box>
			}
			{node.data.wiki?.extract &&
				<Stack gap={0} justify="flex-end" className={classes.detailsimagewithzoomtitle} p={"md"} bg={"rgba(225, 241, 247, 1)"}>
					<Text size={"xs"} lineClamp={3} c={"var(--mantine-color-tertiary-9)"}>{node.data.wiki.extract}</Text>
				</Stack>
			}
		</Box>
};

/**
 * EntityCategoryPairings
 */
const EntityCategoryPairings = ({entity}) => {

	const [data, setData] = useState([]);

	const [activeIndex, setActiveIndex] = useState(undefined);

	const { t } = useTranslation();

	const { data: dataEntityCategoryPairings, refetch: refetchEntityCategoryPairings} =
		useEntityCategoryPairingAll({
			entityId: entity?.entityId,
			onSuccess: (data, totalCount, count) => {

				setActiveIndex(undefined);

				setData(data
					.sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
					.map((entityCategoryPairing, index) => {
						return {
							name: localized(entityCategoryPairing, "name"),
							sharedMolecules: entityCategoryPairing.sharedMolecules,
							sharedMoleculesPc: entityCategoryPairing.sharedMoleculesPc
						}
					}));
			}
		})

	useEffect(() => {

		if (entity !== undefined) {
			refetchEntityCategoryPairings();
		}
	}, [entity]);

	/**
	 * @param props
	 * @returns {Element}
	 */
	const renderLabel = (props) => {
		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, index } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';

		const wrapText = (text, maxCharsPerLine = 10) => {
			const words = text.split(" ");
			let lines = [];
			let currentLine = "";

			words.forEach((word) => {
				// If adding the current word doesn't exceed the limit, let's add it to the current line.
				if ((currentLine + word).length <= maxCharsPerLine) {
					currentLine += word + " ";
				} else {
					// Otherwise, we close the current line and start a new one.
					lines.push(currentLine.trim());
					currentLine = word + " ";
				}
			});

			// We add the last line
			lines.push(currentLine.trim());

			return lines;
		};

		return (
			<g>
				{index === activeIndex &&

					<Sector
						cx={cx}
						cy={cy}
						startAngle={startAngle}
						endAngle={endAngle}
						innerRadius={outerRadius + 8}
						outerRadius={outerRadius + 10}
						fill={"var(--mantine-color-tertiary-6)"}
						style={{cursor: 'pointer'}}
						cornerRadius={1}
					/>

				}
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={index === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"} strokeWidth={1}
					  strokeDasharray="3 3" fill="none"/>
				{/*<circle cx={ex} cy={ey} r={3} fill={"white"} stroke="none"/>*/}
				<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={5} textAnchor={textAnchor} fontSize={14} fontWeight={700}
					  fill={index === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}>
					{payload.name}
				</text>
				<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={20} textAnchor={textAnchor} fontSize={12}
					  fill={index === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}>
					{formatPc(payload.sharedMoleculesPc)}
				</text>

			</g>
		);
	};

	return data.length > 0 &&
		<Flexible>
			<Flexible.Expandable>
		<Stack h={"100%"} style={{...simpleBackgroundStyle, position: "relative"}}
			   onMouseLeave={() => setActiveIndex(undefined)}>

			<Stack align="center" justify="flex-start" gap={0}
				   style={{position: "absolute", top: "50%", left: "50%", transform:"translate(-50%, -50%)"}}>
				<Text size={"42px"} fw={700} c={"var(--mantine-color-tertiary-9)"}>
					<NumberFormatter thousandSeparator="'" value={data?.length}/>
				</Text>
				<Text c={"var(--mantine-color-tertiary-9)"}>
					{t("studio.entityCategories")}
				</Text>
			</Stack>

			<ResponsiveContainer width="100%" height="100%">
				<PieChart>
					<Pie
						data={data}
						cx="50%"
						cy="50%"
						innerRadius={"58%"}
						outerRadius={"70%"}
						fill={"var(--mantine-color-tertiary-9)"}
						dataKey="sharedMolecules"
						paddingAngle={1}
						strokeWidth={0}
						isAnimationActive={false}
						startAngle={90}
						endAngle={-270}
						cornerRadius={1}
						activeIndex={activeIndex}
						activeShape={(props) => <Sector {...props} style={{cursor: 'pointer'}}
														fill="var(--mantine-color-tertiary-6)"/>}
						onMouseEnter={(_, index) => setActiveIndex(index)}
						onMouseLeave={() => setActiveIndex(undefined)}
						onClick={(data, index) => {
							// if(onClick) {
							// 	onClick(data.attribute);
							// }
						}}
						label={renderLabel}
					/>
				</PieChart>
			</ResponsiveContainer>
		</Stack>
			</Flexible.Expandable>
		</Flexible>
}

/**
 * Entity
 */
const Entity = ({entities, nodes, edges, node, selection, onChange = () => {}, isOnlyInputConnectable = false}) => {

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");
	const [searchItems, setSearchItems] = useState([]);

	const [minCompatibility, setMinCompatibility] = useState(undefined);
	const [maxCompatibility, setMaxCompatibility] = useState(undefined);

	const [moleculesIds, setMoleculesIds] = useState([]);
	const [entityPairings, setEntityPairings] = useState([]);
	const [draggable, setDraggable] = useState(false);

	const [lastSelectionTimestamp, setLastSelectionTimestamp] = useState(Date.now())

	const navigate = useNavigate();
	const {t} = useTranslation();
	const theme = useMantineTheme();

	const [n, setNode, e, setEdge] = useDnDContext();

	/**
	 * Creates a custom drag image for the drag-and-drop operation.
	 * This function dynamically generates an element to act as the drag image
	 * and aligns it with the cursor during the drag event.
	 *
	 * @param {DragEvent} event - The drag event object.
	 * @param {string} content - The content (HTML or text) to display in the drag image.
	 * @param {object} styles - Optional custom styles to apply to the drag image.
	 */
	const createDragImage = (event, content, styles = {}) => {

		// 1. Create a temporary div element to serve as the drag image
		const dragImage = document.createElement("div");

		// 2. Default styles for the drag image to make it visually appealing and hidden initially
		const defaultStyles = {
			position: "absolute",    // Ensures the element does not affect document flow
			top: "-9999px",          // Position off-screen initially to avoid flicker
			left: "-9999px",
			fontSize: "14px",        // Default font size
			padding: "var(--mantine-spacing-sm)", // Padding for spacing inside the box
			color: "var(--mantine-color-white)", // Text color
			backgroundColor: "var(--mantine-color-primary-6)", // Background color
			borderRadius: "4px",     // Rounded corners for the drag image
			whiteSpace: "nowrap",    // Prevents text wrapping
			...styles,               // Merge with any custom styles provided
		};

		// 3. Apply the default and custom styles to the drag image element
		Object.assign(dragImage.style, defaultStyles);

		// 4. Set the inner content of the drag image (text or HTML)
		dragImage.innerHTML = content;

		// 5. Append the drag image to the body to ensure it is part of the DOM
		document.body.appendChild(dragImage);

		// 6. Calculate the dimensions of the drag image to center it relative to the cursor
		const { offsetWidth, offsetHeight } = dragImage;

		// 7. Set the drag image in the drag event, centering it horizontally and vertically
		event.dataTransfer.setDragImage(dragImage, offsetWidth / 2 + 12, offsetHeight / 2 + 12);

		// 8. Remove the drag image from the DOM after a short delay to clean up
		setTimeout(() => {
			document.body.removeChild(dragImage);
		}, 0);
	};

	/**
	 * onDragStart
	 */
	const onDragStart = (event, entity) => {

		if (node) {
			setNode({
				id: `${entity.entityId}`,
				type: "intermediate",
			});

			setEdge(toEdge(node.id, "default", entity, moleculesIds));
		}

		if (event.dataTransfer) {

			// Standard drag-and-drop
			event.dataTransfer.effectAllowed = "move";

			createDragImage(
				event,
				`${localized(entity.representativeIngredient, "name")}`, // Content
				{ backgroundColor: "var(--mantine-color-primary-6)", color: "white" }
			);
		}
	};

	useEffect(() => {

		if(lastSelectionTimestamp !== selection.timestamp) {

			console.debug("Details.Entity.reset")

			setLastSelectionTimestamp(selection.timestamp);

			setSearch("");
			setPage(1);
			setDraggable(false);
			setSearchItems([]);
			setMoleculesIds([]);
			setEntityPairings([]);
		}
	}, [selection]);

	useEffect(() => {

		try {

			console.debug("Details.Entity.reset")

			setDraggable(isOnlyInputConnectable ? node?.type === "input" : true);

			// Filter out entities already present on the canvas
			const entitiesNotInNodes = extractEntitiesNotInNodes(entities, nodes);

			// Get all selected entity molecules
			const moleculesIds = extractMolecules(entities, nodes, [node]).map((molecule) => molecule.moleculeId);

			// Extract pairing
			const entityPairings = extractEntityPairings(entitiesNotInNodes, moleculesIds);

			// Calculate min/max compatibility range
			const compatibilities = lastSelectionTimestamp !== selection.timestamp ?
				defaultCompatibilities(entityPairings, moleculesIds, COMPATIBILITY_MARKS) :
				[minCompatibility, maxCompatibility];

			setMinCompatibility(compatibilities[0]);
			setMaxCompatibility(compatibilities[1]);

			// Entity pairings filtered by min/max compatibility
			const compatibilityEntityPairings = entityPairings.filter(entityPairing => {
				const sharedMoleculesPc = getSharedMoleculesPc(entityPairing.sharedMolecules, moleculesIds);
				return sharedMoleculesPc >= (minCompatibility || compatibilities[0]) && sharedMoleculesPc <= (maxCompatibility || compatibilities[1]);
			});

			if(!search) {
				setSearchItems(compatibilityEntityPairings);
			}
			else {
				setSearchItems(searchEntities(compatibilityEntityPairings, search));
			}

			setMoleculesIds(moleculesIds);
			setEntityPairings(entityPairings);
		}
		catch (ignored) {
			// noop
		}

	}, [search, minCompatibility, maxCompatibility, entities, nodes.length, selection]);

	useEffect(() => {

		if(onChange) {
			onChange(node?.data.label);
		}

	}, [node]);

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 * @param minCompatibility
	 * @param maxCompatibility
	 */
	const onMinMaxCompatibility = (minCompatibility, maxCompatibility) => {
		setMinCompatibility(minCompatibility);
		setMaxCompatibility(maxCompatibility);
		setPage(1);
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 */
	function entityPairingsComparator(a, b) {

		// Inverted comparison
		const pcComparison = b.sharedMolecules - a.sharedMolecules;
		if (pcComparison !== 0) {
			return pcComparison;
		}

		return localized(a.representativeIngredient, "name").localeCompare(localized(b.representativeIngredient, "name"));
	}

	/**
	 * @param marks
	 * @param value
	 * @param type
	 * @returns {*}
	 */
	function defaultCompatibilities(compatibilities, moleculesIds, marks) {

		/**
		 * @param marks
		 * @param value
		 * @param type
		 * @returns {*}
		 */
		function normalize(marks, value, type) {

			for (let i = 0; i < marks.length - 1; i++) {

				if (value <= marks[i + 1].value) {
					// For the minimum, return the lower step
					if (type === 'min') {
						return marks[i].value;
					}
					// For the maximum, return the upper step
					if (type === 'max') {
						return marks[i + 1].value;
					}
				}
			}
		}

		// Extract shared molecules percentage
		const sharedMoleculesPc = compatibilities?.map(compatibility => getSharedMoleculesPc(compatibility.sharedMolecules, moleculesIds));

		// Fin min and max
		const minValue = Math.min(...sharedMoleculesPc);
		const maxValue = Math.max(...sharedMoleculesPc);

		const minValueNormalized = normalize(marks, minValue, 'min');
		const maxValueNormalized = normalize(marks, maxValue, 'max');

		return [minValueNormalized !== undefined ? minValueNormalized : 0, maxValueNormalized !== undefined ? maxValueNormalized : 0];
	}

	/**
	 * SelectedGroupNodes
	 */
	const selectedGroupNodes = useMemo(() => {

		if(node === undefined || node.type !== "group") {
			return [];
		}

		return nodes.filter(n => n.parentId === node.id);
	}, [nodes, node]);

	/**
	 * SelectedGroup
	 */
	const selectedGroup = useMemo(() => {

		if(node === undefined || node.parentId === undefined) {
			return undefined;
		}

		return nodes.find(n => n.id === node.parentId);
	}, [nodes, node]);

	/**
	 * SelectedEntity
	 */
	const selectedEntity = useMemo(() => {

		if(node === undefined) {
			return undefined;
		}

		return getEntityById(entities, node.id);
	}, [entities, node]);

	/**
	 * SelectedEntityCategory
	 */
	const selectedEntityCategory = useMemo(() => {

		if(selectedEntity === undefined || selectedEntity.categories.length === 0) {
			return undefined;
		}

		return selectedEntity.categories[0];
	}, [selectedEntity]);

	/**
	 * SelectedEdges
	 */
	const selectedEdges = useMemo(() => {

		if(node === undefined) {
			return [];
		}

		return getConnectedEdges(edges, [node]);
	}, [edges, node]);

	/**
	 * averageSharedMoleculesPc
	 */
	const averageSharedMoleculesPc = useMemo(() => {

		if(selectedEdges === undefined) {
			return 0;
		}

		return calculateAverageSharedMoleculesPc(selectedEdges);
	}, [selectedEdges]);

	/**
	 * SelectedConnectedNodes
	 */
	const selectedConnectedNodes = useMemo(() => {

		if(selectedEdges === undefined || selectedEdges.length === 0) {
			return [];
		}

		// Extract all unique IDs from source and target in selectedEdges
		const connectedNodeIds = new Set();

		selectedEdges.forEach(edge => {
			if (edge.source !== node.id) {
				connectedNodeIds.add(edge.source);
			}
			if (edge.target !== node.id) {
				connectedNodeIds.add(edge.target);
			}
		});

		// Filter nodes that match the collected IDs
		return nodes.filter(n => connectedNodeIds.has(n.id));
	}, [selectedEdges, node]);

	/**
	 * @param ingredient
	 */
	const getCategories = (ingredient) => {

		// Categories
		if(ingredient.categories && ingredient.categories.length > 0) {
			let categories = ingredient.categories.filter((category) => category.representative > 0)
			// return categories?.length > 0 ? categories[0] : ingredient.categories[0];
			return categories && categories.length > 0 ? categories : ingredient.categories;
		}
		// Single category
		else if (ingredient.category) {
			return [ingredient.category];
		}

		return [];
	}

	const [tabValue, setTabValue] = useState("0");

	return (

		<Stack justify="space-between" align={"stretch"} grow bg={"var(--mantine-color-tertiary-outline-hover)"} w={"100%"} h={"100%"} gap={"md"} p={"md"}>

			<Tabs defaultValue="0" value={tabValue} h={"100%"}>

				<ScrollTab mt={0} height={38} bg={"var(--mantine-color-tertiary-light-hover)"} /*justify={"flex-start"}*/
						   scrollbarClassNames= {{
							   scrollbar: classes.scrollbar,
							   corner: classes.corner,
							   thumb:classes.thumb
						   }}>

							<ScrollTab.Tab selected={tabValue === "0"} onClick={() => setTabValue("0")} classNames={{root: classes.detailsscrolltabtabroot}}>
								Details
							</ScrollTab.Tab>
					{selectedEntity &&
							<ScrollTab.Tab selected={tabValue === "1"} onClick={() => setTabValue("1")} classNames={{root: classes.detailsscrolltabtabroot}}>
								Categories
							</ScrollTab.Tab>

					}
				</ScrollTab>

				<Tabs.Panel value="0" w={"100%"}>

					<Stack align="stretch" justify="flex-start">
					{selectedEntity &&
						<ImageWithZoom node={node}/>
					}

					<Group align="flex-start" justify="space-between" c={"var(--mantine-color-tertiary-9)"} wrap={"nowrap"} grow>

						{selectedEntityCategory &&
							<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>
								<Text size={"sm"} fw={700} lineClamp={1} c={"var(--mantine-color-tertiary-9)"}>{t("common.entityCategory")}</Text>
								<Text size={"sm"}>
									<Anchor underline={"never"} c={"var(--mantine-color-tertiary-9)"} inherit onClick={() => entityCategoryNavigate(navigate, selectedEntityCategory, "_blank")}>
										{localized(selectedEntityCategory, "name")}
									</Anchor>
								</Text>
							</Stack>
						}

						{selectedEntity?.naturalSourceIngredient &&
							<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>
								<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>{t("ingredient.naturalSource")}</Text>
								<Text size={"sm"}>
									<Anchor underline={"never"} c={"var(--mantine-color-tertiary-9)"} inherit onClick={() => ingredientNavigate(navigate, selectedEntity.naturalSourceIngredient, "_blank")}>
										{localized(selectedEntity.naturalSourceIngredient, "name")}
									</Anchor>
								</Text>
							</Stack>
						}

						{selectedGroup &&
							<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>
								<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>{t("studio.group")}</Text>
								<Text size={"sm"} c={"var(--mantine-color-tertiary-9)"}>
									{selectedGroup.data.label}
								</Text>
							</Stack>
						}
					</Group>

					{selectedGroupNodes.length > 0 &&
						<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>
							<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>{t("studio.groupedIngredients")}</Text>
							<Text size={"sm"}  c={"var(--mantine-color-tertiary-9)"}>
								{selectedGroupNodes
									.sort((a, b) => a.data.label.toLowerCase().localeCompare(b.data.label.toLowerCase()))
									.map((selectedGroupNode, index) => selectedGroupNode.data.label).join(", ")
								}
							</Text>
						</Stack>
					}

					{selectedEntity?.synonymIngredients.length > 0 &&
						<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>
							<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>{t("studio.sameProperties")}</Text>
							<Text size={"sm"}>
								{selectedEntity.synonymIngredients
									.sort((a, b) => localized(a, "name").localeCompare(localized(b, "name")))
									.map((ingredient, index, array) => (
										<>
											<Anchor underline={"never"} c={"var(--mantine-color-tertiary-9)"} inherit href={ingredientNavigateHref(ingredient)} target={"_blank"}>{localized(ingredient, "name")}</Anchor>
											{index < array.length - 1 && ", "}
										</>
									))
								}
							</Text>
						</Stack>
					}

					{selectedConnectedNodes.length > 0 &&
						<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>
							<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>{t("studio.pairedIngredients")}</Text>
							<Text size={"sm"}  c={"var(--mantine-color-tertiary-9)"}>
								{selectedConnectedNodes
									.sort((a, b) => a.data.label.toLowerCase().localeCompare(b.data.label.toLowerCase()))
									.map((selectedConnectedNode, index) => selectedConnectedNode.data.label).join(", ")
								}
							</Text>
						</Stack>
					}
					</Stack>
				</Tabs.Panel>

				<Tabs.Panel value="1" w={"100%"} h={"100%"} pb={38}>
					<EntityCategoryPairings entity={selectedEntity} />
				</Tabs.Panel>

			</Tabs>

			<Stack align="stretch" justify="flex-start">

				{/*{selectedEntity &&*/}
				{/*	<ImageWithZoom node={node}/>*/}
				{/*}*/}

				{/*{selectedEntity &&*/}
				{/*	<EntityCategoryPairings entity={selectedEntity} />*/}
				{/*}*/}

				{/*<Group align="flex-start" justify="space-between" c={"var(--mantine-color-tertiary-9)"} wrap={"nowrap"} grow>*/}

				{/*	{selectedEntityCategory &&*/}
				{/*		<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>*/}
				{/*			<Text size={"sm"} fw={700} lineClamp={1} c={"var(--mantine-color-tertiary-9)"}>{t("common.entityCategory")}</Text>*/}
				{/*			<Text size={"sm"}>*/}
				{/*				<Anchor underline={"never"} c={"var(--mantine-color-tertiary-9)"} inherit onClick={() => entityCategoryNavigate(navigate, selectedEntityCategory, "_blank")}>*/}
				{/*					{localized(selectedEntityCategory, "name")}*/}
				{/*				</Anchor>*/}
				{/*			</Text>*/}
				{/*		</Stack>*/}
				{/*	}*/}

				{/*	{selectedEntity?.naturalSourceIngredient &&*/}
				{/*		<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>*/}
				{/*			<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>{t("ingredient.naturalSource")}</Text>*/}
				{/*			<Text size={"sm"}>*/}
				{/*				<Anchor underline={"never"} c={"var(--mantine-color-tertiary-9)"} inherit onClick={() => ingredientNavigate(navigate, selectedEntity.naturalSourceIngredient, "_blank")}>*/}
				{/*					{localized(selectedEntity.naturalSourceIngredient, "name")}*/}
				{/*				</Anchor>*/}
				{/*			</Text>*/}
				{/*		</Stack>*/}
				{/*	}*/}

				{/*	{selectedGroup &&*/}
				{/*		<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>*/}
				{/*			<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>{t("studio.group")}</Text>*/}
				{/*			<Text size={"sm"} c={"var(--mantine-color-tertiary-9)"}>*/}
				{/*				{selectedGroup.data.label}*/}
				{/*			</Text>*/}
				{/*		</Stack>*/}
				{/*	}*/}

				{/*</Group>*/}

				{/*{selectedGroupNodes.length > 0 &&*/}
				{/*	<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>*/}
				{/*		<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>{t("studio.groupedIngredients")}</Text>*/}
				{/*		<Text size={"sm"}  c={"var(--mantine-color-tertiary-9)"}>*/}
				{/*			{selectedGroupNodes*/}
				{/*				.sort((a, b) => a.data.label.toLowerCase().localeCompare(b.data.label.toLowerCase()))*/}
				{/*				.map((selectedGroupNode, index) => selectedGroupNode.data.label).join(", ")*/}
				{/*			}*/}
				{/*		</Text>*/}
				{/*	</Stack>*/}
				{/*}*/}

				{/*{selectedEntity?.synonymIngredients.length > 0 &&*/}
				{/*	<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>*/}
				{/*		<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>{t("studio.sameProperties")}</Text>*/}
				{/*		<Text size={"sm"}>*/}
				{/*			{selectedEntity.synonymIngredients*/}
				{/*				.sort((a, b) => localized(a, "name").localeCompare(localized(b, "name")))*/}
				{/*				.map((ingredient, index, array) => (*/}
				{/*					<>*/}
				{/*						<Anchor underline={"never"} c={"var(--mantine-color-tertiary-9)"} inherit href={ingredientNavigateHref(ingredient)} target={"_blank"}>{localized(ingredient, "name")}</Anchor>*/}
				{/*						{index < array.length - 1 && ", "}*/}
				{/*					</>*/}
				{/*				))*/}
				{/*			}*/}
				{/*		</Text>*/}
				{/*	</Stack>*/}
				{/*}*/}

				{/*{selectedConnectedNodes.length > 0 &&*/}
				{/*	<Stack align="flex-start" justify="flex-start" gap={0} style={simpleBackgroundStyle}>*/}
				{/*		<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>{t("studio.pairedIngredients")}</Text>*/}
				{/*		<Text size={"sm"}  c={"var(--mantine-color-tertiary-9)"}>*/}
				{/*			{selectedConnectedNodes*/}
				{/*				.sort((a, b) => a.data.label.toLowerCase().localeCompare(b.data.label.toLowerCase()))*/}
				{/*				.map((selectedConnectedNode, index) => selectedConnectedNode.data.label).join(", ")*/}
				{/*			}*/}
				{/*		</Text>*/}
				{/*	</Stack>*/}
				{/*}*/}

				<Group align="flex-start" justify="space-between" c={"var(--mantine-color-tertiary-9)"} wrap={"nowrap"}
					   style={simpleBackgroundStyle}>
					<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
						<Text size={"42px"} fw={700} ta={"center"} lineClamp={1} c={"var(--mantine-color-tertiary-9)"}>{selectedEdges?.length}</Text>
						<Text size={"sm"} ta={"center"} opacity={1} c={"var(--mantine-color-tertiary-9)"}>
							{t("studio.pairings")}
						</Text>
					</Stack>
					<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
						<Text size={"42px"} fw={700} ta={"center"} lineClamp={1} c={averageSharedMoleculesPc > 0 ? getAverageSharedMoleculesPcColor(averageSharedMoleculesPc) : "var(--mantine-color-tertiary-9)"}>
							{formatPc(averageSharedMoleculesPc)}
						</Text>
						<Text size={"sm"} ta={"center"} opacity={1} c={averageSharedMoleculesPc > 0 ? getAverageSharedMoleculesPcColor(averageSharedMoleculesPc) : "var(--mantine-color-tertiary-9)"}>
							{t("ingredient.compatibility")}
						</Text>
					</Stack>
				</Group>
			</Stack>

			<Flexible>

				<Flexible.Fixed>
					<Box style={simpleBackgroundStyle}>
						<Text size={"sm"} fw={700} c={"var(--mantine-color-tertiary-9)"}>
							{t('ingredient.compatibilityRange')}
						</Text>
						<Space h="md"/>
						<RangeSlider color={"tertiary.9"}
									 minRange={20} step={20}
									 label={null}
									 classNames={{
										 track: classes.rangeslidertrack,
										 markLabel: classes.rangeslidermarklabel,
										 mark: classes.rangeslidermark
									 }}
									 marks={COMPATIBILITY_MARKS}
									 value={[minCompatibility, maxCompatibility]}
									 onChange={(value) => onMinMaxCompatibility(value[0], value[1])} />
						<Space h="md"/>
					</Box>
				</Flexible.Fixed>

				<Space h="md"/>

				<Flexible.Expandable>

					<ScrollableArea style={simpleBackgroundStyle}>

						<StripedTable striped={true} stripedColor={"tertiary"} stickyHeader highlightOnHover highlightOnHoverColor={"tertiary"} className={classes.detailsentitytable}>
							<Table.Thead>
								<Table.Tr>
									<Table.Th p={0} pl={0}>
										<SearchInput
											iconColor={"tertiary"}
											textInputClassNames= {{
												input: classes.detailssarchinput
											}}
											size={"xs"}
											radius={0}
											value={search}
											placeholder={t('common.ingredients')}
											onChange={(value) => onSearch(value)}
										/>
									</Table.Th>
									<Table.Th ta={"right"} w={"25%"}>
										{t("molecule.molecules")}
									</Table.Th>
									<Table.Th ta={"right"} w={"25%"}>
										%
									</Table.Th>
								</Table.Tr>
							</Table.Thead>
							<Table.Tbody>
								{searchItems
									.sort(entityPairingsComparator)
									.slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
									.map((item, index) => (
										<Table.Tr key={`entity-${index}`} style={{cursor: draggable ? "grab" : "auto"}}
												  onDragStart={(event) => onDragStart(event, item)}
												  draggable={draggable}>

											<Table.Td>
												<Group align={"center"} gap={0} wrap={"nowrap"}>
													{draggable && (
														<FontAwesomeIcon
															icon={faGripVertical}
															style={{
																paddingTop: "3px",
																marginLeft: "-2px",
																marginRight: "8px",
																color: "var(--mantine-color-tertiary-4)"
															}}
														/>
													)}
													<Stack gap={0}>
														<Text size={"sm"} >
															{localized(item.representativeIngredient, "name")}
														</Text>
														<Text size={"xs"} lh={1.3} opacity={0.75}>
															{getCategories(item.representativeIngredient)
																.sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
																.map((category, index) => localized(category, 'name')).join(", ")}
														</Text>
													</Stack>
													{/*<IngredientText color={"tertiary"} ingredient={item.representativeIngredient}*/}
													{/*				ingredientHighlight={search} showCategory/>*/}
												</Group>
											</Table.Td>
											<Table.Td ta={"right"} style={{verticalAlign: "top"}}>
												{item.sharedMolecules}
											</Table.Td>
											<Table.Td ta={"right"} style={{verticalAlign: "top"}}>
												{formatPc(getSharedMoleculesPc(item.sharedMolecules,moleculesIds))}
											</Table.Td>
										</Table.Tr>
									))}
							</Table.Tbody>
						</StripedTable>
					</ScrollableArea>
				</Flexible.Expandable>

				<Flexible.Fixed style={simpleBackgroundStyle}>
					<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
							   selectedCount={searchItems.length} totalCount={entityPairings.length}
							   wrap={true} size={"md"} gap={"6px"}
							   color={"tertiary"}
							   paginatorClassNames = {{
									control: classes.detailspaginationcontrol,
									dots: classes.detailspaginationdots
							   }}/>
				</Flexible.Fixed>
			</Flexible>

		</Stack>
	);
}

/**
 * Molecules
 */
const Molecules = ({ molecules, entities, nodes, selectedNodes, edge, tabChange, onChange = () => {} }) => {

	const navigate = useNavigate();
	const { t } = useTranslation();

	const [selectedEntityCategories, setSelectedEntityCategories] = useState([]);

	const [activeIndex, setActiveIndex] = useState(undefined);

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");
	const [searchItems, setSearchItems] = useState([]);

	const theme = useMantineTheme();

	const scrollAreaRef = useRef(null);

	console.debug("Details.Molecules.update")

	useEffect(() => {

		setSearch("");
		setPage(1);
		setActiveIndex(undefined);

		if(molecules.length === 0) {
			setSelectedEntityCategories([]);
			return;
		}

		// Extract nodes from selected nodes
		const extractedNodes = extractNodes(nodes, selectedNodes);

		// Extract entities for the relevant nodes
		const selectedEntities = extractedNodes
			.map((node) => getEntityById(entities, node.id))
			.filter((entity) => !!entity);

		// Create a Map to aggregate category counts
		const categoryMap = new Map();

		selectedEntities.forEach((entity) => {
			entity.categories.forEach((category) => {
				const categoryId = category.entityCategoryId;

				// Extract moleculeIds from entity.molecules
				const moleculeIds = entity.molecules?.map((molecule) => molecule.moleculeId) || [];

				// If the category already exists in the map, update its entityIds and moleculeIds
				if (categoryMap.has(categoryId)) {
					const existing = categoryMap.get(categoryId);

					categoryMap.set(categoryId, {
						...existing,
						entityIds: [...new Set([...existing.entityIds, entity.entityId])], // Add entityId without duplicates
						moleculeIds: [...new Set([...existing.moleculeIds, ...moleculeIds])], // Add moleculeIds without duplicates
					});
				} else {
					// Otherwise, initialize the category in the map
					categoryMap.set(categoryId, {
						entityCategoryId: categoryId,
						name: localized(category, "name"), // Localized name
						entityIds: [entity.entityId], // Initialize with the current entityId
						moleculeIds: [...new Set(moleculeIds)], // Initialize with moleculeIds (deduplicated)
					});
				}
			});
		});

		// Calculate the 'count' field for each category
		categoryMap.forEach((category) => {
			category.count = category.moleculeIds.length;
		});

		// Convert the Map to an array
		setSelectedEntityCategories(Array.from(categoryMap.values())
			.sort((a, b) => a.name.localeCompare(b.name)));

	}, [molecules, entities, nodes, selectedNodes, edge]);

	useEffect(() => {
		setSearch("");
	}, []);

	useEffect(() => {

		try {

			if(!search) {
				setSearchItems(molecules);
			}
			else {
				setSearchItems(molecules.filter(molecule => localized(molecule, "name").toLowerCase().includes(search.toLowerCase())))
			}
		}
		catch(ignored) {
			// noop
		}

	}, [search, molecules]);

	useEffect(() => {

		if(onChange) {
			onChange(`${t("molecule.molecules")} ${getSubtitle() !== undefined ? getSubtitle() : ""}`);
		}

	}, [selectedEntityCategories]);

	useEffect(() => {
		scrollToTop();
	}, [tabChange]);

	/**
	 * scrollToTop
	 */
	const scrollToTop = () => {
		if (scrollAreaRef?.current !== undefined) {
			setTimeout(() => {
				try {
					scrollAreaRef.current.scrollTop = 0;
				}
				catch(e) {
					// noop
				}
			}, 100);
		}
	};

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 * @param props
	 * @returns {Element}
	 */
	const renderLabel = (props) => {
		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, index } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';

		const wrapText = (text, maxCharsPerLine = 10) => {
			const words = text.split(" ");
			let lines = [];
			let currentLine = "";

			words.forEach((word) => {
				// If adding the current word doesn't exceed the limit, let's add it to the current line.
				if ((currentLine + word).length <= maxCharsPerLine) {
					currentLine += word + " ";
				} else {
					// Otherwise, we close the current line and start a new one.
					lines.push(currentLine.trim());
					currentLine = word + " ";
				}
			});

			// We add the last line
			lines.push(currentLine.trim());

			return lines;
		};

		return (
			<g>
				{index === activeIndex &&

					<Sector
						cx={cx}
						cy={cy}
						startAngle={startAngle}
						endAngle={endAngle}
						innerRadius={outerRadius + 8}
						outerRadius={outerRadius + 10}
						fill={"var(--mantine-color-tertiary-6)"}
						style={{cursor: 'pointer'}}
						cornerRadius={1}
					/>

				}
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={index === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"} strokeWidth={1}
					  strokeDasharray="3 3" fill="none"/>
				{/*<circle cx={ex} cy={ey} r={3} fill={"white"} stroke="none"/>*/}
				<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={5} textAnchor={textAnchor} fontSize={14} fontWeight={700}
					  fill={index === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}>
					{payload.name}
				</text>
				<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={20} textAnchor={textAnchor} fontSize={12}
					  fill={index === activeIndex ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}>
					{formatPc(percent * 100)}
				</text>

			</g>
		);
	};

	/**
	 * getSubtitle
	 */
	function getSubtitle() {

		if(edge !== undefined) {
			return t("studio.shared")
		}

		return undefined;
	}

	/**
	 * Subtitle
	 */
	const Subtitle = () => {

		return getSubtitle() !== undefined ?
			<Text c={"var(--mantine-color-tertiary-9)"}>
				{getSubtitle()}
			</Text>
			:
			<Text>
				&nbsp;
			</Text>
	}

	return (
		<Stack justify="space-between" align={"stretch"} grow bg={"var(--mantine-color-tertiary-outline-hover)"} w={"100%"} h={"100%"} gap={"md"} p={"md"}>

			<Stack align="stretch" justify="space-between" h={"100%"}>

				<Stack flex={1} style={{...simpleBackgroundStyle, position: "relative"}}
					   onMouseLeave={() => setActiveIndex(undefined)}>

					<Stack align="center" justify="flex-start" gap={0}
						   style={{position: "absolute", top: "50%", left: "50%", transform:"translate(-50%, -50%)"}}>
						<Text size={"42px"} fw={700} c={"var(--mantine-color-tertiary-9)"}>
							<NumberFormatter thousandSeparator="'" value={molecules?.length}/>
						</Text>
						<Text c={"var(--mantine-color-tertiary-9)"}>
							{t("molecule.molecules")}
						</Text>
						<Subtitle />
					</Stack>

					<ResponsiveContainer width="100%" height="100%">
						<PieChart>
							<Pie
								data={selectedEntityCategories}
								cx="50%"
								cy="50%"
								innerRadius={"58%"}
								outerRadius={"70%"}
								fill={"var(--mantine-color-tertiary-9)"}
								dataKey="count"
								paddingAngle={1}
								strokeWidth={0}
								isAnimationActive={false}
								startAngle={90}
								endAngle={-270}
								cornerRadius={1}
								activeIndex={activeIndex}
								activeShape={(props) => <Sector {...props} style={{cursor: 'pointer'}}
																fill="var(--mantine-color-tertiary-6)"/>}
								onMouseEnter={(_, index) => setActiveIndex(index)}
								onMouseLeave={() => setActiveIndex(undefined)}
								onClick={(data, index) => {
									// if(onClick) {
									// 	onClick(data.attribute);
									// }
								}}
								label={renderLabel}
							/>

							{edge !== undefined &&
								<Pie
									data={[
										{
											count: molecules.length,
											fill: "var(--mantine-color-tertiary-9)"
										},
										{
											count: selectedEntityCategories[0]?.moleculeIds.length - molecules.length || 0,
											fill: "var(--mantine-color-tertiary-light-hover)"
										},
										{
											count: selectedEntityCategories[1]?.moleculeIds.length - molecules.length || 0,
											fill: "var(--mantine-color-tertiary-light-hover)"
										}
									]}
									cx="50%"
									cy="50%"
									innerRadius={"45%"}
									outerRadius={"57%"}
									fill={"var(--mantine-color-tertiary-9)"}
									dataKey="count"
									paddingAngle={1}
									strokeWidth={0}
									isAnimationActive={false}
									startAngle={90}
									endAngle={-270}
									cornerRadius={1}
								/>
							}
						</PieChart>
					</ResponsiveContainer>
				</Stack>

				<Group align="flex-start" justify="center" c={"var(--mantine-color-tertiary-9)"} wrap={"nowrap"}
					   style={simpleBackgroundStyle}>

					<Stack align="stretch" justify="flex-start" gap={0} w={"33%"}>
						<Text size={"42px"} fw={700} ta={"center"} lineClamp={1} c={"var(--mantine-color-tertiary-9)"}>
							{selectedEntityCategories.length}
						</Text>
						<Text size={"sm"} ta={"center"} opacity={1} c={"var(--mantine-color-tertiary-9)"}>
							{t("studio.entityCategories")}
						</Text>
					</Stack>
				</Group>

			</Stack>

			<Flexible>

				<Flexible.Expandable>
					<ScrollableArea viewportRef={scrollAreaRef}
								style={simpleBackgroundStyle}
					>
						<StripedTable striped={true} stripedColor={"tertiary"} stickyHeader highlightOnHover highlightOnHoverColor={"tertiary"} className={classes.detailsmoleculartable}>
							<Table.Thead>
								<Table.Tr>
									<Table.Th p={0} pl={0}>
										<SearchInput
											iconColor={"tertiary"}
											textInputClassNames= {{
												input: classes.detailssarchinput
											}}
											size={"xs"}
											radius={0}
											value={search}
											placeholder={t(`molecule.molecules`)}
											onChange={(value) => onSearch(value)}
										/>
									</Table.Th>
								</Table.Tr>
							</Table.Thead>
							<Table.Tbody>
								{searchItems
									.sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
									.slice(paginationStartSkip(page, theme.custom.molecule.paginationSize), paginationEndSkip(page, theme.custom.molecule.paginationSize))
									.map((item, index) => (
									<Table.Tr style={{color: activeIndex !== undefined ? selectedEntityCategories[activeIndex].moleculeIds.includes(item.moleculeId) ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)" : "var(--mantine-color-tertiary-9)"}} onClick={() => moleculeIdNavigate(navigate, item.moleculeId, "_blank")}>
										<Table.Td>
											<Highlight inherit highlight={search}>
												{localized(item, "name")}
											</Highlight>
										</Table.Td>
									</Table.Tr>
								))}
							</Table.Tbody>
						</StripedTable>
					</ScrollableArea>
				</Flexible.Expandable>

				<Flexible.Fixed style={simpleBackgroundStyle}>
					<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.molecule.paginationSize}
							   selectedCount={searchItems.length} totalCount={molecules.length}
							   wrap={true} size={"md"} gap={"6px"}
							   color={"tertiary"}
							   paginatorClassNames = {{
								   control: classes.detailspaginationcontrol,
								   dots: classes.detailspaginationdots
							   }}/>
				</Flexible.Fixed>
			</Flexible>
		</Stack>
	);
};

/**
 * MolecularChart
 */
const MolecularChart = ({ molecules, attribute = "flavors", tabChange, maxItems = 10 }) => {

	const { t } = useTranslation();

	const [data, setData] = useState([]);
	const [occurrencesLength, setOccurrencesLength] = useState(0);
	const [chartData, setChartData] = useState([]);
	const [domain, setDomain] = useState([0, 100]);

	const [activeIndex, setActiveIndex] = useState(undefined);

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");
	const [searchItems, setSearchItems] = useState([]);

	const theme = useMantineTheme();

	const scrollAreaRef = useRef(null);

	console.debug("Details.MolecularChart.update")

	/**
	 * Helper function to accumulate frequencies as percentages
	 * @param {Array} items - The array of items to process
	 * @returns {Object[]} - A sorted array of unique items with counts and percentages
	 */
	const accumulateFrequency = (items) => {

		// Ensure items array exists and is valid
		if (!items || items.length === 0) return [];

		// Count occurrences
		const totalItems = items.length;
		const frequencyMap = items.reduce((acc, item) => {
			const name = localized(item, "name"); // Use the localized name as the unique key
			if (name) {
				acc[name] = acc[name] || { name, count: 0 }; // Initialize if not present
				acc[name].count += 1; // Increment count
				acc[name].pc = acc[name].count / totalItems * 100;
			}
			return acc;
		}, {});

		// Convert the frequency map to an array
		const frequencyArray = Object.values(frequencyMap);

		// Sort by `pc` (descending) and then by `name` (alphabetical ascending)
		return frequencyArray.sort((a, b) => {
			if (b.pc === a.pc) {
				return a.name.localeCompare(b.name); // Sort alphabetically if `pc` is the same
			}
			return b.pc - a.pc; // Sort by `pc` (descending)
		});
	};

	useEffect(() => {

		setSearch("");
		setPage(1);
		setActiveIndex(undefined);

		// All occurrences
		const occurrences = molecules?.flatMap((molecule) => molecule[attribute] || []);

		// Update all data for the specified attribute (flavors, odors, tastes, compounds)
		const result = accumulateFrequency(occurrences);

		setData(result);
		setOccurrencesLength(occurrences.length);

	}, [molecules, attribute]);

	useEffect(() => {

		// Limit the chart data to maxItems
		const limitedChartData = data.slice(0, maxItems).map((item) => ({
			element: item.name,
			frequency: item.pc,
		}));

		setChartData(limitedChartData);

		// Calculate the domain for PolarRadiusAxis based on limited data
		if (limitedChartData.length > 0) {
			const frequencies = limitedChartData.map((item) => item.frequency);
			const max = Math.max(...frequencies); // Maximum percentage
			setDomain([0, Math.min(100, max)]); // Add padding for better visuals
		}

	}, [data, maxItems]);

	useEffect(() => {
		setSearch("");
	}, []);

	useEffect(() => {

		try {

			if(!search) {
				setSearchItems(data);
			}
			else {
				setSearchItems(data.filter(d => d.name.toLowerCase().includes(search.toLowerCase())))
			}
		}
		catch(ignored) {
			// noop
		}

	}, [search, data]);

	useEffect(() => {
		scrollToTop();
	}, [tabChange]);

	/**
	 * scrollToTop
	 */
	const scrollToTop = () => {
		if (scrollAreaRef?.current !== undefined) {
			setTimeout(() => {
				try {
					scrollAreaRef.current.scrollTop = 0;
				}
				catch(e) {
					// noop
				}
			}, 100);
		}
	};

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 * PolarAngleAxisTick
	 */
	const PolarAngleAxisTick = ({ payload, x, y, cx, cy, activeIndex, index, setActiveIndex }) => {
		// Determine whether the label is top, bottom, left, or right
		const isTop = y < cy; // Label is above the center
		const isBottom = y > cy; // Label is below the center
		const isLeft = x < cx; // Label is on the left side
		const isRight = x > cx; // Label is on the right side

		// Adjust positioning for labels
		const dx = isTop || isBottom ? "0" : isLeft ? "-5px" : "5px"; // Horizontal adjustment for left/right
		const dy = isTop
			? "-5px" // Move the top label slightly upward
			: isBottom
				? "10px" // Move the bottom label slightly downward
				: "0.35em"; // No vertical adjustment for left/right

		// Horizontal alignment
		let textAnchor = "";

		if (isLeft) {
			textAnchor = "end";
		}
		else if (isRight) {
			textAnchor = "start";
		}
		else if (isTop || isBottom) {
			textAnchor = "middle";
		}

		// Change text color when hovering over the label
		const handleMouseEnter = () => setActiveIndex(index); // Set active index
		const handleMouseLeave = () => setActiveIndex(undefined); // Reset active index

		// Return the tick element
		return (
			<text
				x={x}
				y={y}
				dx={dx}
				dy={dy}
				textAnchor={textAnchor}
				fill={activeIndex === index ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}
				fontSize={14}
				fontWeight={700}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={{ cursor: "pointer" }} // Show pointer cursor for hoverable labels
			>
				{payload.value}
			</text>
		);
	};

	/**
	 * PolarRadiusAxisTick
	 */
	const PolarRadiusAxisTick = ({ payload, x, y, ...rest }) => {

		// if (payload.value === 0 || payload.value === 100) return null;

		return (
			<text
				x={x}
				y={y}
				dx={4}
				dy={14}
				// transform={`rotate(360 ${x} ${y})`} // Rotate label 90° clockwise
				fill="var(--mantine-color-tertiary-9)"
				fontSize={12}
				textAnchor="left"
			>
				{`${formatPc(payload.value)}`}
			</text>
		);
	};

	// Render the graphics section dynamically based on the attributes prop
	return chartData === undefined || chartData.length === 0 ? null :

		<Stack justify="space-between" align={"stretch"} grow bg={"var(--mantine-color-tertiary-outline-hover)"} w={"100%"} h={"100%"} gap={"md"} p={"md"}>

			<Stack align="stretch" justify="space-between" h={"100%"}>

				<Stack flex={1} style={simpleBackgroundStyle}
					   onMouseLeave={() => setActiveIndex(undefined)}>

					<ResponsiveContainer width="100%" height="100%" >
						<RadarChart data={chartData} outerRadius="70%">

							<PolarGrid gridType={"circle"} stroke="var(--mantine-color-tertiary-9)" strokeOpacity={0.25}/>

							<PolarAngleAxis dataKey="element"
											tick={(props) => (
												<PolarAngleAxisTick
													{...props}
													activeIndex={activeIndex}
													setActiveIndex={setActiveIndex}
												/>
											)}
							/>

							<PolarRadiusAxis angle={90}
											 domain={domain} // Dynamic domain
											 tick={<PolarRadiusAxisTick />}/>

							<Radar isAnimationActive={false}
								   dataKey="frequency"
								   stroke={"var(--mantine-color-tertiary-9)"}
								   fill={"var(--mantine-color-tertiary-9)"}
								   fillOpacity={0.25}
								   strokeLinejoin="round" // Rende le giunzioni più arrotondate
							/>
						</RadarChart>
					</ResponsiveContainer>
				</Stack>

				<Group align="flex-start" justify="space-between" c={"var(--mantine-color-tertiary-9)"} wrap={"nowrap"}
					   style={simpleBackgroundStyle}>

					<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
						<Text size={"42px"} fw={700} ta={"center"} lineClamp={1} c={"var(--mantine-color-tertiary-9)"}>
							<NumberFormatter thousandSeparator="'" value={data.length}/>
						</Text>
						<Text size={"sm"} ta={"center"} opacity={1} c={"var(--mantine-color-tertiary-9)"}>
							{t(`molecule.${attribute}`)}
						</Text>
					</Stack>
					<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
						<Text size={"42px"} fw={700} ta={"center"} lineClamp={1} c={"var(--mantine-color-tertiary-9)"}>
							<NumberFormatter thousandSeparator="'" value={occurrencesLength}/>
						</Text>
						<Text size={"sm"} ta={"center"} opacity={1} c={"var(--mantine-color-tertiary-9)"}>
							{t(`studio.occurrences`)}
						</Text>
					</Stack>
				</Group>

			</Stack>

			<Flexible>

				<Flexible.Expandable>
					<ScrollableArea viewportRef={scrollAreaRef}
								style={simpleBackgroundStyle}
					>

						<StripedTable striped={"even"} stripedColor={"tertiary"} stickyHeader className={classes.detailsmoleculartable}>
							<Table.Thead>
								<Table.Tr>
									<Table.Th p={0} pl={0}>
										<SearchInput
											iconColor={"tertiary"}
											textInputClassNames= {{
												input: classes.detailssarchinput
											}}
											size={"xs"}
											radius={0}
											value={search}
											placeholder={t(`molecule.${attribute}`)}
											onChange={(value) => onSearch(value)}
										/>
									</Table.Th>
									<Table.Th ta={"right"} w={"25%"}>
										{t("studio.occurrences")}
									</Table.Th>
									<Table.Th ta={"right"} w={"25%"}>
										%
									</Table.Th>
								</Table.Tr>
							</Table.Thead>
							<Table.Tbody>
								<Table.Tr>
									<Table.Td colSpan={3}>
										<Group c={"var(--mantine-color-tertiary-9)"}>
											<Text size={"xs"}>
												{t(`molecule.${attribute}Description`)}
											</Text>
										</Group>
									</Table.Td>
								</Table.Tr>
								{searchItems
									.slice(paginationStartSkip(page, theme.custom.molecule.paginationSize), paginationEndSkip(page, theme.custom.molecule.paginationSize))
									.map((item, index) => (
										<Table.Tr style={{color: activeIndex !== undefined && chartData[activeIndex].element === item.name ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)", fontWeight: ((page - 1) * theme.custom.molecule.paginationSize + index) < maxItems ? 700 : 400}}>
											<Table.Td>
												<Highlight inherit highlight={search}>
													{item.name}
												</Highlight>
											</Table.Td>
											<Table.Td ta={"right"}>
												{item.count}
											</Table.Td>
											<Table.Td ta={"right"}>
												{formatPc(item.pc)}
											</Table.Td>
										</Table.Tr>
									))}
							</Table.Tbody>
						</StripedTable>
					</ScrollableArea>

				</Flexible.Expandable>

				<Flexible.Fixed style={simpleBackgroundStyle}>
					<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.molecule.paginationSize}
							   selectedCount={searchItems.length} totalCount={data.length}
							   wrap={true} size={"md"} gap={"6px"}
							   color={"tertiary"}
							   paginatorClassNames = {{
								   control: classes.detailspaginationcontrol,
								   dots: classes.detailspaginationdots
							   }}/>
				</Flexible.Fixed>
			</Flexible>
		</Stack>
};