import i18n, {localized} from "../../i18n";
import {
	getEntityNaturalSourceIngredient,
	getEntityRepresentativeIngredient,
	getEntitySynonymIngredients
} from "../ingredient/IngredientUtil";
import {localizedLink, WIKI_LINK_NAME} from "../../util/links";

/**
 * GRID_SIZE
 */
export const GRID_SIZE = 20;

/**
 * snapToGrid
 */
export function snapToGrid(value) {
	return Math.round(value / GRID_SIZE) * GRID_SIZE;
}

/**
 * COMPATIBILITY_MARKS
 */
export const COMPATIBILITY_MARKS=[
	{value: 0},
	{value: 20, label: "20%"},
	{value: 40, label: "40%"},
	{value: 60, label: "60%"},
	{value: 80, label: "80%"},
	{value: 100},
];

/**
 * INGREDIENTS_THRESHOLDS
 */
export const INGREDIENTS_THRESHOLDS = [
	{ limit: 12, color: "red", difficulty: "high" },
	{ limit: 6, color: "yellow", difficulty: "medium" },
	{ limit: 0, color: "lime", difficulty: "low" }
];

/**
 * MOLECULES_THRESHOLDS
 */
export const MOLECULES_THRESHOLDS = [
	{ limit: 66, color: "lime" },
	{ limit: 33, color: "yellow" },
	{ limit: 0, color: "red" }
];

/**
 * PAIRINGS_THRESHOLDS
 */
export const PAIRINGS_THRESHOLDS = [
	{ limit: 66, color: "#fa5252" },	// red-6
	{ limit: 33, color: "#fab005" },	// yellow-6
	{ limit: 0, color: "#82c91e" },		// lime-6
];

/**
 * getDataByThreshold
 */
export function getDataByThreshold(value, thresholds) {

	for (const threshold of thresholds) {
		if (value >= threshold.limit) {
			return threshold;
		}
	}

	return undefined;
}

/**
 * getColorByThreshold
 *
 * Funzione generica per determinare il colore in base a un valore e a un set di soglie.
 *
 * @param {number} value - Il valore per il quale determinare il colore.
 * @param {Array} thresholds - Le soglie definite come array di oggetti { limit, color }.
 * @param {Array} colorLevel
 * @param {Array} defaultColor
 * @returns {string} - Il colore associato al valore.
 */
export function getColorByThreshold(value, thresholds, colorLevel = "6", defaultColor = "tertiary") {

	for (const { limit, color } of thresholds) {
		if (value >= limit) {
			return `var(--mantine-color-${color}-${colorLevel})`;
		}
	}

	return `var(--mantine-color-${defaultColor}-${colorLevel})`;
}

/**
 * getFirstInputNode
 */
export function getFirstInputNode(nodes) {
	return nodes.find((node) => node.type === "input") || undefined;
}

/**
 * getEntityById
 */
export function getEntityById(entities, entityId) {
	return entities.find((entity) => entity.entityId === entityId) || undefined;
}

/**
 * Finds the common molecules between two entities.
 *
 * @param {Object} entity1 - The first entity object with a 'molecules' array.
 * @param {Object} entity2 - The second entity object with a 'molecules' array.
 * @returns {Array} - An array of common moleculeIds.
 */
export function extractSharedMolecules(entity1, entity2) {

	// Step 1: Extract the moleculeId array from the first entity
	const moleculeIds1 = new Set(entity1.molecules.map((molecule) => molecule.moleculeId));

	// Step 2: Filter molecules from the second entity that have an id present in the first entity's Set
	return entity2.molecules.filter((molecule) => moleculeIds1.has(molecule.moleculeId));
}

/**
 * extractMolecules
 *
 * Extracts all unique molecules from the given entities and nodes.
 * Handles group nodes and uses their weighting to dynamically balance between intersection and union.
 */
export function extractMolecules(entities, nodes, selectedNodes) {

	// Step 1: Extract all group nodes from selectedNodes
	const groupNodes = selectedNodes.filter((node) => node.type === "group");

	// Step 2: Calculate molecules for group nodes, applying group weighting
	const moleculesFromGroups = groupNodes.flatMap((groupNode) => {

		// Get all nodes with this groupNode's ID as their parentId
		const childNodes = nodes.filter((node) => node.parentId === groupNode.id);

		// Get all molecules from these child nodes
		const childMolecules = childNodes.flatMap(
			(node) => getEntityById(entities, node.id)?.molecules || []
		);

		// Apply weighting to combine intersection and union
		const uniqueChildMolecules = [...new Set(childMolecules)];
		const groupWeighting = groupNode.data.weighting ?? 0.5; // Default weighting to 0.5 if not provided

		// Calculate intersection and union
		const moleculeIntersection = uniqueChildMolecules.filter((molecule, index, self) =>
			self.indexOf(molecule) === index && childMolecules.every((m) => m === molecule)
		);

		const moleculeUnion = uniqueChildMolecules;

		// Apply weighted combination
		const combinedMolecules = new Set([
			...moleculeIntersection.slice(0, Math.ceil(moleculeIntersection.length * groupWeighting)), // Take part of the intersection
			...[...moleculeUnion].slice(0, Math.ceil(moleculeUnion.length * (1 - groupWeighting))), // Take part of the union
		]);

		return Array.from(combinedMolecules);
	});

	// Step 4: Filter all selectedNodes that do not have parentId in groupNodes
	const nodesOutsideGroups = selectedNodes.filter(
		(node) =>
			!groupNodes.some((groupNode) => groupNode.id === node.parentId) // Exclude nodes that are children of group nodes
	);

	// Step 5: Get all molecules from nodes that are not part of groups
	const moleculesFromNodesOutsideGroups = nodesOutsideGroups.flatMap(
		(node) => getEntityById(entities, node.id)?.molecules || [] // Extract molecules from each node
	);

	// Step 6: Combine all molecules and remove duplicates
	return [...new Set([...moleculesFromGroups, ...moleculesFromNodesOutsideGroups])];
}

/**
 * sharedMoleculesPc
 */
export function getSharedMoleculesPc(sharedMolecules, moleculesIds) {

	if(!sharedMolecules || !moleculesIds) {
		return undefined;
	}

	return sharedMolecules / moleculesIds.length * 100;
}

/**
 * extractEntityPairings
 */
export function extractEntityPairings(entities, moleculeIds) {

	return entities
		.map((entity) => {

			// Extract moleculeIds from the entity
			const entityMoleculeIds = entity.molecules.map((molecule) => molecule.moleculeId);

			// Calculate the shared molecules
			const sharedMolecules = entityMoleculeIds.filter((id) => moleculeIds.includes(id));
			const sharedMoleculesCount = sharedMolecules.length;

			// Return a copy of the entity with the new properties
			return {
				...entity, // Copy the original entity
				sharedMolecules: sharedMoleculesCount
			};
		})
		.filter((entity) => entity.sharedMolecules > 0); // Only include entities with sharedMolecules > 0
}

/**
 * Filters entities whose entityId is NOT present in nodes
 */
export function extractEntitiesNotInNodes(entities, nodes) {

	// Create a Set of entityId values from nodes for faster lookup
	const entityIds = new Set(nodes.map((node) => node.id));

	// Filter entities whose entityId is NOT in the Set of entityIds
	return entities.filter((entity) => !entityIds.has(entity.entityId));
}

/**
 * entitiesMoleculesAggregation
 */
export function entitiesMoleculesAggregation(entities, molecules) {

	return entities.map((entity) => {

		// Get entity molecules
		const entityMolecules = molecules.filter((molecule) =>
			molecule.entityIds.includes(entity.entityId)
		);

		// TODO add flavors, tastes, ... search strings

		// Build the search string
		const searchString = [
			entity.ingredients
				.flatMap((ingredient) => [
					localized(ingredient, "name")
				])
				.join(" "),
			// Array.from(
			// 	new Set( // Unique data
			// 		entityMolecules.flatMap((molecule) => [
			// 			localized(molecule, "name"),
			// 			...(molecule.compounds || []).map((compound) => localized(compound, "name")),
			// 			...(molecule.flavors || []).map((flavor) => localized(flavor, "name")),
			// 			...(molecule.odors || []).map((odor) => localized(odor, "name")),
			// 			...(molecule.tastes || []).map((taste) => localized(taste, "name"))
			// 		])
			// 	)
			// ).join(" ")
		].join(" ").toLowerCase();

		return {
			...entity,
			entityId: `${entity.entityId}`,
			representativeIngredient: getEntityRepresentativeIngredient(entity),
			naturalSourceIngredient: getEntityNaturalSourceIngredient(entity),
			synonymIngredients: getEntitySynonymIngredients(entity),
			search: searchString,
			molecules: entityMolecules
		}
	});
}

/**
 * searchEntities
 */
export function searchEntities(entities, search) {

	// Split the search input into multiple terms using commas or spaces as delimiters
	const searchTerms = search
		.split(/[, ]+/) // Split by commas, spaces, or both
		.map((term) => term.trim().toLowerCase()) // Trim and convert to lowercase
		.filter((term) => term.length > 0); // Remove empty terms

	// Filter entities based on the search terms
	return entities.filter((entity) =>
			searchTerms.every((term) => entity.search.toLowerCase().includes(term))
		// `.every()` to ensure all terms must match
		// `.some()` if you want any term to match
	);
}

/**
 * extractNodes
 *
 * Extracts all unique nodes from the given selectedNodes (handles group nodes)
 */
export function extractNodes(nodes, selectedNodes) {

	// Step 1: Extract all group nodes from selectedNodes
	const groupNodes = selectedNodes.filter((node) => node.type === "group");

	// Step 2: Calculate molecules for group nodes, applying group weighting
	const nodesFromGroups = groupNodes.flatMap((groupNode) => {

		// Get all nodes with this groupNode's ID as their parentId
		return nodes.filter((node) => node.parentId === groupNode.id);
	});

	// Step 4: Filter all selectedNodes that do not have parentId in groupNodes
	const nodesOutsideGroups = selectedNodes.filter(
		(node) =>
			!groupNodes.some((groupNode) => groupNode.id === node.parentId) // Exclude nodes that are children of group nodes
	);

	// Step 6: Combine all nodes and remove duplicates
	return [...new Set([...nodesFromGroups, ...nodesOutsideGroups])];
}

/**
 * Get unique parent IDs from selected nodes
 */
export const getParentIds = (nodes) => {

	// Filter the nodes that have a parentId and return the unique parentId values.
	return [...new Set(nodes.filter((node) => node.parentId).map((node) => node.parentId))];
};

/**
 * findGroupNodeAtPosition
 */
export const findGroupNodeAtPosition = (nodes, position) => {

	return nodes.find(
		(existingNode) => existingNode.type === "group" && // Check only for group nodes
			position.x >= existingNode.position.x &&
			position.x <= existingNode.position.x + existingNode.measured.width &&
			position.y >= existingNode.position.y &&
			position.y <= existingNode.position.y + existingNode.measured.height
	);
}

/**
 * getNodeById
 */
export const getNodeById = (nodes, id) => {
	return nodes.find((node) => node.id === id);
};

/**
 * getNodesByParentId
 */
export const getNodesByParentId = (nodes, parentId) => {
	return nodes.filter((node) => node.parentId === parentId);
};

/**
 * sortNodesByParent
 */
export const sortNodesByParent = (nodes) => {

	// Create a map of nodes to facilitate lookup
	const nodeMap = nodes.reduce((acc, node) => {
		acc[node.id] = node;
		return acc;
	}, {});

	// Recursive function to calculate node deep
	const getDepth = (node) => {
		let depth = 0;
		let currentNode = node;
		while (currentNode?.parentId) {
			depth++;
			currentNode = nodeMap[currentNode.parentId];
		}
		return depth;
	};

	// Sort nodes based on the calculated deep
	return nodes.sort((a, b) => getDepth(a) - getDepth(b));
};

/**
 * toGroupId
 */
export function toGroupId() {
	return `group-${Date.now()}`;
}

/**
 * toGroup
 */
export function toGroup(id, position, size) {

	return {
		id: id,
		type: "group",
		position: position,
		data: {
			label: i18n.t("studio.newGroupNode"),
			weighting: 0.5
		},
		style: {
			width: size.width,
			height: size.height,
		},
	}
}

/**
 * toNode
 */
export function toNode(id, type, position, entity, selected = false) {

	return {
		id,
		type,
		position,
		selected,
		data: {
			// label: `${localized(entity.representativeIngredient, "name")} Molecules ${entity.molecules.length}`,
			label: `${localized(entity.representativeIngredient, "name")}`,
			entity: {
				ingredientName: entity.representativeIngredient.name,
				categoryLabel: localized(entity.representativeIngredient.category, "name"),
				wikiLink: localizedLink(entity?.links, WIKI_LINK_NAME),
				moleculesCount: entity.molecules.length
			}
		},
		style: {padding: `${getNodePadding(entity.molecules.length)}px`}
	};
}

/**
 * toChildNode
 */
export function toChildNode(node, groupId, groupPosition) {

	return {
		...node,
		// selected: false,
		parentId: groupId,
		extent: "parent",
		position: {
			x: node.position.x - groupPosition.x,
			y: node.position.y - groupPosition.y,
		}
	}
}

/**
 * toChildNode
 */
export function ungroupNode(node, position) {

	return {
		...node,
		parentId: undefined,
		extent: undefined,
		position
	}
}

/**
 * getNodePadding
 */
function getNodePadding(moleculesLength) {
	return snapToGrid(moleculesLength / 4 + 10);
}

/**
 * Get edges connected to a specific set of nodes
 * @param {Array} edges - The array of edges.
 * @param {Array} nodes - The array of nodes to check.
 * @returns {Array} - Array of edges connected to the nodes.
 */
export function getConnectedEdges(edges, nodes) {
	const nodeIds = nodes.map(node => node.id);
	return edges.filter(edge => nodeIds.includes(edge.source) || nodeIds.includes(edge.target));
}

/**
 * toEdge
 */
export function toEdge(sourceId, type, entity, moleculesIds) {

	const sharedMoleculesPc = getSharedMoleculesPc(entity.sharedMolecules, moleculesIds);

	return {
		id: `${sourceId}-${entity.entityId}`,
		type: type,
		source: `${sourceId}`,
		target: `${entity.entityId}`,
		deletable: false,
		data: {
			// label: `Molecules ${entity.sharedMolecules} / ${sharedMoleculesPc}%`,
			label: i18n.t("ingredient.compatibleAt", {compatibility: `${formatPc(sharedMoleculesPc)}`}),
			sharedMolecules: entity.sharedMolecules,
			sharedMoleculesPc: sharedMoleculesPc,
			width: getEdgeWidth(sharedMoleculesPc),
		}
	};
}

/**
 * getEdgeWidth
 */
function getEdgeWidth(sharedMoleculesPc) {
	return sharedMoleculesPc / 3;
}

/**
 * toSelection
 */
export function toSelection(nodes = [], edges = [], onPane = true) {

	return {
		nodes: nodes,
		edges: edges,
		onPane: onPane,
		timestamp: Date.now()
	}
}

/**
 * formatPc
 */
export function formatPc(valuePc, digitsAboveOne = 0, digitsBelowOne = 1) {

	// Handle cases where the value is null, undefined, or zero
	if (!valuePc || valuePc === 0) {
		return "0%";
	}

	// Format based on the value and the specified digits
	return valuePc >= 1
		? `${valuePc.toFixed(digitsAboveOne)}%`
		: `${valuePc.toFixed(digitsBelowOne)}%`;
}