import { useEffect } from "react";

/**
 * useViewportHeight
 * Dynamically updates a CSS variable "--vh" to match the actual viewport height.
 */
export const useViewportHeight = () => {

	useEffect(() => {

		const updateViewportHeight = () => {
			const vh = window.innerHeight * 0.01; // Calcualate 1% of visible height
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		};

		updateViewportHeight();

		window.addEventListener("resize", updateViewportHeight);

		return () => {
			window.removeEventListener("resize", updateViewportHeight);
		};
	}, []);
};