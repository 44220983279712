import {Box, Flex} from "@mantine/core";

/**
 * Flexible
 */
export const Flexible = (props) => {

    const {children} = props;

    return (
        <Flex direction="column" h="100%" {...props}>
            {children}
        </Flex>
    );
}

/**
 * FixedStyle
 */
export const FixedStyle = { flex: "0 0 auto"};

/**
 * Fixed
 */
const Fixed = (props) => {

    const { children, style } = props;

    return (
        <Box style={{...style, ...FixedStyle}}>
            {children}
        </Box>
    )
}

Flexible.Fixed = Fixed;

/**
 * ExpandableStyle
 */
export const ExpandableStyle = { flex: "1 1 auto", height: "0px", overflow: "hidden"};

/**
 * Expandable
 */
const Expandable = (props) => {

    const { children, style } = props;

    return (
        <Box style={{...style, ...ExpandableStyle}}>
            {children}
        </Box>
    )
}

Flexible.Expandable = Expandable;