import React, {useEffect, useState} from 'react';
import {
    Stack,
    Space,
    useMantineTheme, Table
} from '@mantine/core';
import {localized} from "../../i18n";
import classes from "./IngredientList.module.css";
import {useTranslation} from "react-i18next";
import {alphabeticComparator} from "../../util/utils";
import {ingredientNavigate, IngredientText} from "./IngredientLink";
import {SearchInput} from "../../components/search/Search";
import {
    paginationEndSkip,
    paginationStartSkip
} from "../../util/pagination";
import {Paginator} from "../../components/paginator/Paginator";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import useKey from "../useKey";
import useIngredientAll from "./useIngredientAll";
import useResult from "../useResult";
import {FlavorDbCredits} from "../../components/credits/Credits";

/**
 * Ingredient09
 *
 * @param title
 * @param ingredients
 * @param ingredientTarget
 * @param showCategory
 * @param categoryTarget
 * @param refresh
 * @param highlight
 * @returns {Element}
 * @constructor
 */
export const Ingredient09 = ({  title, ingredients, ingredientTarget = "_self",
                                showCategory = true, categoryTarget = "_self",
                                hideSearch = false,
                                highlight}) => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");
    const [searchItems, setSearchItems] = useState([]);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useMantineTheme();

    useEffect(() => {
        setSearch("");
    }, []);

    useEffect(() => {
        try {

            if(!search) {
                setSearchItems(ingredients);
            }
            else {
                setSearchItems(ingredients.filter(ingredient => localized(ingredient, 'name').toLowerCase().includes(search.toLowerCase())));
            }
        }
        catch(ignored) {
            // noop
        }

    }, [search, ingredients]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
        setPage(1);
    }

    return (
        <Stack justify="flex-start" gap="0">
            {title ? title : null}
            <StripedTable highlightOnHover>
                {hideSearch ? null :
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th colSpan={2} p={0}>
                                <SearchInput
                                    value={search}
                                    placeholder={t('common.ingredients')}
                                    onChange={(value) => onSearch(value)}
                                />
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                }
                <Table.Tbody>

                    {hideSearch || searchItems.length === 0 ? null :
                        <Space h={"lg"}/>
                    }

                    {searchItems
                        .sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
                        .slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
                        .map((item, index) => (
                            <Table.Tr key={`ingredient-${index}`} style={{cursor: "pointer"}} onClick={() => ingredientNavigate(navigate, item)}>
                                <Table.Td style={{verticalAlign: "middle"}} >
                                    {/*<IngredientLink ingredient={getIngredient(item.ingredientId)} ingredientHighlight={search} ingredientTarget="_self" categoryTarget="_self"/>*/}
                                    <IngredientText ingredient={item} ingredientHighlight={search || highlight} showCategory={showCategory}/>
                                </Table.Td>
                                <Table.Td w={"30px"}>
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                </Table.Td>
                            </Table.Tr>
                        ))}
                </Table.Tbody>
            </StripedTable>
            <Space h={"lg"}/>
            <Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
                       selectedCount={searchItems.length} totalCount={ingredients?.length}/>
            <FlavorDbCredits pl={"md"} pt={"md"} i18nKey={"common.flavordbIngredientsAttribution"}/>
        </Stack>
    );
}

// /**
//  * IngredientAZ
//  *
//  * @param title
//  * @param ingredients
//  * @param ingredientTarget
//  * @param showCategory
//  * @param categoryTarget
//  * @returns {Element}
//  * @constructor
//  */
// export const IngredientAZ = ({  title,
//                                 ingredients, ingredientTarget = "_self",
//                                 showCategory = true, categoryTarget = "_self"}) => {
//
//     const [page, setPage] = useState(1);
//     const [search, setSearch] = useState(" ");
//     const [searchItems, setSearchItems] = useState([]);
//     const [firstLetters, setFirstLetters] = useState([]);
//
//     const { t } = useTranslation();
//
//     useEffect(() => {
//         setSearch("");
//     }, []);
//
//     useEffect(() => {
//
//         try {
//
//             if(!search) {
//                 setSearchItems(ingredients);
//             }
//             else {
//                 setSearchItems(ingredients.filter(ingredient => localized(ingredient, 'name').toLowerCase().includes(search.toLowerCase())));
//             }
//         }
//         catch(ignored) {
//             // noop
//         }
//
//     }, [search]);
//
//     useEffect(() => {
//         setFirstLetters(extractFirstLetters(searchItems, "name"));
//     }, [searchItems]);
//
//     /**
//      * @param value
//      */
//     const onSearch = (value) => {
//         setSearch(value);
//         setPage(1);
//     }
//
//     return (
//         <Stack justify="flex-start" gap="md">
//             {title ? title : null}
//             <SearchInput
//                 pt={7}
//                 value={search}
//                 placeholder={t(`common.ingredients`)}
//                 onChange={(value) => onSearch(value)}
//             />
//
//             <Tabs variant="unstyled" defaultValue={"" + page} value={"" + page} classNames={classes}>
//                 <Space h="lg"/>
//                 {Array.from(firstLetters).map((firstLetter, index) => (
//                     <Tabs.Panel value={"" + (index + 1)}>
//                         <SimpleGrid cols={3} pl={10} pr={10}>
//                             {searchItems.map((item) => (
//                                 localized(item, 'name')[0].toUpperCase() !== firstLetter ? null :
//                                     <IngredientLink ingredient={item} ingredientTarget={ingredientTarget} ingredientHighlight={search} showCategory={showCategory} categoryTarget={categoryTarget}/>
//                             ))}
//                         </SimpleGrid>
//                     </Tabs.Panel>
//                 ))}
//             </Tabs>
//
//             <PaginatorAZ    color={"tertiary"} page={page} onPageChange={setPage} totalPages={firstLetters.length}
//                             letters={firstLetters}
//                             selectedCount={searchItems.length} totalCount={ingredients.length}/>
//         </Stack>
//     );
// }

/**
 * IngredientList
 *
 * @param entityCategoryId
 * @param molecularSearchTypes
 * @param ingredientIds
 * @param ingredientNames
 * @param search
 * @param random
 * @param onLoad
 * @param title
 * @param ingredientTarget
 * @param showCategory
 * @param categoryTarget
 * @returns {Element}
 * @constructor
 */
export const IngredientList = ({entityCategoryId, molecularSearchTypes, ingredientIds, ingredientNames, search, hideSearch = false, random, onLoad = (ids, totalCount) => {},
                                title, ingredientTarget = "_self", showCategory = true, categoryTarget = "_self"}) => {

    const {key, stateKey} =
        useKey({
            onKeyChange: () => {
                console.debug(`IngredientList`);
                // refetchItems();
            }
        });

    const { data: dataItems, isLoaded: isLoadedItems, totalCount: totalCountItems,
            reset: resetItems, refetch: refetchItems} =
        useIngredientAll({
            unfiltered: search !== undefined,
            entityCategoryId: entityCategoryId,
            molecularSearchTypes: molecularSearchTypes,
            ingredientIds: ingredientIds,
            ingredientNames: ingredientNames,
            search: search,
            random: random
        })

    const {isSuccess, isError} =
        useResult({
            isSuccess: isLoadedItems,
            onSuccess: () => {
                onLoad(dataItems.sort((a, b) => a.ingredientId - b.ingredientId).map(item => item.ingredientId), totalCountItems);
            }
        })

    return (!isSuccess ? null :
        <Ingredient09 ingredients={dataItems}
                      title={title}
                      ingredientTarget={ingredientTarget}
                      showCategory={showCategory}
                      categoryTarget={categoryTarget}
                      hideSearch={hideSearch}
                      // highlight={search}
    	/>
        // <IngredientAZ ingredients={items} title={title} ingredientTarget={ingredientTarget} showCategory={showCategory} categoryTarget={categoryTarget}/>
    );
}

