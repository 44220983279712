/**
 * @param entity
 * @returns {unknown[]}
 */
export function getEntityRepresentativeIngredient(entity)  {

	if (!entity.ingredients || entity.ingredients.length === 0) {
		return undefined;
	}

	for (let ingredient of entity.ingredients) {
		if (ingredient.representative) {
			return {
				...ingredient,
				category: entity.categories ? entity.categories[0] : undefined
			};
		}
	}

	return undefined;
}

/**
 * @param entity
 * @returns {unknown[]}
 */
export function getEntityNaturalSourceIngredient(entity)  {

	if (!entity.ingredients || entity.ingredients.length === 0) {
		return undefined;
	}

	for (let ingredient of entity.ingredients) {
		if (ingredient.naturalSourceName) {
			return {
				...ingredient,
			};
		}
	}

	return undefined;
}

/**
 * @param entity
 * @returns {unknown[]}
 */
export function getEntitySynonymIngredients(entity)  {

	if (!entity.ingredients || entity.ingredients.length === 0) {
		return undefined;
	}

	const result = [];

	for (let ingredient of entity.ingredients) {
		if (!ingredient.naturalSourceName && !ingredient.representative) {
			result.push({
				...ingredient,
			});
		}
	}

	return result;
}

// /**
//  * @param entity
//  * @returns {unknown[]}
//  */
// export function getEntityRandomSynonymIngredient(entity)  {
//
// 	if (!entity.ingredients) {
// 		return undefined;
// 	}
//
// 	const filteredIngredients = entity.ingredients.filter(ingredient =>
// 		ingredient.representative === 0 && ingredient.naturalSourceName === 0
// 	);
//
// 	if (filteredIngredients.length === 0) {
// 		return undefined;
// 	}
//
// 	const randomIndex = Math.floor(Math.random() * filteredIngredients.length);
// 	const selectedIngredient = filteredIngredients[randomIndex];
//
// 	return {
// 		...selectedIngredient,
// 		category: entity.categories ? entity.categories[0] : undefined
// 	};
// }