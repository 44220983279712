import React, {useState} from 'react';
import {Anchor, Group, Highlight, Text} from "@mantine/core";
import {localized} from "../../i18n";
import {useNavigate} from "react-router-dom";
import {keyState} from "../useKey";
import {useTranslation} from "react-i18next";
import {Icon} from "../../components/icons/Icons";
import {Settings, useAccountContext} from "../../components/account/AccountContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons/faHeart";
import {faHeart} from "@fortawesome/free-regular-svg-icons/faHeart";
import {useFeatures} from "../../components/features/useFeatures";

/**
 * MoleculeIconTitle
 */
export const MoleculeIconTitle = (props) => {

    const {textKey = 'common.molecules', color = "var(--mantine-color-white)", withIcon = true} = props;

    const { t } = useTranslation();

    return <Group gap="2" wrap={"nowrap"} {...props}>
        {!withIcon ? null :
            <Icon name={"physics"} style={{width: "18px", height: "18px", marginBottom: "-2px", fill: color}}/>
        }
        <Text pl={withIcon ? 4 : 0} size={"sm"} fw={700}>{t(textKey)}</Text>
    </Group>

}

/**
 * MoleculesLink
 *
 * @param textKey
 * @param target
 * @param style
 * @param className
 * @param onClick
 * @param children
 * @returns {Element}
 * @constructor
 */
export const MoleculesLink = ({textKey = "common.molecules", target = "_self", style, className, onClick = () => {}, children}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Anchor target={target} underline="never" style={{...style}} className={className}
                onClick={(event) => {moleculesNavigate(navigate, target); onClick(event);}}>
            {t(textKey)}
        </Anchor>
    );
};

/**
 * MoleculeLink
 *
 * @param molecule
 * @param target
 * @param style
 * @param highlight
 * @param className
 * @param onClick
 * @returns {Element}
 * @constructor
 */
export const MoleculeLink = ({molecule, target = "_self", style, highlight, className, onClick = () => {}}) => {

    const navigate = useNavigate();

    return (
        <Anchor target={target} underline="never" style={{...style}} className={className}
              onClick={(event) => {moleculeNavigate(navigate, molecule); onClick(event);}}>
          <Highlight highlight={highlight}>
              {localized(molecule, 'name')}
          </Highlight>
        </Anchor>
    );
};

/**
 * MoleculeText
 *
 * @param molecule
 * @param highlight
 * @param className
 * @param onClick
 * @param children
 * @returns {Element}
 * @constructor
 */
export const MoleculeText = ({molecule, highlight}) => {

    const { isAuthenticated, userAccount, updateUserAccountSettings } = useAccountContext();
    const [settings] = useState(isAuthenticated ? userAccount.getSettings() : new Settings({}));
    const [favorite, setFavorite] = useState(settings.isFavoriteMolecule(molecule.moleculeId));
    const { Features } = useFeatures();

    /**
     * handleFavorite
     */
    function handleFavorite() {
        settings.setFavoriteMolecule(molecule.moleculeId);
        updateUserAccountSettings(userAccount);
        setFavorite(settings.isFavoriteMolecule(molecule.moleculeId));
    }

    return (
        <Group gap={6} align="flex-start" wrap={"nowrap"}>

            {Features.account.features.moleculeFavorites.plan.enabled &&
                <FontAwesomeIcon icon={settings.isFavoriteMolecule(molecule.moleculeId) ? faHeartSolid : faHeart} size={"md"} color={"primary"} opacity={0.25}
                                 style={{height: "18px", paddingTop: "4px"}}
                                 onClick={(event) => {event.stopPropagation(); event.preventDefault(); handleFavorite()}}/>
            }

        	<Highlight highlight={highlight}>
            	{localized(molecule, 'name')}
        	</Highlight>
        </Group>
    );
};

/**
 * moleculesNavigate
 *
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
export function moleculesNavigate(navigate, target = "_self") {

    if(target === "_self") {
        navigate(`/molecules`, {state: {...keyState()}});
    }
    else {
        window.open(`/molecules`, "_blank");
    }
}

/**
 * moleculeNavigate
 *
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
export function moleculeNavigate(navigate, molecule, target = "_self") {

    if(!molecule) {
        return;
    }

    if(target === "_self") {
        navigate(`/molecule/${molecule.moleculeId}`, {state: {...keyState()}});
    }
    else {
        window.open(`/molecule/${molecule.moleculeId}`, "_blank");
    }
}

/**
 * moleculeIdNavigate
 *
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
export function moleculeIdNavigate(navigate, moleculeId, target = "_self") {

    if(!moleculeId) {
        return;
    }

    if(target === "_self") {
        navigate(`/molecule/${moleculeId}`, {state: {...keyState()}});
    }
    else {
        window.open(`/molecule/${moleculeId}`, "_blank");
    }
}