import React, {useContext, useEffect, useState} from 'react';
import {
    Stack,
    Space,
    useMantineTheme, Table, Text, Highlight
} from '@mantine/core';
import {localized} from "../../i18n";
import classes from "./MoleculeList.module.css";
import {useTranslation} from "react-i18next";
import {alphabeticComparator} from "../../util/utils";
import {SearchInput} from "../../components/search/Search";
import {
    paginationEndSkip,
    paginationStartSkip
} from "../../util/pagination";
import {Paginator} from "../../components/paginator/Paginator";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {moleculeNavigate, MoleculeText} from "./MoleculeLink";
import {useNavigate} from "react-router-dom";
import useKey from "../useKey";
import useMoleculeAll from "./useMoleculeAll";
import useResult from "../useResult";
import {FlavorDbCredits} from "../../components/credits/Credits";

/**
 * Molecule09
 *
 * @param title
 * @param hideSearch
 * @param molecules
 * @param moleculeTarget
 * @returns {Element}
 * @constructor
 */
export const Molecule09 = ({  title, hideSearch = false, molecules, moleculeTarget = "_self"}) => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");
    const [searchItems, setSearchItems] = useState([]);

    const navigate = useNavigate();

    const { t } = useTranslation();
    const theme = useMantineTheme();

    useEffect(() => {
        setSearch("");
    }, []);

    useEffect(() => {

        try {
            if(!search) {
                setSearchItems(molecules);
            }
            else {
                setSearchItems(
                    molecules.filter(molecule =>
                        localized(molecule, 'name').toLowerCase().includes(search.toLowerCase()) ||
                        molecule.flavors.some(flavor =>
                            localized(flavor, 'name').toLowerCase().includes(search.toLowerCase())
                        )
                    ));
            }
        }
        catch(ignored) {
            // noop
        }

    }, [search, molecules]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
        setPage(1);
    }

    return  (
        <Stack justify="flex-start" gap="0">
            {title ? title : null}
            <StripedTable highlightOnHover>
                {hideSearch ? null :
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th p={0} colSpan={3}>
                                <SearchInput
                                    value={search}
                                    placeholder={t('molecule.moleculeAndFlavor')}
                                    onChange={(value) => onSearch(value)}
                                />
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                }
                <Table.Tbody>

                    {hideSearch || searchItems.length === 0 ? null :
                        <Space h={"lg"}/>
                    }

                    {searchItems
                        .sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
                        .slice(paginationStartSkip(page, theme.custom.molecule.paginationSize), paginationEndSkip(page, theme.custom.molecule.paginationSize))
                        .map((item, index) => (
                            <Table.Tr style={{cursor: "pointer"}} onClick={() => moleculeNavigate(navigate, item)}>
                                <Table.Td w={"30%"}>
                                    <MoleculeText molecule={item} highlight={search}/>
                                </Table.Td>
                                <Table.Td>
                                    <Text size={"xs"}>
                                        <Highlight highlight={search}>
                                        {[...new Set(item.flavors.map(flavor => localized(flavor, 'name')))].sort(alphabeticComparator).join(', ')}
                                        </Highlight>
                                    </Text>
                                </Table.Td>
                                <Table.Td w={"30px"} pt={9} style={{verticalAlign: "middle"}} ta={"right"}>
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                </Table.Td>
                            </Table.Tr>
                        ))}
                </Table.Tbody>
            </StripedTable>
            <Space h={"lg"}/>
            <Paginator page={page} onPageChange={setPage}
                       paginationSize={theme.custom.molecule.paginationSize}
                       selectedCount={searchItems.length} totalCount={molecules.length}/>
            <FlavorDbCredits pl={"md"} pt={"md"} i18nKey={"common.flavordbMoleculesAttribution"}/>
        </Stack>
    );
}

/**
 * MoleculeList
 *
 * @param props
 * @returns {null|React.JSX.Element}
 * @constructor
 */
export const MoleculeList = ({   search, hideSearch = false, random, onLoad = (ids, totalCount) => {},
                                 title, andEntityIds, orEntityIds, moleculeIds, moleculeTarget = "_self"}) => {

    const {key, stateKey} =
        useKey({
            onKeyChange: () => {
                console.debug(`MoleculeList`);

                refetchItems();
            }
        });

    const { data: dataItems, isLoaded: isLoadedItems, totalCount: totalCountItems,
            reset: resetItems, refetch: refetchItems} =
        useMoleculeAll({
            andEntityIds: andEntityIds,
            orEntityIds: orEntityIds,
            moleculeIds: moleculeIds,
            search: search,
            random: random,
        })

    const {isSuccess, isError} =
        useResult({
            isSuccess: isLoadedItems,
            onSuccess: () => {
                onLoad(dataItems.sort((a, b) => a.moleculeId - b.moleculeId).map(item => item.moleculeId), totalCountItems);
            }
        })

    return (!isSuccess ? null :
        <Molecule09 molecules={dataItems}
                    title={title}
                    hideSearch={hideSearch}
                    moleculeTarget={moleculeTarget}/>
    );
}