import React, {useEffect, useState} from 'react';
import {
    Container,
    Text,
    Title,
    useMantineTheme,
    Timeline,
    Space,
    Pill,
    Group,
    ActionIcon,
    Table,
    Checkbox,
    Tabs, Stack, VisuallyHidden, Divider, Box, Overlay, Center, SimpleGrid, NumberFormatter, Button,
} from '@mantine/core';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import i18n, {localized, parameterLanguage} from "../../i18n";
import classes from './RecipePage.module.css';
import {Image169WithZoom, Page, PageTitle} from "../../components/page/Page";
import {convertToFraction, stringToImageSrc} from "../../util/utils";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronRight, faClock,
    faMinus,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {IngredientIconTitle, ingredientNavigate, IngredientText} from "../ingredient/IngredientLink";
import RecipeCategoryLink from "./RecipeCategoryLink";
import {Recipe09} from "./RecipeList";
import {SearchInput} from "../../components/search/Search";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {paginationEndSkip, paginationStartSkip} from "../../util/pagination";
import {Paginator} from "../../components/paginator/Paginator";
import {ScrollTab} from "../../components/scrollTab/ScrollTab";
import {Icon} from "../../components/icons/Icons";
import useKey from "../useKey";
import useRecipe from "./useRecipe";
import useRecipeImage from "./useRecipeImage";
import useIngredientAll from "../ingredient/useIngredientAll";
import useResult from "../useResult";
import {AdvertisingGrid} from "../../components/advertising/Advertising";
import {Molecule09} from "../molecule/MoleculeList";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import {RecipeAuthorUser, RecipeDiet, RecipeNew, sortedCategories} from "./RecipeUtil";
import {CustomCarousel} from "../../components/carousel/CustomCarousel";
import {YouTubeEmbed} from "../../components/youtube/YouTubeEmbed";
import {formatIsoDuration, toDate} from "../../util/time";
import useUser from "../useUser";
import TimerButton from "./TimerButton";
import {SchemaOrgCredits} from "../../components/credits/Credits";
import {Settings, useAccountContext} from "../../components/account/AccountContext";
import {MoleculeIconTitle} from "../molecule/MoleculeLink";
import {RecipeIconTitle, recipeNavigate} from "./RecipeLink";
import {faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons/faHeart";
import {faHeart} from "@fortawesome/free-regular-svg-icons/faHeart";
import {FOOD_FACTS_VIDEO_ID_LINK_NAME, localizedLink} from "../../util/links";
import useMoleculeAll from "../molecule/useMoleculeAll";
import useRecipeAll from "./useRecipeAll";
import {AmazonLink} from "../../components/amazon/Amazon";
import {homeNavigate} from "../home/HomeLink";
import {useFeatures} from "../../components/features/useFeatures";
import {Sponsors} from "../../components/sponsor/Sponsor";
import useInsights from "../useInsights";
import useMedia from "../useMedia";
import {theme} from "../../Theme";
import {accountNavigate} from "../account/AccountLink";

/**
 * @param a
 * @param b
 * @returns {number}
 */
function sortComparator(a, b) {

    const sortComparison = a.sort - b.sort;
    if (sortComparison !== 0) {
        return sortComparison;
    }

    return localized(a, "name").localeCompare(localized(b, "name"));
}

/**
 * @param entities
 * @returns {undefined|*|string}
 */
function getIngredient(ingredients, ingredientId) {

    if (!ingredients) {
        return undefined;
    }

    let result = ingredients.filter(ingredient => ingredient.ingredientId === ingredientId);

    if(result.length >= 0) {
        return result[0];
    }

    return undefined;
}

/**
 * getDietTypes
 */
function getDietTypes(recipe) {

    const result = [];

    if(recipe.diabeticDiet > 0) {
        result.push("DiabeticDiet");
    }
    if(recipe.glutenFreeDiet > 0) {
        result.push("GlutenFreeDiet");
    }
    if(recipe.halalDiet > 0) {
        result.push("HalalDiet");
    }
    if(recipe.hinduDiet > 0) {
        result.push("HinduDiet");
    }
    if(recipe.kosherDiet > 0) {
        result.push("KosherDiet");
    }
    if(recipe.lowCalorieDiet > 0) {
        result.push("LowCalorieDiet");
    }
    if(recipe.lowFatDiet > 0) {
        result.push("LowFatDiet");
    }
    if(recipe.lowLactoseDiet > 0) {
        result.push("LowLactoseDiet");
    }
    if(recipe.lowSaltDiet > 0) {
        result.push("LowSaltDiet");
    }
    if(recipe.veganDiet > 0) {
        result.push("VeganDiet");
    }
    if(recipe.vegetarianDiet > 0) {
        result.push("VegetarianDiet");
    }

    return result;
}

/**
 * Ingredients
 *
 * @param recipe
 * @param ingredients
 * @constructor
 */
const Ingredients = ({recipe, ingredients}) => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");

    const [searchItems, setSearchItems] = useState([]);

    const { isAuthenticated, userAccount, updateUserAccountSettings } = useAccountContext();
    const [settings] = useState(isAuthenticated ? userAccount.getSettings().getRecipe(recipe.recipeId) : new Settings({}));
    const [selectedIngredients, setSelectedIngredients] = useState(settings.ingredients || []);
    const [serving, setServing] = useState(settings.serving || recipe.serving);

    const { Features } = useFeatures();

    const navigate = useNavigate();
    const {t} = useTranslation();
    const theme = useMantineTheme();

    const { isSm } = useMedia();

    useEffect(() => {
        setSearch("");
    }, []);

    useEffect(() => {

        try {

            if(!search) {
                setSearchItems(recipe.ingredients);
            }
            else {
                setSearchItems(recipe.ingredients.filter(ingredient => localized(getIngredient(ingredients, ingredient.ingredientId), 'name').toLowerCase().includes(search.toLowerCase())))
            }
        }
        catch(ignored) {
            // noop
        }

    }, [search]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
    }

    /**
     * Increment serving
     */
    const incrementServing = () => {

        if(!settings.serving) {
            settings.serving = serving;
        }

        settings.serving = settings.serving + 1;
        updateUserAccountSettings(userAccount);
        setServing(settings.serving);
    }

    /**
     * Decrement serving
     */
    const decrementServing = () => {

        if(!settings.serving) {
            settings.serving = serving;
        }

        if(settings.serving > 1) {
            settings.serving = settings.serving - 1;
            updateUserAccountSettings(userAccount);
            setServing(settings.serving);
        }
    }

    /**
     * @param ingredientId
     */
    const handleCheckboxChange = (ingredientId) => {

        if(!settings.ingredients) {
            settings.ingredients = [];
        }

        // Update settings.ingredients based on whether ingredientId is present or not
        if (settings.ingredients.includes(ingredientId)) {
            // Remove ingredientId if it already exists
            settings.ingredients = settings.ingredients.filter(itemId => itemId !== ingredientId);
        } else {
            // Add ingredientId if it doesn't exist
            settings.ingredients = [...settings.ingredients, ingredientId];
        }

        updateUserAccountSettings(userAccount);

        // Update selected ingredients state with the new ingredients list
        setSelectedIngredients([...settings.ingredients]);
    };

    return (!recipe.ingredients ? null :
        <Stack justify="space-between" gap={"0"}>
            <Stack justify="flex-start" gap="0">
                <VisuallyHidden>
                    <Title order={2} pt={"lg"}>{t('recipe.ingredients')}</Title>
                </VisuallyHidden>
                <Space h="lg"/>
                <SimpleBox color={"secondary"}>
                    <Group justify="space-between" hiddenFrom={"sm"}>
                        <ActionIcon size={"38px"} onClick={decrementServing} color={"secondary"}>
                            <FontAwesomeIcon icon={faMinus} size={"md"}/>
                        </ActionIcon>
                        <Title order={3}>
                            {serving} {t("recipe.serving")}
                        </Title>
                        <ActionIcon size={"38px"} onClick={incrementServing} color={"secondary"}>
                            <FontAwesomeIcon icon={faPlus} size={"md"}/>
                        </ActionIcon>
                    </Group>
                    <Group justify="space-between" visibleFrom={"sm"}>
                        <Title order={3}>
                            {serving} {t("recipe.serving")}
                        </Title>
                        <Group gap={"xs"}>
                            <ActionIcon size={"38px"} onClick={decrementServing} color={"secondary"}>
                                <FontAwesomeIcon icon={faMinus} size={"md"}/>
                            </ActionIcon>
                            <ActionIcon size={"38px"} onClick={incrementServing} color={"secondary"}>
                                <FontAwesomeIcon icon={faPlus} size={"md"}/>
                            </ActionIcon>
                        </Group>
                    </Group>
                </SimpleBox>
                <Space h="lg"/>
                <StripedTable highlightOnHover>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th p={0} colSpan={isSm ? 4 : 2}>
                                <SearchInput
                                    value={search}
                                    placeholder={t('common.ingredient')}
                                    onChange={(value) => onSearch(value)}
                                />
                            </Table.Th>
                            <Table.Th w={"230px"} style={{textAlign: "right", verticalAlign: "top"}} visibleFrom={"sm"}>{t('common.amount')}</Table.Th>
                            <Table.Th w={"30px"} visibleFrom={"sm"}/>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>

                        {searchItems.length === 0 ? null :
                            <Space h={"lg"}/>
                        }

                        {searchItems
                            .sort(sortComparator)
                            .slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
                            .map((item, index) => (
                            <Table.Tr key={`ingredient-${index}`} style={{cursor: "pointer"}}>
                                {!Features.recipe.features.ingredientsBasket.plan.enabled ? null :
                                    <Table.Td w={"28px"} onClick={() =>  handleCheckboxChange(getIngredient(ingredients, item.ingredientId).name)}>
                                        <Checkbox size={18} radius={"sm"} pl={8} color={"secondary"}
                                                  classNames={{
                                                      input: classes.checkboxinput,
                                                  }}
                                                  checked={selectedIngredients.includes(getIngredient(ingredients, item.ingredientId).name)}
                                                  onChange={(event) => handleCheckboxChange(getIngredient(ingredients, item.ingredientId).name)}
                                                  style={{pointerEvents: "none"}}
                                                    />
                                    </Table.Td>
                                }
                                <Table.Td colSpan={!Features.recipe.features.ingredientsBasket.plan.enabled ? 2 : 1} style={{verticalAlign: "middle"}} onClick={() => ingredientNavigate(navigate, getIngredient(ingredients, item.ingredientId))}>
                                    {/*<IngredientLink ingredient={getIngredient(ingredients, item.ingredientId)} ingredientHighlight={search} ingredientTarget="_self" categoryTarget="_self"/>*/}
                                    <IngredientText ingredient={getIngredient(ingredients, item.ingredientId)} ingredientHighlight={search}/>
                                </Table.Td>
                                <Table.Td style={{textAlign: "right", verticalAlign: "middle"}} onClick={() => ingredientNavigate(navigate, getIngredient(ingredients, item.ingredientId))}>
                                    <Text>{`${item.amount ? convertToFraction(item.amount * serving) : ''} ${item.unit ? t('recipe.amountUnit.' + item.unit) : ''}`}</Text>
                                </Table.Td>
                                <Table.Td onClick={() => ingredientNavigate(navigate, getIngredient(ingredients, item.ingredientId))}>
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </StripedTable>
            </Stack>
            <Space h={"lg"}/>
            <Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.recipe.paginationSize}
                       selectedCount={searchItems.length} totalCount={recipe.ingredients.length}/>
            <SchemaOrgCredits pl={"md"} pt={"md"} i18nKey={"common.schemaOrgAttribution"}/>
        </Stack>
    )
}

/**
 * Instructions
 */
const Instructions = ({recipe, recipeImage}) => {

    const { isAuthenticated, userAccount, updateUserAccountSettings } = useAccountContext();
    const [settings] = useState(isAuthenticated ? userAccount.getSettings().getRecipe(recipe.recipeId) : new Settings({}));

    const { Features } = useFeatures();

    const [activeInstruction, setActiveInstruction] = useState(settings.activeInstruction !== undefined ? settings.activeInstruction : -1);

    const {t} = useTranslation();

    /**
     * @param ingredientId
     */
    const handleActiveInstruction = (index) => {

        if(index === 0 && settings.activeInstruction === 0) {
            settings.activeInstruction = -1;
        }
        else {
            settings.activeInstruction = index;
        }

        updateUserAccountSettings(userAccount);
        setActiveInstruction(settings.activeInstruction);
    };

    return (!recipe.instructions ? null :
            <>
                <VisuallyHidden>
                    <Title order={2} pt={"lg"}>{t('recipe.preparation')}</Title>
                    {/*<Space h="xs"/>*/}
                </VisuallyHidden>
                <Space h="lg"/>
                <Timeline color="secondary" bulletSize={Features.recipe.features.instructionsStepsTracking.plan.enabled ? 24 : 0} lineWidth={Features.recipe.features.instructionsStepsTracking.plan.enabled ? 2 : 0} active={activeInstruction}
                          classNames={{
                              item: classes.timelineitem,
                              itemBullet: classes.timelineitembullet
                          }}>
                    {recipe.instructions
                        .sort(sortComparator)
                        .map((instruction, index) => (
                            <Timeline.Item key={`preparation-${index}`}
                                           style={{cursor: 'pointer'}}
                                           bullet={Features.recipe.features.instructionsStepsTracking.plan.enabled ? instruction.sort : undefined}
                                           lineVariant={activeInstruction >= index ? "solid" : "dashed"}
                                           onClick={() => handleActiveInstruction(index)}>
                                <SimpleBox color={"secondary"} highlightOnHover={Features.recipe.features.instructionsStepsTracking.plan.enabled}>
                                    <Group justify="space-between">
                                        <Title order={3}>{localized(instruction, "name")}</Title>
                                        {!Features.recipe.features.instructionsStepsTimers.plan.enabled || !instruction.time ? null :
                                            <TimerButton key={recipe.recipeId + "." + instruction.recipeInstructionId} recipe={recipe} recipeImage={recipeImage} instruction={instruction}
                                                         onClick={() => handleActiveInstruction(index)}/>
                                        }
                                    </Group>
                                    <Space h={"lg"}/>
                                    <Text>{localized(instruction, "text")}</Text>
                                </SimpleBox>
                            </Timeline.Item>
                        ))}
                </Timeline>
                {Features.recipe.features.instructionsStepsTracking.plan.enabled &&
                    <>
                        <Space h="lg"/>
                        <SimpleBox color={"tertiary"} ml={40.5}>
                            <Group justify="flex-start" >
                                <Text size={"xs"}>{t(`recipe.preparationSteps`, {stepCount: recipe.instructions.length})}</Text>
                            </Group>
                        </SimpleBox>
                    </>
                }
            </>
    )
}

/**
 * Storage
 *
 * @param recipe
 * @constructor
 */
const Storage = ({recipe}) => {

    const {t} = useTranslation();

    return (
        <SimpleBox color={"tertiary"} mt={"lg"}>
            <Title order={2}>{t('recipe.storage')}</Title>
            <Space h="lg"/>
            <Text>{localized(recipe, 'storage')}</Text>
        </SimpleBox>
    )
}

/**
 * Tips
 *
 * @param recipe
 * @constructor
 */
const Tips = ({recipe}) => {

    const {t} = useTranslation();

    return (
        <SimpleBox color={"tertiary"} mt={"lg"}>
            <Title order={2}>{t('recipe.tips')}</Title>
            <Space h="lg"/>
            <Text>{localized(recipe, 'tip')}</Text>
        </SimpleBox>
    )
}

/**
 * Utensils
 *
 * @param recipe
 * @constructor
 */
const Utensils = ({recipe}) => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");
    const [searchItems, setSearchItems] = useState([]);

    const {t} = useTranslation();
    const theme = useMantineTheme();

    const { isSm } = useMedia();

    useEffect(() => {
        setSearch("");
    }, []);

    useEffect(() => {

        try {

            if (!search) {
                setSearchItems(recipe.utensils);
            }
            else {
                setSearchItems(recipe.utensils.filter(utensil => localized(utensil, 'name').toLowerCase().includes(search.toLowerCase())))
            }
        }
        catch(ignored) {
            // noop
        }

    }, [search]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
    }

    return (!recipe.utensils ? null :
        <Stack justify="space-between" gap={"0"}>
            <Stack justify="flex-start" gap="0">
                <VisuallyHidden>
                    <Title order={2} pt={"lg"}>{t('recipe.utensils')}</Title>
                </VisuallyHidden>
                <Space h="lg"/>
                <StripedTable stripedColor={"white"} style={{color: "var(--mantine-color-tertiary-9)"}}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th p={0}>
                                <SearchInput
                                    value={search}
                                    placeholder={t('recipe.utensilsDescription')}
                                    onChange={(value) => onSearch(value)}
                                />
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        <Table.Tr key={`utensil`}>
                            <Table.Td style={{padding: 0}}>
                                    {searchItems.length === 0 ? null :
                                        <Space h={"lg"}/>
                                    }
                                    <SimpleGrid cols={!isSm ? 2 : 1}>
                                        {searchItems
                                            .sort(sortComparator)
                                            .slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
                                            .map((item, index) => (

                                                <AmazonLink utensil={item} highlight={search} showDescription/>
                                        ))}
                                    </SimpleGrid>
                                </Table.Td>
                        </Table.Tr>
                    </Table.Tbody>
                </StripedTable>
            </Stack>
            <Space h={"lg"}/>
            <Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.recipe.paginationSize}
                       selectedCount={searchItems.length} totalCount={recipe.utensils.length}/>
            {/*<Space h="lg"/>*/}
            {/*<SimpleBox color="tertiary">*/}
            {/*    <Text size={"sm"}>{t("recipe.utensilsDescription")}</Text>*/}
            {/*</SimpleBox>*/}
            <SchemaOrgCredits pl={"md"} pt={"md"} i18nKey={"common.schemaOrgAttribution"}/>
        </Stack>
    )
}

/**
 * RecipePage
 *
 * @param props
 * @returns {React.JSX.Element|*[]}
 * @constructor
 */
const RecipePage = (props) => {

    const {paramLng} = useParams();
    const lng = parameterLanguage(paramLng);

    const {paramRecipeId} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const recipeId = location.state?.recipeId || paramRecipeId;

    const { isAuthenticated } = useAccountContext();

    const { Features } = useFeatures();

    const [autoplayActivable, setAutoplayActivable] = useState(true);

    const [pageRecipes, setPageRecipes] = useState(1);
    const [previousTotalCountRecipes, setPreviousTotalCountRecipes] = useState(0);

    const {isLoading, isLoaded} = useLoadingContext();

    const [tabValue, setTabValue] = useState("0");
    const { t } = useTranslation();

    const {key, stateKey, random} =
        useKey({
			isLoading: isLoading,
			onKeyChange: () => {
                console.debug(`RecipePage`);

                setTabValue("0");

                setPageRecipes(1);
                setPreviousTotalCountRecipes(0);

                resetUser();
                resetMolecules();
                resetRecipes();
                resetIngredients();
                resetItemImage();

                refetchItem();
			}
		});

    useEffect(() => {
        if(lng !== undefined && lng !== i18n.language) {
            i18n.changeLanguage(lng)
                .then(value => window.location.reload());
        }
    }, [lng]);

    const {	data: dataItem, isLoaded: isLoadedItem, ingredientIds,
        	reset: resetItem, refetch: refetchItem} =
        useRecipe({
            recipeId: recipeId,
            incrementViews: true,
            onError: (error) => {
                homeNavigate(navigate);
            }
        })

    const {	data: dataInsights, isLoaded: isLoadedInsights,
        reset: resetInsights, refetch: refetchInsights} =
        useInsights({
            enabled: isLoadedItem,
            collectionName: "recipe",
            id: dataItem.recipeId
        })

    const {	data: dataItemImage, isLoaded: isLoadedItemImage,
        	reset: resetItemImage, refetch: refetchItemImage} =
        useRecipeImage({
            enabled: isLoadedItem && dataItem?.recipeId !== undefined,
            recipeId: dataItem.recipeId
        })

    const {	data: dataIngredients, isLoaded: isLoadedIngredients, entityIds,
        	reset: resetIngredients, refetch: refetchIngredients} =
        useIngredientAll({
            enabled: isLoadedItem && ingredientIds?.length > 0,
            ingredientIds: ingredientIds
        })

    const {	data: dataRecipes, isLoaded: isLoadedRecipes, totalCount: totalCountRecipes,
        reset: resetRecipes, refetch: refetchRecipes} =
        useRecipeAll({
            enabled: isLoadedItem && dataItem?.recipeId !== undefined && ingredientIds !== undefined && ingredientIds.length > 0,
            page: pageRecipes,
            excludeRecipeId: dataItem.recipeId,
            ingredientIds: ingredientIds,
            onSuccess: (data, totalCount, count) => {
                setPreviousTotalCountRecipes(totalCount);
            }
        })

    useEffect(() => {
        refetchRecipes();
    }, [pageRecipes]);

    const { data: dataMolecules, isLoaded: isLoadedMolecules, totalCount: totalCountMolecules,
        reset: resetMolecules, refetch: refetchMolecules} =
        useMoleculeAll({
            enabled: Features.recipe.features.relatedMolecules.plan.enabled && isLoadedIngredients && entityIds?.length > 0,
            orEntityIds: entityIds
        })

    const { data: dataUser, isLoaded: isLoadedUser,
        reset: resetUser, refetch: refetchUser} =
        useUser({
            enabled: isLoadedItem && dataItem?.author !== undefined,
            userId: dataItem.author
        })

    const {isSuccess, isError} =
        useResult({
            isLoaded: isLoaded,
            isSuccess: isLoadedItem && isLoadedItemImage && isLoadedIngredients /*&& isLoadedRecipes && isLoadedMolecules*/ && isLoadedUser,
            onSuccess: () => {

            }
        })

    // useEffect(() => {
    //
    //     console.debug(
    //         `isSuccessItem ${isSuccessItem}\n` +
    //         `isLoadedItem ${isLoadedItem}\n` +
    //         `isSuccessItemImage ${isSuccessItemImage}\n` +
    //         `isLoadedItemImage ${isLoadedItemImage}\n` +
    //         `isSuccessIngredients ${isSuccessIngredients}\n` +
    //         `isLoadedIngredients ${isLoadedIngredients}\n`
    //     )
    // }, [isSuccessItem, isLoadedItem, isSuccessItemImage, isLoadedItemImage, isSuccessIngredients, isLoadedIngredients]);

    /**
     * ImageSection
     */
    const ImageSection = ({recipe, user}) => {

        const theme = useMantineTheme();
        const { isSm } = useMedia();

        return <Image169WithZoom src={stringToImageSrc(dataItemImage)}
                                 withPointer
                                 withZoom={!isSm}
                                 withFullscreen={!isSm}
                                 leftIcon={<RecipeNew recipe={recipe}/>}
                                 rightIcon={<RecipeAuthorUser user={user} className={classes.recipeimage169avatar}/>}/>;
    }

    /**
     * RecipeCard
     */
    const RecipeCard = () => {

        return (

            <StripedTable stripedColor={"tertiary"} className={classes.recipecardtable}>
                <Table.Tbody>

                    <Table.Tr>
                        <Table.Td pl={0}>
                            <IngredientIconTitle />
                        </Table.Td>
                        <Table.Td pr={0} ta={"right"}>
                            <Text>
                                {dataItem.ingredients.length}
                            </Text>
                        </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                        <Table.Td pl={0}>
                            <Text size={"sm"} fw={700}>
                                {t('recipe.steps')}
                            </Text>
                        </Table.Td>
                        <Table.Td pr={0} ta={"right"}>
                            <Text>
                                {dataItem.instructionsCount}
                            </Text>
                        </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                        <Table.Td pl={0}>
                            <Text size={"sm"} fw={700}>
                                {t('recipe.utensils')}
                            </Text>
                        </Table.Td>
                        <Table.Td pr={0} ta={"right"}>
                            <Text>
                                {dataItem.utensilsCount}
                            </Text>
                        </Table.Td>
                    </Table.Tr>
                    {!dataMolecules || dataMolecules.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <MoleculeIconTitle />
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {totalCountMolecules}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                    {previousTotalCountRecipes === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <RecipeIconTitle textKey={'ingredient.showRecipes'}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {previousTotalCountRecipes}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                </Table.Tbody>
            </StripedTable>
        );
    }

    /**
     * Description
     */
    const Description = ({recipe, user}) => {

        return (
            <>
                <Text size={"sm"}>{localized(recipe, 'description')}</Text>
                <Text size={"sm"} pt={"xs"}>{localized(recipe, 'curiosity')}</Text>
                <Text size={"sm"} pt={"xs"} fs="italic">{t("recipe.author", {author: user.name, date: toDate(recipe.date)})}</Text>
                <Group gap={4} pt={"md"}>
                    {!recipe.keywords ? null : recipe.keywords.sort(sortComparator).map((keyword, index) => (
                        <Pill key={`keyword-${index}`} mr={4}
                              classNames={{
                                  root: classes.pillroot
                              }}>
                            #{localized(keyword, 'name')}
                        </Pill>
                    ))}
                </Group>
            </>
        )
    }

    // /**
    //  * RecipeSubComponent
    //  */
    // const RecipeSubTitleComponent = ({recipe}) => {
    //
    //     return (
    //         <Group gap={4}>
    //             {!recipe.keywords ? null : recipe.keywords.sort(sortComparator).map((keyword, index) => (
    //                 <Pill key={`keyword-${index}`} mr={4}
    //                       classNames={{
    //                           root: classes.pillroot
    //                       }}>
    //                     #{localized(keyword, 'name')}
    //                 </Pill>
    //             ))}
    //         </Group>
    //     )
    // }

    /**
     * RecipeSubComponent
     */
    const RecipeSubComponent = ({recipe}) => {

        const theme = useMantineTheme();

        /**
         * Group1
         */
        const Group1 = ({width= "15%"}) => {

            return <>
                <Stack align="stretch" justify="center" gap="0" w={width}>
                    <Group justify="center" gap="6">
                        <FontAwesomeIcon icon={faClock} style={{color: "var(--mantine-color-white)"}}/>
                        <Text c={"var(--mantine-color-white)"} ta={"center"}>{formatIsoDuration(recipe.totalTime)}</Text>
                    </Group>
                    <Text c={"var(--mantine-color-white)"} ta={"center"} size={'xs'} opacity={0.75}>
                        {t("recipe.totalTime")}
                    </Text>
                </Stack>
                <Divider w={1} orientation="vertical" color={"var(--mantine-color-white)"} opacity={0.1}/>
                <Stack align="stretch" justify="center" gap="0" w={width}>
                    <Group justify="center" gap="6">
                        <FontAwesomeIcon icon={faClock} style={{color: "var(--mantine-color-white)"}}/>
                        <Text c={"var(--mantine-color-white)"} ta={"center"}>{formatIsoDuration(recipe.prepTime)}</Text>
                    </Group>
                    <Text c={"var(--mantine-color-white)"} ta={"center"} size={'xs'} opacity={0.75}>
                        {t("recipe.preparation")}
                    </Text>
                </Stack>
                <Divider w={1} orientation="vertical" color={"var(--mantine-color-white)"} opacity={0.1}/>
                <Stack align="stretch" justify="center" gap="0" w={width}>
                    <Group justify="center" gap="6">
                        <FontAwesomeIcon icon={faClock} style={{color: "var(--mantine-color-white)"}}/>
                        <Text c={"var(--mantine-color-white)"} ta={"center"}>{formatIsoDuration(recipe.cookTime)}</Text>
                    </Group>
                    <Text c={"var(--mantine-color-white)"} ta={"center"} size={'xs'} opacity={0.75}>
                        {t("recipe.cooking")}
                    </Text>
                </Stack>
            </>
        }

        /**
         * Group2
         */
        const Group2 = ({width= "15%"}) => {

            return <>
                {recipe.difficulty === undefined ? <Stack w={width}/> :
                    <Stack align="stretch" justify="center" gap="0" w={width}>
                        <Group justify="center" gap="6">
                            <Icon name={recipe.difficulty} style={{width: "18px", height: "18px", fill: "var(--mantine-color-white)", marginBottom: "-2px"}}/>
                        </Group>
                        <Text c={"var(--mantine-color-white)"} ta={"center"} size={'xs'} opacity={0.75}>
                            {t("recipe.difficulty")}
                        </Text>
                    </Stack>
                }
                <Divider w={1} orientation="vertical" color={"var(--mantine-color-white)"} opacity={0.1}/>
                {recipe.costType === undefined ? <Stack w={width}/> :
                    <Stack align="stretch" justify="center" gap="0" w={width}>
                        <Group justify="center" gap="6">
                            <Icon name={recipe.costType} style={{width: "18px", height: "18px", fill: "var(--mantine-color-white)", marginBottom: "-2px"}}/>
                        </Group>
                        <Text c={"var(--mantine-color-white)"} ta={"center"} size={'xs'} opacity={0.75}>
                            {t("recipe.cost")}
                        </Text>
                    </Stack>
                }
                <Divider w={1} orientation="vertical" color={"var(--mantine-color-white)"} opacity={0.1}/>
                {recipe.season === undefined ? <Stack w={width}/> :
                    <Stack align="stretch" justify="center" gap="0" w={width}>
                        <Group justify="center" gap="6">
                            <Icon name={recipe.season} style={{width: "18px", height: "18px", fill: "var(--mantine-color-white)", marginBottom: "-2px"}}/>
                        </Group>
                        <Text c={"var(--mantine-color-white)"} ta={"center"} size={'xs'} opacity={0.75}>
                            {t("recipe.season")}
                        </Text>
                    </Stack>
                }
            </>
        }

        return (
            <Box className={classes.recipesubcomponentroot}>

                <Overlay className={classes.recipesubcomponentoverlay} backgroundOpacity={0.05} blur={14} color="var(--mantine-color-white)"/>

                <Center pb={"md"}>
                    <RecipeDiet recipe={recipe} color={"white"}/>
                </Center>

                <Group justify="space-between" gap="0"  wrap={"flex-wrap"} showFrom={"sm"} visibleFrom={"sm"}>
                    <Group1/>
                    <Divider w={1} orientation="vertical" color={"var(--mantine-color-white)"} opacity={0.1}/>
                    <Group2/>
                </Group>

                <Box hiddenFrom={"sm"}>
                    <Group justify="space-between" gap="0" wrap={"flex-wrap"} pb={"md"}>
                        <Group1 width={"33%"}/>
                    </Group>
                    <Group justify="space-between" gap="0" wrap={"flex-wrap"} >
                        <Group2 width={"33%"}/>
                    </Group>
                </Box>

                {isAuthenticated &&
                    <SubTitleComponent recipe={recipe}/>
                }
            </Box>
        )
    }

    /**
     * DisplayTitle
     */
    const DisplayTitle = ({recipe}) => {

        const { isAuthenticated, userAccount, updateUserAccountSettings } = useAccountContext();
        const [settings] = useState(isAuthenticated ? userAccount.getSettings() : new Settings({}));
        const [favorite, setFavorite] = useState(settings.isFavoriteRecipe(recipe.recipeId));

        /**
         * handleFavorite
         */
        function handleFavorite() {
            settings.setFavoriteRecipe(recipe.recipeId);
            updateUserAccountSettings(userAccount);
            setFavorite(settings.isFavoriteRecipe(recipe.recipeId));
        }

        return <Group align={"flex-start"} gap={6} wrap={"nowrap"}>
                    {Features.account.features.recipeFavorites.plan.enabled &&
					    <FontAwesomeIcon icon={settings.isFavoriteRecipe(recipe.recipeId) ? faHeartSolid : faHeart} size={"md"} color={"white"} opacity={0.25}
                                     style={{cursor: "pointer", paddingTop: "8px", height: "38px"}}
									 onClick={(event) => {event.stopPropagation(); event.preventDefault(); handleFavorite()}}/>
                    }
					<Stack gap={0} align="flex-start" justify="flex-start">
						<Text lineClamp={2} inherit>
							{localized(recipe, 'name')}
						</Text>
						<Text size={"xs"} pl={2} pt={"xs"} ff={theme.fontFamily}><NumberFormatter thousandSeparator="'" value={dataInsights.views}/> {t("common.views")}</Text>
					</Stack>
				</Group>
    }

    /**
     * SubTitleComponent
     */
    const SubTitleComponent = ({recipe}) => {

        const {isAuthenticated, userAccount, updateUserAccountSettings, onUpdateUserAccountSettings, offUpdateUserAccountSettings} = useAccountContext();
        const [settings] = useState(isAuthenticated ? userAccount.getSettings().getRecipe(recipe.recipeId) : new Settings({}));

        const [validRecipe, setValidRecipe] = useState(isAuthenticated ? userAccount.getSettings().isRecipeValid(recipe.recipeId) : false);

        const { isSm } = useMedia();

        // Define the callback to execute on update
        const handleOnUpdateUserAccount = () => {
            setValidRecipe(isAuthenticated ? userAccount.getSettings().isRecipeValid(recipe.recipeId) : false);
        };

        useEffect(() => {

            // Subscribe to the settings update event
            onUpdateUserAccountSettings(handleOnUpdateUserAccount);

            // Clean up by unsubscribing when component unmounts
            return () => {
                offUpdateUserAccountSettings(handleOnUpdateUserAccount);
            };

        }, [onUpdateUserAccountSettings, offUpdateUserAccountSettings]);

        return (
            <Group justify={!isSm ? "flex-end" : "center"} mt={"md"} mr={"md"}>

                {!validRecipe ? null :
                    <Button color={"secondary"} onClick={() => {accountNavigate(navigate)}}>{t("recipe.basket")}</Button>
                }

                <Button color={"secondary"} onClick={() => {

                    if(!validRecipe) {
                        settings.active = true;
                        updateUserAccountSettings(userAccount);
                    }
                    else {
                        settings.active = false;
                        settings.serving = 1;
                        settings.activeInstruction = -1;
                        settings.ingredients = [];
                        updateUserAccountSettings(userAccount);
                        recipeNavigate(navigate, recipe);
                    }

                }} >{validRecipe ? t("recipe.endCooking") : t("recipe.startCooking")}</Button>
            </Group>
        )
    }

    function getSchema(recipe) {



    }

    return (!isSuccess ? null :
        <Page
            pageTitle={localized(dataItem, 'name')}
            pageDescription={localized(dataItem, 'description')}
            pageKeywords={`recipe,${localized(dataItem, 'name')}`}
            pageUrl={`/recipe/${dataItem.recipeId}`}
            pageImage={`${window.location.origin}/api/v1/recipe/image/${dataItem.recipeId}.jpeg`}
            pageSchema={
                {
                    "@context": "https://schema.org",
                    "@type": "Recipe",
                    "name": localized(dataItem, 'name'),
                    "recipeYield": dataItem.yield,
                    "recipeCategory": dataItem.categories?.map((category, index) => localized(category, 'name')).join(","),
                    "recipeCuisine": localized(dataItem, 'cuisine'),
                    "keywords": dataItem.keywords.map((keyword, index) => localized(keyword, 'name')).join(","),
                    "image":`${window.location.origin}/api/v1/recipe/image/${dataItem.recipeId}.jpeg`,
                    "author": {
                        "@type": "Person",
                        "name": dataItem.author,
                    },
                    "datePublished": dataItem.date,
                    "description": localized(dataItem, 'description'),
                    "recipeIngredient": dataItem.ingredients.map((ingredient, index) => ({
                        "@type": "HowToSupply",
                        "name": `${localized(getIngredient(dataIngredients, ingredient.ingredientId), "name")}`,
                        "url": `${window.location.origin}/ingredient/name/${getIngredient(dataIngredients, ingredient.ingredientId).name}`,
                        "amount": `${ingredient.amount ? convertToFraction(ingredient.amount * dataItem.serving) + " " : ''}${ingredient.unit ? t('recipe.amountUnit.' + ingredient.unit) + " " : ''}`
                    })),
                    "recipeInstructions": dataItem.instructions.map((instruction, index) => ({
                        "@type": "HowToStep",
                        "name": localized(instruction, 'name'),
                        "text": localized(instruction, 'text'),
                        "time": instruction.time,
                    })),
                    "nutrition": {
                        "@type": "NutritionInformation",
                        "servingSize": `${dataItem.serving} serving`,
                        "calories": `${dataItem.caloriesPerServing} calories`,
                    },
                    "cookTime": dataItem.cookTime,
                    "prepTime": dataItem.prepTime,
                    "totalTime": dataItem.totalTime,
                    "tool": dataItem.utensils.map((utensil, index) => ({
                        "@type": "HowToTool",
                        "name": localized(utensil, 'name')
                    })),
                    "suitableForDiet": getDietTypes(dataItem),
                    "difficulty": dataItem.difficulty,
                    "season": dataItem.season,
                    "tip": localized(dataItem, 'tip'),
                    "curiosity": localized(dataItem, 'curiosity'),
                    "storage": localized(dataItem, 'storage'),
                }
            }
            svgZIndex={1}
            hasTab
            pageTitles={[
                <PageTitle
                    displayTitle={<DisplayTitle recipe={dataItem}/>}
                    backgroundImageSrc={stringToImageSrc(dataItemImage)}
                    backgroundImageBlur={0}
                    breadcrumb={sortedCategories(dataItem.categories).map((category, index) => (
                        category.parentRecipeCategoryId ?
                            <PageTitle.Breadcrumb>
                                <Text>
                                    {localized(category, 'name')}
                                </Text>
                            </PageTitle.Breadcrumb>
                            :
                            <PageTitle.Breadcrumb type={"a"}>
                                <RecipeCategoryLink recipeCategory={category}/>
                            </PageTitle.Breadcrumb>
                    ))
                    }
                    withDescription
                    descriptionComponent={<Description recipe={dataItem} user={dataUser}/>}
                    // withSubTitle={isAuthenticated}
                    // subTitleComponent={<SubTitleComponent recipe={dataItem}/>}
                    withCard
                    cardSection = {<CustomCarousel autoplayActivable={autoplayActivable} color={"tertiary"}>
                        <CustomCarousel.Slide>
                            <ImageSection recipe={dataItem} user={dataUser}/>
                        </CustomCarousel.Slide>
                        {dataIngredients.map((item, index) => (
                            localizedLink(item.links, FOOD_FACTS_VIDEO_ID_LINK_NAME) === undefined ? null :
                                <CustomCarousel.Slide>
                                    <YouTubeEmbed videoId={localizedLink(item.links, FOOD_FACTS_VIDEO_ID_LINK_NAME)}
                                                  onPlay={() => setAutoplayActivable(false)}
                                                  onPause={() => setAutoplayActivable(true)}
                                                  onStop={() => setAutoplayActivable(true)}
                                    />
                                </CustomCarousel.Slide>
                        ))}
                    </CustomCarousel>}
                    cardComponent = {<RecipeCard/>}
                    titleIcon={<Icon name={"hat-chef"}/>}
                    withSub
                    subComponent={<RecipeSubComponent recipe={dataItem}/>}
                    hasTabs
                    />
            ]}>

                <Tabs defaultValue="0" variant="outline" value={tabValue}>

                    <Container>

                        <ScrollTab>

                            <ScrollTab.Tab selected={tabValue === "0"} onClick={() => setTabValue("0")}>
                                <IngredientIconTitle />
                            </ScrollTab.Tab>

                            <ScrollTab.Tab selected={tabValue === "1"} onClick={() => setTabValue("1")}>
                                <Text size={"sm"} fw={700}>
                                    {t('recipe.preparation')}
                                </Text>
                            </ScrollTab.Tab>

                            <ScrollTab.Tab selected={tabValue === "2"} onClick={() => setTabValue("2")}>
                                <Text size={"sm"} fw={700}>
                                    {t('recipe.utensils')}
                                </Text>
                            </ScrollTab.Tab>

                            {!dataMolecules || dataMolecules.length === 0 ? null :
                                <ScrollTab.Tab selected={tabValue === "3"} onClick={() => setTabValue("3")}>
                                    <MoleculeIconTitle />
                                </ScrollTab.Tab>
                            }

                            {previousTotalCountRecipes === 0 ? null :
                                <ScrollTab.Tab selected={tabValue === "4"} onClick={() => setTabValue("4")}>
                                    <RecipeIconTitle textKey={'ingredient.showRecipes'}/>
                                </ScrollTab.Tab>
                            }
                        </ScrollTab>

                        <AdvertisingGrid>

                            <Tabs.Panel value="0" pb="xs">
                                <Ingredients recipe={dataItem} ingredients={dataIngredients}/>
                            </Tabs.Panel>

                            <Tabs.Panel value="1" pb="xs">
                                <Instructions recipe={dataItem} recipeImage={dataItemImage}/>
                                <Storage recipe={dataItem}/>
                                <Tips recipe={dataItem}/>
                                <SchemaOrgCredits pl={"md"} pt={"md"} i18nKey={"common.schemaOrgAttribution"}/>
                            </Tabs.Panel>

                            <Tabs.Panel value="2" pb="xs">
                                <Utensils recipe={dataItem}/>
                            </Tabs.Panel>

                            {!dataMolecules || dataMolecules.length === 0 ? null :
                                <Tabs.Panel value="3" pb="xs">

                                    <Molecule09 molecules={dataMolecules}
                                                title={
                                                    <>
                                                        <VisuallyHidden>
                                                            <Title order={2} pt={"lg"}>{t('common.molecules')}</Title>
                                                        </VisuallyHidden>
                                                        <Space h="lg"/>
                                                    </>
                                                }
                                    />

                                </Tabs.Panel>
                            }

                            {previousTotalCountRecipes === 0 ? null :
                                <Tabs.Panel value="4" pb="xs">
                                                <Recipe09 title={
                                                    <>
                                                        <VisuallyHidden>
                                                            <Title order={2} pt={"lg"}>{t('recipe.showRecipes')}</Title>
                                                        </VisuallyHidden>
                                                        <Space h="lg"/>
                                                    </>
                                                }
                                              recipes={dataRecipes}
                                              page={pageRecipes}
                                              onPageChange={setPageRecipes}
                                              totalCount={totalCountRecipes}
                                              recipeTarget="_self"
                                              categoryTarget="_self"/>
                                </Tabs.Panel>
                            }

                            <Space h={"lg"}/>
                            <Sponsors section={"recipe"} id={dataItem.recipeId}/>

                        </AdvertisingGrid>
                    </Container>
                </Tabs>




            {/*TODO Remove*/}

            {true ? null :
                <pre><code style={{fontSize: 11}}>{JSON.stringify(dataItem, null, 4)}</code></pre>
            }
        </Page>
    );
}

export default RecipePage;