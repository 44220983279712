import React, {useEffect, useRef, useState} from 'react';
import classes from './ScrollTab.module.css';
import {Box, Button, Group, ScrollArea} from "@mantine/core";
import {useInViewport} from "@mantine/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsis} from "@fortawesome/free-solid-svg-icons";
import {isMobile} from 'react-device-detect';

/**
 * ScrollTab
 *
 * @param props
 * @returns {Element}
 */
export const ScrollTab = (props) => {

    const {children,
        mt = -38,
        width = "100%",
        height = "100%",
        justify,
        scrollbarClassNames= {
            scrollbar: classes.scrollbar,
            corner: classes.corner,
            thumb:classes.thumb
        }

    } = props;

    const viewportRef = useRef(null);
    const innerRef = useRef(null);
    const { ref: ref1, inViewport: inViewport1 } = useInViewport(); // Left marker
    const { ref: ref2, inViewport: inViewport2 } = useInViewport(); // Right marker

    const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });

    useEffect(() => {
        // noop
    }, [scrollPosition]);

    /**
     * @param event
     */
    const handleClick = (event) => {

        return;

        // const clickedElement = event.target.parentElement.parentElement.parentElement; // L'elemento che è stato cliccato
        //
        // console.log(clickedElement)
        //
        // if(clickedElement.classList.contains("scrolltabtab")) {

            // const viewportRect = viewportRef.current.getBoundingClientRect();
            // console.log(viewportRect)
            //
            // const boxRect = boxRef.current.getBoundingClientRect();
            // console.log(boxRect)
            //
            // const elementRect = clickedElement.getBoundingClientRect();
            // console.log(elementRect)
            //
            // let elementBoxPos = 0;
            //
            // // Right side
            // if(elementRect.left + elementRect.width > boxRect.width / 2) {
            //     elementBoxPos = elementRect.left + Math.abs(boxRect.left) + elementRect.width;
            //     console.log("element box pos "+ elementBoxPos);
            // }
            // // Left side
            // else {
            //     elementBoxPos = elementRect.left + Math.abs(boxRect.left);
            //     console.log("element box pos "+ elementBoxPos);
            // }
            //
            // const scrollTo = elementBoxPos * (boxRect.width - viewportRect.width) / viewportRect.width;
            // console.log("scrollTo "+ scrollTo);
            //
            // viewportRef.current?.scrollTo({ left: scrollTo, behavior: 'smooth' })

            // const left = elementRect.left + (elementRect.width / 2) < (boxRect.left + boxRect.width) - (viewportRect.left + viewportRect.width) / 2;
            //
            //
            // if(left) {
            //     const scrollTo = (viewportRect.left + viewportRect.width) * (elementRect.left + elementRect.width) / (boxRect.left + boxRect.width);
            //     viewportRef.current?.scrollTo({ left: scrollTo, behavior: 'smooth' })
            // }
            // else {
            //     const scrollTo = (viewportRect.left + viewportRect.width) * elementRect.left / (boxRect.left + boxRect.width);
            //     viewportRef.current?.scrollTo({ right: scrollTo, behavior: 'smooth' })
            // }
        // }
    };

    // /**
    //  * @param position
    //  */
    // const scrollTo = (position) => {
    //
    //     const viewportRect = viewportRef.current.getBoundingClientRect();
    //     const innerRect = innerRef.current.getBoundingClientRect();
    //
    //     const range = ((innerRect.width - viewportRect.width) / 2) + 20 ;
    //     if(position === "start") {
    //         viewportRef.current?.scrollTo({ left: scrollPosition.x - range, behavior: 'smooth' });
    //     }
    //     else {
    //         viewportRef.current?.scrollTo({ left: scrollPosition.x + range, behavior: 'smooth' });
    //     }
    // }

    return (

        <Box mt={mt} w={width} h={height}>

            <ScrollArea viewportRef={viewportRef}
                        type={isMobile ? "scroll" : "hover"}
                        classNames={scrollbarClassNames}
                        onClick={handleClick}
                        onScrollPositionChange={onScrollPositionChange}
                        offsetScrollbars={true}
                        scrollbars={"x"}
                        scrollHideDelay={300}
                        style={{overflow: "visible"}}
            >

                {scrollPosition.x === 0 ? null :
                    <Box className={classes.navigation} style={{left: 0, marginLeft: "calc(var(--mantine-spacing-lg) * -1)"}}
                         // onClick={() => scrollTo("start")}
                        visibleFrom={"sm"}
                        >
                        <FontAwesomeIcon icon={faEllipsis}/>
                    </Box>
                }

                <Group  {...props}
                        gap={0}
                        ref={innerRef}
                        wrap={"nowrap"}
                        justify={justify !== undefined ? justify : inViewport1 && inViewport2 ? "center" : "flex-start"}
                        style={{textAlign: inViewport1 && inViewport2 ? "center" : "left"}}>

                    <div ref={ref1} className={classes.corner}/>

                    {children}

                    {/*<Button onClick={() => viewportRef.current?.scrollTo({ left: 0, behavior: 'smooth' })}>SCROLL LEFT</Button>*/}

                    <div ref={ref2} className={classes.corner}/>

                </Group>

                {scrollPosition.x >= (innerRef.current?.getBoundingClientRect().width - viewportRef.current?.getBoundingClientRect().width) - 10 ? null :
                    <Box className={classes.navigation} style={{right: 0, marginRight: "calc(var(--mantine-spacing-lg) * -1)"}}
                         // onClick={() => scrollTo("end")}
                         visibleFrom={"sm"}
                        >
                        <FontAwesomeIcon icon={faEllipsis} />
                    </Box>
                }
            </ScrollArea>

        </Box>
    )
}

/**
 * Tab
 *
 * @param props
 * @returns {Element}
 */
const Tab = (props) => {

    const {children,
        onClick,
        selected,
        color = "secondary",
        classNames= {
            root: classes.scrolltabtabroot
        }
    } = props;

    useEffect(() => {
    }, []);

    return (
        <Button {...props} color={color} radius={0} data={selected ? "selected" : ""}
                classNames={classNames}
                onClick={(event) => {
                    onClick(event);
                }}>
            {children}
        </Button>
    )
}

ScrollTab.Tab = Tab;