import React, {memo, useCallback, useEffect, useState} from "react";
import {Box, Button, Center, Group, Image, Stack, Text, Tooltip, Transition} from "@mantine/core";
import {
	getBezierPath,
	Handle, NodeResizeControl,
	NodeToolbar,
	Position,
	useInternalNode,
	useOnSelectionChange, useReactFlow, useStore,
} from "@xyflow/react";

import classes from "./Studio.module.css"
import useWiki from "../useWiki";
import {
	faImage,
	faObjectUngroup,
	faTrash,
	faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	getColorByThreshold, getConnectedEdges,
	getNodesByParentId,
	MOLECULES_THRESHOLDS, PAIRINGS_THRESHOLDS,
	ungroupNode
} from "./StudioUtils";
import {entityNavigate} from "../ingredient/IngredientLink";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useStudioStore} from "./StudioPage";
import {adjustRgbBrightness, hexToRgb, interpolateColor, toRgb} from "../../util/color";
import {theme} from "../../Theme";

/**
 * useGlow
 */
const useGlow = (nodes, thresholds = PAIRINGS_THRESHOLDS) => {

	// Get the edges from the global React Flow state using useStore
	const edges = useStore((state) => state.edges);

	let studioHeatmapEnabled = useStudioStore((state) => state.studioHeatmapEnabled);
	const [connectedEdgesPercentage, setConnectedEdgesPercentage] = useState(0);

	/**
	 * Calculates the glow color based on thresholds and percentage.
	 * @param {number} percentage - The percentage value to evaluate.
	 * @returns {string} - The glow color in RGB format.
	 */
	function getGlowColor(percentage) {

		const clampedPercentage = Math.min(100, Math.max(0, percentage));

		// Find the two closest thresholds to interpolate between
		for (let i = 0; i < thresholds.length - 1; i++) {
			const current = thresholds[i];
			const next = thresholds[i + 1];

			if (clampedPercentage >= next.limit) {
				const range = current.limit - next.limit;
				const factor = (clampedPercentage - next.limit) / range;
				return toRgb(interpolateColor(next.color, current.color, factor));
			}
		}

		// Fallback to the lowest color if no thresholds match
		return thresholds[thresholds.length - 1].color;
	}

	useEffect(() => {

		// Calculate the percentage of connected edges relative to all edges
		setConnectedEdgesPercentage(getConnectedEdges(edges, nodes)?.length / edges?.length * 100 || 0);

	}, [studioHeatmapEnabled, nodes, edges]);

	return {
		enabled: studioHeatmapEnabled && edges.length > 0 && connectedEdgesPercentage > 0,
		pc: connectedEdgesPercentage,
		color: () => getGlowColor(connectedEdgesPercentage)
	};
};

/**
 * DynamicGlow
 */
const DynamicGlow = ({
						node,
						percentage,
						color,
						opacity = 0.9,
						scaleFactor = 40,
						transition = "0.5s",
						blurRadius = "160px",
						borderRadius = "50%",
						minSpread = 80,
						maxSpread = 160,
					}) => {

	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const { getViewport } = useReactFlow();
	const viewport = getViewport();

	// Mouse position relative to viewport
	useEffect(() => {
		const handleMouseMove = (e) => {
			setMousePosition({
				x: (e.clientX - viewport.x) / viewport.zoom,
				y: (e.clientY - viewport.y) / viewport.zoom,
			});
		};
		window.addEventListener("mousemove", handleMouseMove);
		return () => window.removeEventListener("mousemove", handleMouseMove);
	}, [viewport]);

	/**
	 * Calculate dynamic spread based on percentage
	 */
	function getSpreadRadius(percentage) {

		// Do not show to high density
		if(percentage === 100) {
			return maxSpread * 80 / 100;
		}

		// Map the percentage to a spread value between min and max
		return minSpread + ((percentage / 100) * (maxSpread - minSpread));
	}

	// Calculate shadow offset dynamically
	function getShadowOffset(node) {
		if (!node) return { x: 0, y: 0 };

		const nodeCenter = {
			x: node.positionAbsoluteX + node.width / 2,
			y: node.positionAbsoluteY + node.height / 2,
		};

		const dx = mousePosition.x - nodeCenter.x;
		const dy = mousePosition.y - nodeCenter.y;

		return {
			x: -dx / scaleFactor,
			y: -dy / scaleFactor,
		};
	}

	const shadowOffset = getShadowOffset(node); // Assuming nodes[0] is the target node

	return (
		<div style={{ position: "absolute", width: "100%", height: "100%" }}>
			<div
				style={{
					position: "absolute",
					top: `50%`,
					left: `50%`,
					width: `0%`,
					height: `0%`,
					borderRadius: borderRadius,
					boxShadow: `0px 0px ${blurRadius} ${getSpreadRadius(percentage)}px ${color}`,
					transition: `transform ${transition} ease-out`,
					transform: `translate(${shadowOffset.x}px, ${shadowOffset.y}px)`,
					pointerEvents: 'none',
					opacity: opacity,
					zIndex: -1,
				}}
			/>
		</div>
	);
};

/**
 * StaticGlow
 */
const StaticGlow = ({
				  percentage,
				  color,
				  opacity = 0.7,
				  blurRadius = "200px",
				  borderRadius = "8px",
				  minSpread = 0,
				  maxSpread = 10,
				  offset = 2
			  }) => {

	/**
	 * Calculate dynamic spread based on percentage
	 */
	function getSpreadRadius(percentage) {
		// Map the percentage to a spread value between min and max
		return minSpread + ((percentage / 100) * (maxSpread - minSpread));
	}

	return (
		<div style={{ position: "absolute", width: "100%", height: "100%" }}>
			<div
				style={{
					position: "absolute",
					top: `-${offset}px`,
					left: `-${offset}px`,
					width: `calc(100% + ${offset * 2}px)`,
					height: `calc(100% + ${offset * 2}px)`,
					borderRadius: borderRadius,
					boxShadow: `0px 0px ${blurRadius} ${getSpreadRadius(percentage)}px ${color}`,
					pointerEvents: 'none',
					opacity: opacity,
					zIndex: -1,
				}}
			/>
		</div>
	);
};

/**
 * useSelectionColor
 */
const useSelectionColor = (	nodeId,
							nodeSelected,
							glowColor,
						 	adjustBrightnessPc = -20,
							defaultSelectionColor = "var(--mantine-color-primary-6)",
							defaultUnselectionColor = "var(--mantine-color-primary-2)",
	) => {

	const [selectionColor, setSelectionColor] = useState(defaultSelectionColor);
	const [unselectionColor, setUnselectionColor] = useState(defaultUnselectionColor);

	useEffect(() => {

		const element = document.querySelector(`.react-flow__node[data-id="${nodeId}"]`);

		if (element) {

			const selectionColor = glowColor !== undefined ? glowColor : defaultSelectionColor;
			const unselectionColor = glowColor !== undefined ? toRgb(adjustRgbBrightness(glowColor, adjustBrightnessPc)) : defaultUnselectionColor;

			// Force inline border color
			element.style.borderColor = nodeSelected ? selectionColor : unselectionColor;

			// Mouse / focus handling
			const handleMouseEnter = () => (element.style.borderColor = selectionColor);
			const handleMouseLeave = () => (element.style.borderColor = nodeSelected ? selectionColor : unselectionColor);

			element.addEventListener("mouseenter", handleMouseEnter);
			element.addEventListener("mouseleave", handleMouseLeave);
			element.addEventListener("focus", handleMouseEnter);
			element.addEventListener("blur", handleMouseLeave);

			setSelectionColor(selectionColor);
			setUnselectionColor(unselectionColor);

			// return () => {
			// 	// Cleanup event listeners
			// 	element.removeEventListener("mouseenter", handleMouseEnter);
			// 	element.removeEventListener("mouseleave", handleMouseLeave);
			// 	element.removeEventListener("focus", handleMouseEnter);
			// 	element.removeEventListener("blur", handleMouseLeave);
			// };
		}
	}, [nodeId, nodeSelected, glowColor, adjustBrightnessPc, defaultSelectionColor, defaultUnselectionColor]);

	return {
		selectionColor,
		unselectionColor
	}
}

/**
 * ImageWithZoom
 */
const ImageWithZoom = ({node, selectionColor, unselectionColor}) => {

	const [hovered, setHovered] = useState(false);

	const {setNodes, getNode} = useReactFlow();

	const {
		data: dataEntityWiki, isLoaded: isLoadedEntityWiki,
		reset: resetEntityWiki, refetch: refetchEntityWiki} =
		useWiki({
			enabled: true,
			url: node.data.entity.wikiLink,
			onSuccess: (wiki) => {

				setNodes((prevNodes) =>
					prevNodes.map((n) => {
						// Update only the current group node
						if (n.id === node.id) {
							return {
								...n,
								data: {
									...n.data,
									wiki: wiki
								}
							};
						}
						return n; // Leave other nodes unchanged
					})
				);
			}
		})

	return !dataEntityWiki || !dataEntityWiki.image ?
		<Box className={`${classes.typesimagewithzoomroot} ${classes.typesimagewithzoomnoimage}`}>
			<FontAwesomeIcon icon={faImage} size={"3x"} color={node.selected ? selectionColor : unselectionColor}/>
		</Box>
		:
		<Box className={classes.typesimagewithzoomroot}
			 onMouseEnter={() => setHovered(true)}
			 onMouseLeave={() => setHovered(false)}>
			<Transition
				mounted={true}
				transition="scale"
				timingFunction="ease-in-out">
				{(styles) => (
					<Image
						src={dataEntityWiki.image}
						className={classes.typesimagewithzoomimage}
						style={{
							transform: hovered? 'scale(1.1)' : 'scale(1)',  // Scale on hover
							transition: 'transform 0.15s ease-in-out',  // Smooth transition
						}}
					/>
				)}
			</Transition>

			{/*<Container className={classes.imagewithzoomoverlay}>*/}
			{/*	<Overlay color="var(--mantine-color-primary-9)" backgroundOpacity={!hovered ? 0 : 0.12} blur={0} zIndex={1}/>*/}
			{/*	/!*<Stack h={"100%"} pt={"100%"} align="flex-end" justify="center">*!/*/}
			{/*	/!*	*!/*/}
			{/*	/!*</Stack>*!/*/}
			{/*	<Stack gap={0} justify="flex-end" className={classes.imagewithzoomtitle}>*/}
			{/*		<Text size={"sm"}>{node.data.label}</Text>*/}
			{/*/!*		<Group align="flex-start" gap={6} wrap={"nowrap"} >*!/*/}

			{/*/!*			<Stack gap={0}>*!/*/}
			{/*/!*				<Text c={"white"} lineClamp={1} pb={4}>*!/*/}
			{/*/!*					{localized(recipe, 'name')}*!/*/}
			{/*/!*				</Text>*!/*/}
			{/*/!*				{showCategory ?*!/*/}
			{/*/!*					<Text c={"white"} size={'xs'} opacity={0.75}>*!/*/}
			{/*/!*						{sortedCategories(recipe.categories)*!/*/}
			{/*/!*							.map((category, index) => localized(category, 'name'))*!/*/}
			{/*/!*							.join(" / ")}*!/*/}
			{/*/!*					</Text>*!/*/}
			{/*/!*					:*!/*/}
			{/*/!*					// <Text c={"white"} size={'xs'} opacity={0.75}>*!/*/}
			{/*/!*					//     &nbsp;*!/*/}
			{/*/!*					// </Text>*!/*/}
			{/*/!*					null*!/*/}
			{/*/!*				}*!/*/}
			{/*/!*			</Stack>*!/*/}
			{/*/!*		</Group>*!/*/}
			{/*/!*		<FontAwesomeIcon icon={faChevronRight} color={"white"} />*!/*/}
			{/*	</Stack>*/}
			{/*</Container>*/}
		</Box>
};

/**
 * NodeToolbarDefault
 */
const NodeToolbarDefault = ({ node }) => {

	const { setNodes, getNode } = useReactFlow();
	const [selectedNodes, setSelectedNodes] = useState([]); // State to keep track of selected nodes

	const navigate = useNavigate();
	const { t } = useTranslation();

	// This callback updates the selected nodes' IDs whenever a selection change occurs
	const onChange = useCallback(({ nodes }) => {
		setSelectedNodes(nodes.map((node) => node.id)); // Map selected nodes to their IDs
	}, []);

	// React Flow hook to listen for selection changes
	useOnSelectionChange({
		onChange, // Pass the memoized onChange handler
	});

	/**
	 * handleUngroup
	 * This function removes the `parentId` and `extent` properties from the node,
	 * effectively ungrouping it, and recalculates its absolute position to ensure it remains in the same spot on the canvas.
	 */
	const handleUngroup = () => {
		setNodes((prevNodes) =>

			prevNodes.map((n) => {

				if (n.id === node.id) {
					// Calculate the node's absolute position
					let absolutePosition = n.position;

					// If the node is inside a group, calculate its absolute position relative to the group's position
					if (n.parentId) {
						const groupNode = getNode(n.parentId); // Retrieve the parent group node using its ID
						if (groupNode) {
							// Add the group's position to the node's position to calculate the absolute position
							absolutePosition = {
								x: groupNode.position.x + n.position.x,
								y: groupNode.position.y + n.position.y,
							};
						}
					}

					// Return a new node object with `parentId` and `extent` removed, and the absolute position updated
					return ungroupNode(n, absolutePosition);
				}

				// Return unchanged nodes
				return n;
			})
		);
	};

	/**
	 * handleRemoveNode
	 * Removes the node entirely from the React Flow canvas.
	 */
	const handleRemoveNode = () => {
		setNodes((prevNodes) => prevNodes.filter((n) => n.id !== node.id)); // Filter out the current node by ID
	};

	// Render the Node Toolbar. It is only visible when exactly one node is selected and it is the current node
	return (
		<NodeToolbar isVisible={selectedNodes.length === 1 && node.selected} position={"top"}>
			<Button.Group pb={2}>
				{/* Render the ungroup button only if the node has a parentId (i.e., it is part of a group) */}
				{node.parentId && (
					<Tooltip label={t("studio.ungroup")}>
						<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} onClick={handleUngroup}>
							<FontAwesomeIcon icon={faObjectUngroup}/>
						</Button>
					</Tooltip>
				)}
				<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} onClick={() => entityNavigate(navigate, node.id, "_blank")}>
					<FontAwesomeIcon icon={faUpRightFromSquare}/>
				</Button>
				<Tooltip label={t("studio.delete")}>
					<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} onClick={handleRemoveNode}>
						<FontAwesomeIcon icon={faTrash}/>
					</Button>
				</Tooltip>
			</Button.Group>
		</NodeToolbar>
	);
};

/**
 * GroupNode
 */
export const GroupNode = memo((node) => {

	const { setNodes, getNodes } = useReactFlow();

	const {enabled: glowEnabled, color: glowColor, pc: glowPc } = useGlow([node]);

	const [selectedNodes, setSelectedNodes] = useState([]); // State to keep track of selected nodes
	const [hasChildren, setHasChildren] = useState(false); // State to track if the group has child nodes
	const [temporarySelection, setTemporarySelection] = useState(false);
	const [resizing, setResizing] = useState(false);

	/**
	 * isSelected
	 */
	const isSelected = () => temporarySelection || resizing || node.selected;

	const {selectionColor, unselectionColor} = useSelectionColor(node.id, isSelected(), glowEnabled ? glowColor() : undefined);

	const { t } = useTranslation();

	// This callback updates the selected nodes' IDs whenever a selection change occurs
	const onChange = useCallback(({ nodes }) => {
		const childNodes = getNodesByParentId(getNodes(), node.id);
		setHasChildren(childNodes.length > 0); // Update state to reflect if the group has children
		setSelectedNodes([nodes.map((node) => node.id)]); // Map selected nodes to their IDs
	}, []);

	// React Flow hook to listen for selection changes
	useOnSelectionChange({
		onChange, // Pass the memoized onChange handler
	});

	/**
	 * handleUngroup
	 * This function removes the `parentId` and `extent` properties from all child nodes of the group,
	 * effectively ungrouping them.
	 */
	const handleUngroup = () => {
		setNodes((prevNodes) => {

			const updatedNodes = prevNodes.map((n) => {

				// Check if the node is a child of the current group
				if (n.parentId === node.id) {
					const position = {
						x: n.position.x + (node.positionAbsoluteX || 0), // Adjust x position to keep its position on the canvas
						y: n.position.y + (node.positionAbsoluteY || 0), // Adjust y position to keep its position on the canvas
					};

					// Remove parentId and extent to ungroup the node
					return ungroupNode(n, position);
				}
				return n; // Return the node as is if it's not a child of the group
			});

			// After ungrouping, update `hasChildren` to false if there are no child nodes left
			setHasChildren(false);

			return updatedNodes;
		});
	};

	/**
	 * handleRemoveNode
	 * Removes the group node and all child nodes that reference it as a parentId.
	 */
	const handleRemoveNode = () => {

		setNodes((prevNodes) => {

			// Get all child nodes that have the current group node as their parentId
			const childNodes = prevNodes.filter((n) => n.parentId === node.id);

			// Remove the group node and all child nodes
			return prevNodes.filter((n) => n.id !== node.id && !childNodes.includes(n));
		});
	};

	/**
	 * changeWeighting
	 * Updates the `data.weighting` of the group node dynamically.
	 * @param {number} newWeighting - The new weighting value (between 0 and 1).
	 */
	const changeWeighting = (newWeighting) => () => {

		setTemporarySelection(true);

		setNodes((prevNodes) =>
			prevNodes.map((n) => {
				// Update only the current group node
				if (n.id === node.id) {
					return {
						...n,
						data: {
							...n.data,
							weighting: newWeighting, // Update weighting value
						},
						selected: false
					};
				}
				return n; // Leave other nodes unchanged
			})
		);

		// Simulate a re-selection with a slight delay to ensure React Flow processes the change
		setTimeout(() => {
			setNodes((prevNodes) =>
				prevNodes.map((n) => {
					if (n.id === node.id) {
						return {
							...n,
							selected: true, // Re-select the node
						};
					}
					return n;
				})
			);

			setTemporarySelection(false)
		}, 0); // Adjust the delay if necessary
	};

	return (
			<Box className={classes.nodebase} style={{
				outline: isSelected() ? `2px solid ${selectionColor}` : "none",
				borderRadius: "6px",
				backgroundColor: glowEnabled ? toRgb(adjustRgbBrightness(glowColor(), 85)) : toRgb(adjustRgbBrightness(toRgb(hexToRgb(theme.colors.primary[6])), 95))
			}}>

			{glowEnabled &&
				<StaticGlow percentage={glowPc} color={glowColor()}/>
			}

			<Group justify={"center"} p={"xs"}>
				<Text lh={1.3} c={isSelected() ? selectionColor : glowEnabled ? unselectionColor : "var(--mantine-color-primary-9)"}>{node.data.label}</Text>
			</Group>

			<NodeToolbar isVisible={isSelected() && selectedNodes.length === 1} position={"top"}>
				<Button.Group pb={2}>
					{hasChildren &&
						<Tooltip label={t("studio.ungroup")}>
							<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} onClick={handleUngroup}>
								<FontAwesomeIcon icon={faObjectUngroup}/>
							</Button>
						</Tooltip>
					}
					<Tooltip label={t("studio.delete")}>
						<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} onClick={handleRemoveNode}>
							<FontAwesomeIcon icon={faTrash}/>
						</Button>
					</Tooltip>
					<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} disabled={node.data.weighting === 0} onClick={changeWeighting(0)}>
						0
					</Button>
					<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} disabled={node.data.weighting === 0.5} onClick={changeWeighting(0.5)}>
						0.5
					</Button>
					<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} disabled={node.data.weighting === 1} onClick={changeWeighting(1)}>
						1
					</Button>
				</Button.Group>
			</NodeToolbar>

			<NodeResizeControl position={"bottom-right"}
							   onResizeStart={() => setResizing(true)}
							   onResizeEnd={() => setResizing(false)}/>

			<Handle
				type="target"
				position={Position.Top}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>

			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>
		</Box>
	);
});

/**
 * InputNode
 */
export const InputNode = memo((node) => {

	const {enabled: glowEnabled, color: glowColor, pc: glowPc } = useGlow([node]);

	const {selectionColor, unselectionColor} = useSelectionColor(node.id, node.selected, glowEnabled ? glowColor() : undefined);

	return  (
		<Box className={classes.nodebase}>

			{glowEnabled &&
				<DynamicGlow node={node} percentage={glowPc} color={glowColor()}/>
			}

			<ImageWithZoom node={node} selectionColor={selectionColor} unselectionColor={unselectionColor}/>

			<Center>
				<Stack gap={0} justify="flex-end" className={classes.inputtitle}
					   style={{
						   color: node.selected ? "var(--mantine-color-white)" : glowEnabled ? unselectionColor : "var(--mantine-color-primary-9)",
						   backgroundColor: node.selected ? selectionColor : "var(--mantine-custom-color-body-light-hover)"
				}}>
					<Text lh={1.3} >{node.data.label}</Text>
					<Text size={"xs"} lh={1.3} opacity={0.75}>{node.data.entity.categoryLabel}</Text>
					{/*<Text size={"xs"} lh={1.3} opacity={0.75}>{JSON.stringify(node.data.wiki)}</Text>*/}
				</Stack>
			</Center>

			<NodeToolbarDefault node={node}/>

			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>
		</Box>
	);
});

/**
 * IntermediateNode
 */
export const IntermediateNode = memo((node) => {

	const {enabled: glowEnabled, color: glowColor, pc: glowPc } = useGlow([node]);

	const {selectionColor, unselectionColor} = useSelectionColor(node.id, node.selected, glowEnabled ? glowColor() : undefined);

	return (
			<Box className={classes.nodebase}>

			{glowEnabled &&
				<DynamicGlow node={node} percentage={glowPc} color={glowColor()}/>
			}

			<ImageWithZoom node={node} selectionColor={selectionColor} unselectionColor={unselectionColor}/>

			<Center>
				<Stack gap={0} justify="flex-end" className={classes.intermediatetitle}
					   style={{
						   color: node.selected ? "var(--mantine-color-white)" : glowEnabled ? unselectionColor : "var(--mantine-color-primary-9)",
						   backgroundColor: node.selected ? selectionColor : "var(--mantine-custom-color-body-light-hover)"
				}}>
					<Text lh={1.3}>{node.data.label}</Text>
					<Text size={"xs"} lh={1.3} opacity={0.75}>{node.data.entity.categoryLabel}</Text>
					{/*<Text size={"xs"} lh={1.3} opacity={0.75}>{JSON.stringify(node.data.wiki)}</Text>*/}
				</Stack>
			</Center>

			<NodeToolbarDefault node={node}/>

			<Handle
				type="target"
				position={Position.Top}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>

			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>
		</Box>
	);
});

/**
 * DefaultEdge
 */
export const DefaultEdge = memo(({ id, source, target, style = {}, data, markerEnd, selected }) => {

	let studioHeatmapEnabled = useStudioStore((state) => state.studioHeatmapEnabled);

	// Use `useInternalNode` to get details of the `source` and `target` nodes
	const sourceNode = useInternalNode(source);
	const targetNode = useInternalNode(target);

	useEffect(() => {

		const element = document.querySelector(`.react-flow__edge[data-id="${id}"]`);

		// This class informs parent SVG about the source node, if group then adapt the SVG container z-index
		if(element && sourceNode.type === "group") {
			element.classList.add("is-group");
		}
		else {
			element.classList.remove("is-group");
		}

	}, [selected]);

	// If one of the nodes is undefined (e.g., during initial rendering), return null
	if (!sourceNode || !targetNode) {
		return null;
	}

	// Calculate the center positions of the source and target nodes
	const sourceCenterX = sourceNode.internals.positionAbsolute.x + sourceNode.measured.width / 2;
	const sourceCenterY = sourceNode.internals.positionAbsolute.y + sourceNode.measured.height / 2;

	const targetCenterX = targetNode.internals.positionAbsolute.x + targetNode.measured.width / 2;
	const targetCenterY = targetNode.internals.positionAbsolute.y + targetNode.measured.height / 2;

	// Determine the positions (e.g., Top, Bottom, Left, Right) relative to the node positions
	const sourcePosition = sourceCenterX <= targetCenterX ? Position.Right : Position.Left; // If target is to the right, source is "Right"
	const targetPosition = sourceCenterX <= targetCenterX ? Position.Left : Position.Right; // If target is to the right, target is "Left"

	// Build the Bezier curve path
	const [edgePath] = getBezierPath({
		sourceX: sourceCenterX,
		sourceY: sourceCenterY,
		sourcePosition,
		targetX: targetCenterX,
		targetY: targetCenterY,
		targetPosition,
	});

	// Modify the style dynamically based on the `selected` state
	const strokeWidth = data?.width > 3 ? data?.width : 3;

	const edgeStyle = {
		stroke: selected ?
			!studioHeatmapEnabled ? "var(--mantine-color-primary-light-hover)" : getColorByThreshold(data.sharedMoleculesPc, MOLECULES_THRESHOLDS, "light-hover") :
			!studioHeatmapEnabled ? "var(--mantine-color-primary-outline-hover)" : getColorByThreshold(data.sharedMoleculesPc, MOLECULES_THRESHOLDS, "outline-hover"), // Change color if selected
		strokeWidth: strokeWidth,
		...style
	};

	// Dynamically calculate the label dimensions
	const fontSize = 14; // Font size for the text
	const padding = 6; // Padding around the text
	const lineHeight = fontSize * 1.2; // Approximate line height
	const textLines = data?.label ? data.label.split("\n") : []; // Split label into multiple lines if it contains `\n`
	const textWidth = Math.max(...textLines.map((line) => line.length * (fontSize * 0.6))); // Calculate the width based on the longest line
	const textHeight = textLines.length * lineHeight + padding * 2; // Calculate the height dynamically based on the number of lines

	// Render the curve and the optional label
	return (
		<>
			{/* Render the dynamic path */}
			<path
				id={id}
				className="react-flow__edge-path"
				d={edgePath}
				style={edgeStyle} // Apply dynamic styles
				markerEnd={markerEnd} // Marker at the end of the curve
			/>
			{/* Optional label positioned at the center of the edge */}
			{data?.label && (
				<>
					{/* Add background for the label */}
					<rect
						x={(sourceCenterX + targetCenterX) / 2 - textWidth / 2} // Position slightly left to center the label
						y={(sourceCenterY + targetCenterY) / 2 - textHeight / 2} // Center the label vertically
						rx={4} // Border radius for the label background
						ry={4} // Border radius for the label background
						width={textWidth} // Dynamic width based on text length
						height={textHeight} // Dynamic height based on number of lines
						fill={selected ? !studioHeatmapEnabled ? "var(--mantine-color-primary-6)" : getColorByThreshold(data.sharedMoleculesPc, MOLECULES_THRESHOLDS, "6") : "var(--mantine-custom-color-body-light-hover)"} // Background color for the label
						opacity={selected ? 1 : 1} // Slight transparency for the background
					/>
					{/* Render the text on top of the background */}
					{textLines.map((line, index) => (
						<text
							key={index}
							x={(sourceCenterX + targetCenterX) / 2} // Center the text horizontally
							y={(sourceCenterY + targetCenterY) / 2 - textHeight / 2 + padding + lineHeight * (index + 0.8)} // Position each line dynamically
							textAnchor="middle"
							style={{
								fontSize: `${fontSize}px`,
								fill: selected ? "var(--mantine-color-white)" : !studioHeatmapEnabled ? "var(--mantine-color-primary-9)" : getColorByThreshold(data.sharedMoleculesPc, MOLECULES_THRESHOLDS),
								pointerEvents: "none",
							}}
						>
							{line}
						</text>
					))}
				</>
			)}
		</>
	);
});

