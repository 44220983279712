import React, {useState} from 'react';
import {
	Box,
	Breadcrumbs, Card, Center,
	Container, Grid,
	Image, Overlay,
	Paper, rgba,
	Space,
	Stack,
	Title, Transition,
	useMantineTheme
} from "@mantine/core";
import classes from './Page.module.css';
import {ReactComponent as TopPage} from "./TopPage.svg";
import {ReactComponent as BottomLeftPage} from "./BottomLeftPage.svg";
import {ReactComponent as BottomRightPage} from "./BottomRightPage.svg";
import {ReactComponent as PageTitleSvg} from "./PageTitle.svg";
import {ReactComponent as PageCardBackgroundSvg} from "./PageCardBackground.svg";
import {Parallax} from "react-scroll-parallax";
import {useFullscreen} from "@mantine/hooks";
import {faChevronLeft, faHome, faImage} from "@fortawesome/free-solid-svg-icons";
import {homeNavigate} from "../../pages/home/HomeLink";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CustomCarousel} from "../carousel/CustomCarousel";
import useMedia from "../../pages/useMedia";
import {Meta} from "../meta/Meta";

/**
 * BasePage
 */
const BasePage = (props) => {

    const {pageTitle, pageDescription, pageKeywords, pageUrl, pageImage, pageSchema, className, children} = props;

    return (

        <Paper {...props}
               className={`${classes.paper} ${className}`}
               shadow={"sm"}
               radius={0}>

			<Meta title={pageTitle} description={pageDescription} keywords={pageKeywords} url={pageUrl} image={pageImage} schema={pageSchema}/>

			{children}
		</Paper>
	)
}

/**
 * Page
 */
export const Page = (props) => {

	const {
		pageTitle,
		pageDescription,
		pageKeywords,
		pageUrl,
		pageImage,
		pageSchema,
		pageTitles,
		carouselColor = "secondary", hasTabs = false, svgZIndex = 0, children
	} = props;

	return (
		<BasePage pageTitle={pageTitle}
				  pageDescription={pageDescription}
				  pageKeywords={pageKeywords}
				  pageUrl={pageUrl}
				  pageImage={pageImage}
				  pageSchema={pageSchema}
				  className={classes.pagetitle}>

			<Box className={classes.pagetitlebackground}>

				<Box className={classes.pagetitlebox}>

					<Box visibleFrom={"sm"}>
						<PageTitleSvg className={classes.pagetitlesvg} style={{zIndex: svgZIndex}}/>
					</Box>

					<CustomCarousel color={carouselColor} indicatorsClassName={hasTabs ? classes.customcarouselindicators : ""}>
						{pageTitles}
					</CustomCarousel>
				</Box>
			</Box>

			{children}

		</BasePage>
    )
}

/**
 * PageTitle
 */
export const PageTitle = (props) => {

	const {
		displayTitle,
		simpleText,
		titleIcon,
		breadcrumb,
		pt,
		backgroundImageSrc,
		backgroundImageBlur = 7,
		withDescription = false, descriptionComponent,
		withSubTitle = false, subTitleComponent,
		withCard = false, cardSection, cardComponent,
		withSub = false, subComponent,
		hasNavigationButton = true,
		hasHomeButton = true,
		hasTabs = false} = props;

	const theme = useMantineTheme();

	const { isSm } = useMedia();

	return (
		<CustomCarousel.Slide pb={hasTabs ? 38 : 0} style={{overflow: "hidden"}}>

			{!backgroundImageSrc ? null :
				<Parallax translateY={["0px", (simpleText || withCard) ? !isSm ? "-50px" : "-25px" : "0px"]} className={classes.pagetitlebackgroundimageroot}>
					<Overlay color="var(--mantine-color-primary-6)"
							 gradient={`linear-gradient(315deg, ${rgba("var(--mantine-color-primary-7)", 0)} 25%, ${rgba("var(--mantine-color-primary-9)", 0.85)} 50%)`}
						// backgroundOpacity={0.3}
							 blur={backgroundImageBlur}
							 className={classes.pagetitleoverlay}/>
					<Image src={backgroundImageSrc} className={classes.pagetitlebackgroundimage}/>
				</Parallax>
			}

			<Container pt={!withCard && !isSm ? "calc(var(--mantine-custom-components-header-height) + (var(--mantine-spacing-xl) * 4))" : "var(--mantine-custom-components-header-height)"}
					   pb={!withCard && !isSm ? "calc(var(--mantine-spacing-xl) * 1)" : "0"}
			>

				{!pt || isSm ? null :
					<Space h={pt}/>
				}

				<Parallax translateY={["0px", (simpleText || withCard) ? !isSm ? "-100px" : "-50px" : "0px"]}>

					<Grid gutter={"lg"} grow align="stretch"
						  mt={withCard && !isSm ? "calc(var(--mantine-spacing-xl) * 4)" : `calc(var(--mantine-spacing-xl) * ${!isSm ? "1" : "2"})`}
						  mb={!withCard || isSm ? "calc(var(--mantine-spacing-xl) * 1)" : "0"}>

						<Grid.Col span={withCard ? 6 : 12}>

							{simpleText ?
								<Stack align="stretch"
									   justify="center"
									   className={classes.pagetitlesimpletextroot}>
									<Title order={1} className={classes.pagetitlesimpletexttext}>{simpleText}</Title>
								</Stack>
								:
								<Stack align="stretch" justify={withSubTitle ? "space-between" : "center"} gap={!isSm ? "0" : "0"}
									   className={classes.pagetitletitle}>

									{!withCard || isSm ? null :
										<Box/>
									}

									<Box>

										{!titleIcon ? null :
											<Box visibleFrom={"sm"} className={classes.pagetitletitleicon}>
												{titleIcon}
											</Box>
										}

										{!displayTitle ? null :
											<Title order={1} className={classes.pagetitletext}>{displayTitle}</Title>
										}

										{(!breadcrumb || breadcrumb.length === 0) && !hasNavigationButton && !hasHomeButton ? null :
											<Breadcrumbs classNames={{
												root: classes.pagetitlebreadcrumbroot,
												separator: classes.pagetitlebreadcrumbseparator
											}}>
												{!hasNavigationButton ? null :
													<NavigationPageTitleBreadcrumbs/>
												}
												{!hasHomeButton ? null :
													<HomePageTitleBreadcrumb/>
												}

												{breadcrumb}
											</Breadcrumbs>
										}

										{!withDescription ? null :
											<Box className={classes.pagetitledescription}>
												{descriptionComponent}
											</Box>
										}
									</Box>

									{!withSubTitle ? null :
										subTitleComponent
									}

								</Stack>
							}

						</Grid.Col>

						{!withCard ? null :
							<Grid.Col span={withCard ? 6 : 12}>
								{!cardSection ?
									<>
										{cardComponent}
									</>
									:
									<PageCard>
										<PageCard.Section>
											{cardSection}
										</PageCard.Section>
										{cardComponent}
									</PageCard>
								}
							</Grid.Col>
						}

						{!withSub ? null :
							<Grid.Col span={12} pb={0}>
								{subComponent}
							</Grid.Col>
						}
					</Grid>
				</Parallax>

			</Container>
		</CustomCarousel.Slide>
	);
}

/**
 * NavigationPageTitleBreadcrumbs
 *
 * @returns {Element}
 * @constructor
 */
const NavigationPageTitleBreadcrumbs = () => {

    return (
        <PageTitleBreadcrumb type={"a"} onClick={() => window.history.back()}>
            <FontAwesomeIcon icon={faChevronLeft} fontSize={"sm"}/>
        </PageTitleBreadcrumb>
    );
};

/**
 * HomePageTitleBreadcrumb
 *
 * @returns {Element}
 * @constructor
 */
const HomePageTitleBreadcrumb = () => {

    const navigate = useNavigate();

    return (
        <PageTitleBreadcrumb type={"a"} onClick={() => homeNavigate(navigate)}>
            {/*<Text size={"sm"}>{t("common.home")}</Text>*/}
            <FontAwesomeIcon icon={faHome} fontSize={"sm"}/>
        </PageTitleBreadcrumb>
    );
};

/**
 * PageTitleBreadcrumb
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const PageTitleBreadcrumb = (props) => {

    const {children, type} = props;

    /**
     * @returns {undefined|*}
     */
    function getClass() {

        switch (type) {
            case "a":
                return classes.pagetitlebreadcrumblink;
            default:
                return classes.pagetitlebreadcrumbtext;
        }
    }


    return (
        <Box {...props} className={getClass()}>
            {children}
        </Box>
    )
}

PageTitle.Breadcrumb = PageTitleBreadcrumb;

/**
 * PageCard
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const PageCard = (props) => {

    const {children} = props;

    return (
        <div style={{position: "relative"}}>
            <Box visibleFrom={"sm"}>
                <PageCardBackgroundSvg className={classes.pagecardbackgroundsvg}/>
            </Box>
            <Card className={classes.pagecard} shadow={"none"} >
                {children}
            </Card>
        </div>
    )
}

/**
* PageCardSection
*
* @param props
* @returns {
    Element
}
* @constructor
*/
const PageCardSection = (props) => {

    const {children} = props;

    return (
        <Card.Section {...props} className={classes.pagecardsection}>
            {children}
        </Card.Section>
    )
}

PageCard.Section = PageCardSection;

/**
 * Image169WithZoom
 */
export const Image169WithZoom = ({src, withZoom = true, withFullscreen = true, withPointer = false, leftIcon, rightIcon}) => {

	const { ref, toggle, fullscreen } = useFullscreen();
    const [hovered, setHovered] = useState(false);

    return (
		<Box
			className={classes.pagetitleimage169root}
			onClick={() => {
				if(withFullscreen) {
					toggle();
				}
			}}
			style={{cursor: withPointer ? "pointer" : "auto"}}
			onMouseEnter={() => setHovered(withZoom)}
			onMouseLeave={() => setHovered(false)}>

			{!src ?
				<Center className={classes.pagetitleimage169noimage}>
					<FontAwesomeIcon icon={faImage} size={"8x"}/>
				</Center>
				:
				<Transition
					mounted={true}
					transition="scale"
					timingFunction="ease-in-out">
					{(styles) => (
						<>
							<Image
								ref={ref}
								src={src}
								className={classes.pagetitleimage169image}
								style={{
									transform: hovered && withZoom? 'scale(1.1)' : 'scale(1)',  // Scale on hover
									transition: 'transform 0.15s ease-in-out',  // Smooth transition
								}}
								fallbackSrc={"/image-placeholder-tertiary.png"}
							/>
						</>
					)}
				</Transition>
			}
			{!leftIcon ? null :
				<Box className={classes.pagetitleimage169lefticon}>
					{leftIcon}
				</Box>
			}
			{!rightIcon ? null :
				<Box className={classes.pagetitleimage169righticon}>
					{rightIcon}
				</Box>
			}
		</Box>
    );
};