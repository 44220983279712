import { useEffect } from "react";

/**
 * useBodyStyle
 * A hook to temporarily change global styles for body and html elements.
 * It restores the previous styles when the component unmounts.
 *
 * @param {Object} styles - An object containing the styles to apply.
 */
export const useBodyStyle = (styles = {}) => {

	useEffect(() => {
		const body = document.body;
		const html = document.documentElement;

		// Save existing styles
		const previousBodyStyles = {};
		const previousHtmlStyles = {};

		Object.keys(styles).forEach((key) => {
			previousBodyStyles[key] = body.style[key];
			previousHtmlStyles[key] = html.style[key];
		});

		// Apply new styles
		Object.entries(styles).forEach(([key, value]) => {
			body.style[key] = value;
			html.style[key] = value;
		});

		return () => {
			// Restore previous styles
			Object.entries(previousBodyStyles).forEach(([key, value]) => {
				body.style[key] = value;
			});
			Object.entries(previousHtmlStyles).forEach(([key, value]) => {
				html.style[key] = value;
			});

			console.log()
		};
	}, [styles]);
};
