import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import i18n, {localized, parameterLanguage} from "../../i18n";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import useLifecycle from "../useLifecycle";
import {
	addEdge, Background, BackgroundVariant,
	ControlButton,
	Controls, getNodesBounds,
	MiniMap,
	ReactFlow, ReactFlowProvider,
	useEdgesState,
	useNodesState, useReactFlow
} from '@xyflow/react';
import classes from "./Studio.module.css";
import {
	Box, Button, Modal,
	Paper,
	Space,
	Stack,
	Table, Text,
	useMantineTheme
} from "@mantine/core";

import '@xyflow/react/dist/style.css';
import './react-flow-style.css';
import "allotment/dist/style.css";
import './allotment-style.css';
import './style.css';
import {useAccountContext} from "../../components/account/AccountContext";
import {homeNavigate} from "../home/HomeLink";
import {useTranslation} from "react-i18next";
import {Allotment} from "allotment";
import useResult from "../useResult";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {SearchInput} from "../../components/search/Search";
import {FlavorDbCredits} from "../../components/credits/Credits";
import {DnDProvider, useDnDContext} from "./DnDContext";
import {IngredientText} from "../ingredient/IngredientLink";
import {alphabeticComparator} from "../../util/utils";
import {paginationEndSkip, paginationStartSkip} from "../../util/pagination";
import {Paginator} from "../../components/paginator/Paginator";
import useEntityAll from "../ingredient/useEntityAll";
import useMoleculeFull from "../molecule/useMoleculeFull";
import {
	GRID_SIZE,
	entitiesMoleculesAggregation,
	getEntityById,
	searchEntities,
	toChildNode,
	toGroup,
	toNode,
	snapToGrid,
	sortNodesByParent,
	getParentIds,
	findGroupNodeAtPosition, toGroupId, toSelection,
} from "./StudioUtils";
import {DefaultEdge, GroupNode, InputNode, IntermediateNode} from "./Types";
import {useDisclosure} from "@mantine/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faObjectGroup} from "@fortawesome/free-regular-svg-icons";
import {create} from 'zustand';
import {HeaderPanel} from "./HeaderPanel";
import {useBodyStyle} from "../useBodyStyle";
import {Meta} from "../../components/meta/Meta";
import {useViewportHeight} from "../useViewportHeight";
import {ScrollableArea} from "./ScrollableArea";
import {Details} from "./Details";

/**
 * useStudioStore
 */
export const useStudioStore = create((set) => ({

	studioHeatmapEnabled: localStorage.getItem('studioHeatmapEnabled') === null ? false : localStorage.getItem('studioHeatmapEnabled') === 'true',
	setStudioHeatmapEnabled: (studioHeatmapEnabled) => {
		set({ studioHeatmapEnabled });
		localStorage.setItem('studioHeatmapEnabled', studioHeatmapEnabled);
	},
}));

const nodeTypes = {
	input: InputNode,
	intermediate: IntermediateNode,
	group: GroupNode
};

const edgeTypes = {
	default: DefaultEdge,
};

/**
 * AddInputNode
 */
const AddInputNode = ({title, entities, onAddNode}) => {

	const [opened, { close, open }] = useDisclosure(false);

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");
	const [searchItems, setSearchItems] = useState([]);

	const {t} = useTranslation();

	const theme = useMantineTheme();

	useEffect(() => {
		setSearch("");
		setPage(1);
	}, []);

	useEffect(() => {
		try {

			if (!search) {
				setSearchItems(entities);
			}
			else {
				setSearchItems(searchEntities(entities, search));
			}
		}
		catch (ignored) {
			// noop
		}

	}, [search, entities]);

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 * Handle row click to add the node and close the modal
	 */
	const handleRowClick = (entity) => {

		if (onAddNode) {
			onAddNode(entity);
		}

		close();
	};

	return <>
		<Modal opened={opened}
			   onClose={close}
			   closeOnClickOutside
			   withCloseButton={false}
			   size={"xl"}
			// title={<Title order={2}>{t("sponsor.sponsoring")}</Title>}
			   overlayProps={{color: "var(--mantine-color-secondary-12)", backgroundOpacity: 0.75, blur: 7}}
			   zIndex={404}
			   classNames={{
				   root: classes.modalroot,
				   header: classes.modalheader,
				   content: classes.modalcontent,
				   inner: classes.modalinner,
				   body: classes.modalbody,
			   }}>

			<ScrollableArea h={"80%"} p={"md"}>

				<Stack justify="flex-start" gap="0">
					{title ? title : null}
					<StripedTable highlightOnHover>
						<Table.Thead>
							<Table.Tr>
								<Table.Th p={0} pb={"lg"}>
									<SearchInput
										value={search}
										placeholder={t('common.ingredients')}
										onChange={(value) => onSearch(value)}
									/>
								</Table.Th>
							</Table.Tr>
						</Table.Thead>
						<Table.Tbody>
							{searchItems
								.sort((a, b) => alphabeticComparator(localized(a.representativeIngredient, "name"), localized(b.representativeIngredient, "name")))
								.slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
								.map((item, index) => (
									<Table.Tr key={`entity-${index}`} style={{cursor: "pointer"}} onClick={() => handleRowClick(item)}>
										<Table.Td style={{verticalAlign: "middle"}}>
											<IngredientText ingredient={item.representativeIngredient}
															ingredientHighlight={search} showCategory/>
										</Table.Td>
									</Table.Tr>
								))}
						</Table.Tbody>
					</StripedTable>

					<Space h={"lg"}/>
					<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
							   selectedCount={searchItems.length} totalCount={entities.length}/>

					<FlavorDbCredits pl={"md"} pt={"md"} i18nKey={"common.flavordbIngredientsAttribution"}/>
				</Stack>
			</ScrollableArea>

		</Modal>

		<Box className={classes.addinputnode}>
			<Stack align="center" justify="flex-start" gap="xs" >
				<Button size="lg" radius="50%" color="secondary" variant="filled"  onClick={open} className={classes.addinputnodebutton}>
					+
				</Button>
				<Text size={"sm"} c={"secondary"} maw={300} ta={"center"}>{t("studio.addMainIngredient")}</Text>
			</Stack>
		</Box>
	</>
}

/**
 * ResizeObserverComponent
 * Monitors the dimensions of its child element and provides them as props.
 *
 * @param {object} props
 * @param {React.ReactNode} props.children - The child component to observe.
 * @param {function} [props.onResize] - Optional callback function to handle size updates.
 */
const ResizeObserverComponent = ({ children, onResize }) => {
	const containerRef = useRef(null);
	const [hasZeroDimension, setHasZeroDimension] = useState(false); // Stato per tracciare width o height pari a 0

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const { width, height } = entry.contentRect;

				// Controlla se width o height sono zero e aggiorna lo stato
				if (width === 0 || height === 0) {
					setHasZeroDimension(true);
				} else {
					setHasZeroDimension(false);
				}

				if (onResize) onResize({ width, height });
			}
		});

		if (containerRef.current) {
			resizeObserver.observe(containerRef.current);
		}

		// Cleanup observer
		return () => {
			resizeObserver.disconnect();
		};
	}, [onResize]);

	return (
		<Box
			ref={containerRef}
			style={{
				position: 'relative',
				width: '100%',
				height: '100%'
			}}
		>
			<>
				{children}
			</>
			{hasZeroDimension && (
				<Box
					style={{
						position: "absolute",
						left: "-10px",
						width: "100%",
						height: "100%",
						border: "5px solid var(--mantine-color-tertiary-3)",
						pointerEvents: "none",
					}}
				>
				</Box>
				)}
		</Box>
	);
};

/**
 * StudioPage
 */
const StudioPage = (props) => {

	const {paramLng} = useParams();
	const lng = parameterLanguage(paramLng);

	const {isLoading, isLoaded} = useLoadingContext();

	const {isAuthenticated} = useAccountContext();

	const navigate = useNavigate();
	const {t} = useTranslation();

	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);

	const [selection, setSelection] = useState(toSelection());

	const [entities, setEntities] = useState([]);

	const {screenToFlowPosition} = useReactFlow();
	const [node, setNode, edge, setEdge, reset] = useDnDContext();

	let studioHeatmapEnabled = useStudioStore((state) => state.studioHeatmapEnabled);
	const setStudioHeatmapEnabled = useStudioStore((state) => state.setStudioHeatmapEnabled);

	useLifecycle({
		onMount: () => {

			if (!isAuthenticated) {
				homeNavigate(navigate);
			}

			isLoading(false, false);
		},
		onUnmount: () => {
		}
	});

	useViewportHeight();

	// Change body style for this page
	useBodyStyle({
		overflow: "hidden",
		width: "100%",
		height: "100%",
		userSelect: "none",
		backgroundColor: "var(--mantine-color-body)"
	});

	const {data: dataEntities, isLoaded: isLoadedEntities, totalCount: totalCountEntities,
		reset: resetEntities, refetch: refetchEntities	} =
		useEntityAll({
			enabled: true,
		})

	const {data: dataMolecules, isLoaded: isLoadedMolecules, totalCount: totalCountMolecules,
		reset: resetMolecules, refetch: refetchMolecules	} =
		useMoleculeFull({
			enabled: isLoadedEntities,
		})

	const {isSuccess, isError} =
		useResult({
			isSuccess: isLoadedEntities & isLoadedMolecules,
			onSuccess: () => {
				const entities = entitiesMoleculesAggregation(dataEntities, dataMolecules);
				setEntities(entities);
				isLoaded(true);
			}
		})

	useEffect(() => {
		if (lng !== undefined && lng !== i18n.language) {
			i18n.changeLanguage(lng)
				.then(value => window.location.reload());
		}
	}, [lng]);

	/**
	 * onConnect
	 */
	const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)),
		[],
	);

	/**
	 * onSelectionChange
	 */
	const onSelectionChange = useCallback(({nodes: selectedNodes, edges: selectedEdges}) => {

		if(selectedNodes.length > 0 || selectedEdges.length > 0) {
			setSelection(toSelection(selectedNodes, selectedEdges, false));
		}
	}, []);

	/**
	 * onPaneClick
	 */
	const handlePaneClick = useCallback((event) => {
		setSelection(toSelection())
	}, []);

	/**
	 * onDragOver
	 */
	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	}, []);

	/**
	 * onDrop
	 */
	const onDrop = useCallback((event) => {

			event.preventDefault();

			// Check if the dropped element is valid
			if (node === undefined) {
				return;
			}

			// Convert screen position to flow position
			const position = screenToFlowPosition({
				x: snapToGrid(event.clientX),
				y: snapToGrid(event.clientY),
			});

            const entity = getEntityById(entities, node.id);

			let newNode = toNode(node.id, node.type, position, entity);

			const groupNode = findGroupNodeAtPosition(nodes, position);

			if(groupNode) {
				newNode = toChildNode(newNode, groupNode.id, groupNode.position);
			}

			setNodes((nds) => nds.concat(newNode));

            if(edge !== undefined) {
				setEdges((eds) => eds.concat(edge));
            }

			reset();
		},
		[screenToFlowPosition, node, edge],
	);

	/**
	 * handleNodesChange
	 */
	const handleNodesChange = useCallback((changes) => {

		// Check if the input node was removed
		const inputNodeRemoved = changes.some(
			(change) =>
				change.type === "remove" &&
				nodes.find((node) => node.id === change.id)?.type === "input"
		);

		if (inputNodeRemoved) {
			// Clear all nodes and edges if the input node is removed
			setNodes([]);
			setEdges([]);
			return;
		}

		// Get IDs of nodes to remove from the changes
		const nodesToRemoveIds = changes
			.filter((change) => change.type === "remove")
			.map((change) => change.id);

		if (nodesToRemoveIds.length > 0) {

			/**
			 * Function to recursively collect all nodes to remove, including connected nodes.
			 * @param {Array} nodeIdsToRemove - IDs of nodes to remove
			 * @returns {Array} - Array of all node IDs to remove
			 */
			const collectNodesToRemove = (nodeIdsToRemove) => {

				// Use a Set to store all nodes to remove (to avoid duplicates)
				let allNodesToRemove = new Set(nodeIdsToRemove);

				let newNodesToRemove; // To track newly identified nodes to remove

				do {
					// Find all nodes that are connected to the current set of nodes to remove
					newNodesToRemove = edges
						.filter((edge) => nodeIdsToRemove.includes(edge.source)) // Find edges where the source node is being removed
						.map((edge) => edge.target) // Get the target nodes of those edges
						.filter((nodeId) => !allNodesToRemove.has(nodeId)); // Only include nodes not already marked for removal

					// Add the new nodes to the set
					newNodesToRemove.forEach((nodeId) => allNodesToRemove.add(nodeId));

					// Update the list of nodes to check in the next iteration
					nodeIdsToRemove = newNodesToRemove;
				}
				while (newNodesToRemove.length > 0); // Continue until no new nodes are found

				return Array.from(allNodesToRemove); // Convert the Set back to an array
			};

			// Collect all nodes to remove, including connected ones
			const allNodesToRemoveIds = collectNodesToRemove(nodesToRemoveIds);

			// Filter out nodes that need to be removed
			const updatedNodes = nodes.filter(
				(node) => !allNodesToRemoveIds.includes(node.id)
			);

			// Remove edges that reference removed nodes
			const updatedEdges = edges.filter(
				(edge) =>
					updatedNodes.find((node) => node.id === edge.source) && // Keep edge if source node still exists
					updatedNodes.find((node) => node.id === edge.target)    // Keep edge if target node still exists
			);

			// Update nodes and edges
			setNodes(updatedNodes);
			setEdges(updatedEdges);
			setSelection(toSelection());
		}

		// Apply normal React Flow changes (dragging, adding nodes, etc.)
		onNodesChange(changes);

	}, [onNodesChange, nodes, edges, setNodes, setEdges]);

	/**
	 * handleAddInputNode
	 */
	const handleAddInputNode = useCallback((entity) => {

			// TODO check se corretto
			const reactFlowBounds = document.querySelector('.react-flow').getBoundingClientRect();

			const viewportCenter = {
				x: snapToGrid(reactFlowBounds.width / 2),
				y: snapToGrid(reactFlowBounds.height / 2),
			};

			// Usa `screenToFlowPosition` per convertire il centro del viewport nelle coordinate di React Flow
			const position = screenToFlowPosition(viewportCenter);

			const newNode = toNode(`${entity.entityId}`, "input", position, entity, true);

			setNodes((nds) => [...nds, newNode]);
		},
		[setNodes]
	);

	/**
	 * handleGroupNodes
	 */
	const handleGroupNodes = useCallback(() => {

		const selectedNodes = nodes.filter((node) => node.selected);

		const groupId = toGroupId();
		const bounds = getNodesBounds(selectedNodes);

		if (!bounds) {
			console.error("Error: Unable to calculate the bounds of the selected nodes.");
			return;
		}

		const groupPosition = {
			x: snapToGrid(bounds.x - 20),
			y: snapToGrid(bounds.y - 40),
		};

		const groupSize = {
			width: snapToGrid(bounds.width + 40),
			height: snapToGrid(bounds.height + 60),
		};

		const groupNode = toGroup(groupId, groupPosition, groupSize);

		const updatedNodes = nodes.map((node) =>
			selectedNodes.some((selectedNode) => selectedNode.id === node.id) ? toChildNode(node, groupId, groupPosition) : node
		);

		// Update nodes
		setNodes(sortNodesByParent([groupNode, ...updatedNodes]));

	}, [nodes, setNodes]);

	/**
	 * handleNodeDragStop
	 * This function handles the logic for when a node is dragged and released (drag stop event).
	 * It checks if the released node should become a child of a group node and updates its position accordingly.
	 */
	const handleNodeDragStop = useCallback((event, node) => {

		// Update the nodes state
		setNodes((prevNodes) => {
			const updatedNodes = prevNodes.map((n) => {

				// If the current node is not the one being released, or it is a group node, return it unchanged
				if (n.id !== node.id || n.type === "group") {
					return n;
				}

				// Find a group node at the current position of the released node
				const groupNode = findGroupNodeAtPosition(prevNodes, node.position);

				if (groupNode && groupNode.id !== n.parentId) {
					// If a group node is found and it's not already the parent of the released node:
					return toChildNode(n, groupNode.id, groupNode.position);
				}

				// If no group node is found, return the node unchanged
				return n;
			});

			// Update the nodes state with the modified array
			return updatedNodes;
		});

	}, [setNodes]);

	/**
	 * minimapNodeClassName
	 */
	const minimapNodeClassName = (node) => node.type;

	/**
	 * proOptions
	 */
	const proOptions = { hideAttribution: true };

	return !isSuccess || !isAuthenticated ? null :
			<Paper w={"100vw"} h={"calc(var(--vh, 1vh) * 100)"} radius={0}>

				<Meta title={"FP-Studio"}
					  description={""}
					  keywords={"studio"}/>

				<Stack w={"100%"} h={"100%"} align="stretch" justify="space-between" gap={0}>
					<Allotment>
						<Allotment.Pane>
							<ReactFlow
								nodes={nodes}
								edges={edges}
								nodeTypes={nodeTypes}
								edgeTypes={edgeTypes}
								onPaneClick={handlePaneClick}
								onNodesChange={handleNodesChange}
								onEdgesChange={onEdgesChange}
								onSelectionChange={onSelectionChange}
								onNodeDragStop={handleNodeDragStop}
								// connectionLineComponent={FloatingConnectionLine}
								onConnect={onConnect}
								snapToGrid
								snapGrid={[GRID_SIZE, GRID_SIZE]}
								onDrop={onDrop}
								onDragOver={onDragOver}
								proOptions={proOptions}
								// fitView
								// style={{ backgroundColor: "#F7F9FB" }}
							>
								{nodes.length === 0 ? null :
									<>
										<HeaderPanel position={"top-left"}
													 showGroup={getParentIds(selection.nodes).length === 0} onGroupClick={handleGroupNodes}/>
										<MiniMap position={"bottom-left"} zoomable pannable nodeClassName={minimapNodeClassName}/>
										<Background offset={1} variant={BackgroundVariant.Dots} gap={GRID_SIZE} size={1} color={"var(--mantine-color-primary-2)"}/>
									</>
								}
							</ReactFlow>
							{nodes.length > 0 ? null :
								<AddInputNode entities={entities} onAddNode={handleAddInputNode}/>
							}
						</Allotment.Pane>

						<Allotment.Pane minSize={450} maxSize={window.innerHeight / 1.5} preferredSize={450} snap>
							{nodes.length === 0 ? null :
								<ResizeObserverComponent>
									<Details entities={entities} nodes={nodes} edges={edges} selection={selection}/>
								</ResizeObserverComponent>
							}
						</Allotment.Pane>

					</Allotment>

					<Box w={"100%"} h={"30px"} bg={"var(--mantine-color-gray-3)"}>
						{/*Footer*/}
					</Box>
				</Stack>
			</Paper>
}

export default () => (
	<ReactFlowProvider>
		<DnDProvider>
			<StudioPage/>
		</DnDProvider>
	</ReactFlowProvider>
);