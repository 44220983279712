import React, {useEffect, useState} from "react";
import {Box} from "@mantine/core";

/**
 * SelectionChangeMemo
 */
export const SelectionChangeMemo = React.memo(
	({ selection, other, children }) => {

		// Render the component with its children
		return (
			<Box w={"100%"} h={"100%"}>
				{children}
			</Box>
		);
	},
	(prevProps, nextProps) => {
		// Compare selection timestamps
		const isSelectionEqual = prevProps.selection.timestamp === nextProps.selection.timestamp;

		// Compare other (shallow equality)
		const isOtherEqual = JSON.stringify(prevProps.other) === JSON.stringify(nextProps.other);

		// Return true if nothing has changed
		return isSelectionEqual && isOtherEqual;
	}
);