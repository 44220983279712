import React from 'react';
import {Helmet} from "react-helmet";
import i18n from "../../i18n";

/**
 * BasePage
 */
export const Meta = ({title, description, keywords, url, image, schema}) => {

    return (

		<Helmet>
			<title>CuckooKitchen {title ? `- ` + title : ``}</title>
			{description && <meta name="description" content={description}/>}
			<meta name="keywords" content={(keywords || ``) + `,food pairing,food,pairing,cukookitchen,cucukitchen,kuckuckkitchen,cuckoo,cucu,kuckuckkitchen`}/>
			<html lang={i18n.language}/>

			<meta property="og:title" content={`CuckooKitchen ${title ? "- " + title : ""}`}/>
			{description && <meta property="og:description" content={description}/>}
			{image && <meta property="og:image" content={image}/>}
			<meta property="og:url" content={url !== undefined ? `${window.location.origin}${url}` : window.location.href}/>
			<meta property="og:type" content="article"/>
			<meta property="og:site_name" content="CuckooKitchen"/>

			<link rel="canonical" href={url !== undefined ? `${window.location.origin}${url}` : window.location.href}/>
			<link rel="alternate" hrefLang="de" href={url !== undefined ? `${window.location.origin}/de${url}` : window.location.href}/>
			<link rel="alternate" hrefLang="en" href={url !== undefined ? `${window.location.origin}/en${url}` : window.location.href}/>
			<link rel="alternate" hrefLang="es" href={url !== undefined ? `${window.location.origin}/es${url}` : window.location.href}/>
			<link rel="alternate" hrefLang="fr" href={url !== undefined ? `${window.location.origin}/fr${url}` : window.location.href}/>
			<link rel="alternate" hrefLang="it" href={url !== undefined ? `${window.location.origin}/it${url}` : window.location.href}/>
			<link rel="alternate" hrefLang="pt" href={url !== undefined ? `${window.location.origin}/pt${url}` : window.location.href}/>

			{schema &&
				<script type="application/ld+json">
					{JSON.stringify(schema, null, 4)}
				</script>
			}

			<meta name="twitter:card" content="summary_large_image"/>
			<meta name="twitter:title" content={`CuckooKitchen ${title ? "- " + title : ""}`}/>
			{description && <meta name="twitter:description" content={description}/>}
			{image && <meta name="twitter:image" content={image}/>}
			<meta name="twitter:site" content="@cuckookitchenai"/>

			{/*<meta property="fb:app_id" content="1234567890"/>*/}
		</Helmet>
	)
}