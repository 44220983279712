import React, {useEffect, useRef, useState} from 'react';
import {
    ActionIcon, Text,
    TextInput, Tooltip,
} from '@mantine/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faDeleteLeft,
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import classes from './Search.module.css';

/**
 * SearchInput
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const SearchInput = (props) => {

    const { value,
        onChange,
        onSearch,
        iconColor = "secondary",
        tooltip = undefined,
        withLeftSection = true,
        focus = false,
        size="sm",
        textInputClassNames= {
            input: classes.sarchinputinput
        }
    } = props;

    const [tooltipOpened, setTooltipOpened] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        // Focus su TextInput quando il menu si apre
        if (focus) {

            setTimeout(() => {
                inputRef.current?.focus();
            }, 100);
        }
    }, [focus]);

    /**
     * LeftSection
     *
     * @returns {React.JSX.Element}
     * @constructor
     */
    const LeftSection = () =>
        <ActionIcon
            variant='subtle'
            color={iconColor}
            disabled={!value}
            style={{color: !value ? `var(--mantine-color-${iconColor}-4)` : `var(--mantine-color-${iconColor}-6)`, marginRight:"2px"}}
            onMouseDown={() => {
                if(onSearch) {
                    onSearch();
                }
            }}
            className={classes.leftsection}>
            <FontAwesomeIcon icon={faMagnifyingGlass}/>
        </ActionIcon>

    /**
     * RightSection
     *
     * @returns {Element}
     * @constructor
     */
    const RightSection = () =>
        <ActionIcon
            variant='subtle'
            color={iconColor}
            style={{visibility: value ? "visible" : "hidden", marginRight:"2px"}}
            onMouseDown={() => {
                onChange("");
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 100);
            }}>
            <FontAwesomeIcon icon={faDeleteLeft}/>
        </ActionIcon>

    return <Tooltip color="tertiary" position="bottom" offset={22}
                    zIndex={403} withArrow arrowSize={14}
                    opened={tooltipOpened}
                    label={<Text size={"sm"}>{tooltip}</Text>}
                    p={"md"}
                    style={{visibility: tooltip !== undefined && tooltip.length > 0 ? "visible" : "hidden"}}>
                <TextInput
                    ref={inputRef}
                    size={size}
                    w={"100%"}
                    fw={"400"}
                    leftSection={withLeftSection ? <LeftSection/> : null}
                    rightSection={value ? <RightSection/> : null}
                    classNames={textInputClassNames}
                    {...props}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            if(onSearch) {
                                onSearch();
                            }
                        }
                    }}
                    onFocus={() => setTooltipOpened((o) => !o)}
                    onBlur={() => setTooltipOpened((o) => !o)}
                    onChange={(event) => onChange(event.currentTarget.value)}
            />
        </Tooltip>
}