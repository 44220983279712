import React, { useEffect, useState} from 'react';
import {
    Container, Group, Highlight, NumberFormatter,
    Space,
    Stack,
    Table,
    Tabs,
    Text,
    Title, useMantineTheme,
    VisuallyHidden
} from '@mantine/core';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import i18n, {localized, localizedEn, parameterLanguage} from "../../i18n";
import classes from "./MoleculePage.module.css";
import {Page, PageCard, PageTitle} from "../../components/page/Page";
import {useTranslation} from "react-i18next";
import {Ingredient09} from "../ingredient/IngredientList";
import {alphabeticComparator} from "../../util/utils";
import {Icon} from "../../components/icons/Icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {ScrollTab} from "../../components/scrollTab/ScrollTab";
import {SearchInput} from "../../components/search/Search";
import {paginationEndSkip, paginationStartSkip} from "../../util/pagination";
import {Paginator} from "../../components/paginator/Paginator";
import {TooltipEn} from "../../components/languageSelector/LanguageSelector";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import useKey from "../useKey";
import useMolecule from "./useMolecule";
import useEntityAll from "../ingredient/useEntityAll";
import useResult from "../useResult";
import {AdvertisingGrid} from "../../components/advertising/Advertising";
import {Recipe09} from "../recipe/RecipeList";
import {FlavorDbCredits} from "../../components/credits/Credits";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import {IngredientIconTitle} from "../ingredient/IngredientLink";
import {RecipeIconTitle} from "../recipe/RecipeLink";
import {Settings, useAccountContext} from "../../components/account/AccountContext";
import {faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons/faHeart";
import {faHeart} from "@fortawesome/free-regular-svg-icons/faHeart";
import useRecipeAll from "../recipe/useRecipeAll";
import useIngredientAll from "../ingredient/useIngredientAll";
import {homeNavigate} from "../home/HomeLink";
import {Sponsors} from "../../components/sponsor/Sponsor";
import useInsights from "../useInsights";
import {useFeatures} from "../../components/features/useFeatures";
import {theme} from "../../Theme";

/**
 * @param items
 * @param key
 * @returns {unknown[]}
 */
function extractIngredients(entities, item){
    let result = [];

    entities.forEach(entity => {
        entity.ingredients.forEach(ingredient => {
            if (!result.some(i => i.ingredientId === ingredient.ingredientId)) {

                if(ingredient.ingredientId !== item.ingredientId) {

                    result.push({
                        ...ingredient,
                        categories: entity.categories
                    });
                }
            }
        });
    });

    return result;
}

/**
 * open
 *
 * @param molecule
 * @param target
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
function open(url, target = "_self") {
    window.open(url, target)?.focus();
}

/**
 * Flavors
 *
 * @param molecule
 * @constructor
 */
const Flavors = ({molecule}) => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");
    const [searchItems, setSearchItems] = useState([]);

    const {t} = useTranslation();
    const theme = useMantineTheme();

    useEffect(() => {
        setSearch("");
    }, []);

    useEffect(() => {

        try {

            if (!search) {
                setSearchItems(molecule.flavors);
            } else {
                setSearchItems(molecule.flavors.filter(flavor => localized(flavor, 'name').toLowerCase().includes(search.toLowerCase())))
            }
        }
        catch(ignored) {
            // noop
        }

    }, [search]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
    }

    return (!molecule.flavors ? null :
            <>
                <Stack justify="space-between" gap={"0"}>
                    <Stack justify="flex-start" gap="0">
                        <VisuallyHidden>
                            <Title order={2} pt={"lg"}>{t('molecule.flavors')}</Title>
                        </VisuallyHidden>
                        <Space h="lg"/>
                        <StripedTable stripedColor={"tertiary"} style={{color: "var(--mantine-color-tertiary-9)"}}>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th p={0}>
                                        <SearchInput
                                            value={search}
                                            placeholder={t('molecule.flavors')}
                                            onChange={(value) => onSearch(value)}
                                        />
                                    </Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>

                                {searchItems.length === 0 ? null :
                                    <Space h={"lg"}/>
                                }

                                {searchItems
                                    .sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
                                    .slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
                                    .map((item, index) => (
                                        <Table.Tr key={`molecule-flavor-${index}`}>
                                            <Table.Td>
                                                <Group justify={"flex-start"} gap={2}>
                                                    <Highlight highlight={search}>
                                                        {localized(item, 'name')}
                                                    </Highlight>
                                                    <TooltipEn label={localized(item, 'name')} labelEn={localizedEn(item, 'name')}/>
                                                </Group>
                                            </Table.Td>
                                        </Table.Tr>
                                    ))}
                            </Table.Tbody>
                        </StripedTable>
                    </Stack>
                    <Space h={"lg"}/>
                    <Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.recipe.paginationSize}
                               selectedCount={searchItems.length} totalCount={molecule.flavors.length}/>
                </Stack>
                <Space h="lg"/>
                <SimpleBox color="tertiary">
                    <Text size={"sm"}>{t("molecule.flavorsDescription")}</Text>
                </SimpleBox>
                <FlavorDbCredits pl={"md"} pt={"md"} i18nKey={"common.flavordbMoleculesAttribution"}/>
            </>
    )
}

/**
 * Odors
 *
 * @param molecule
 * @constructor
 */
const Odors = ({molecule}) => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");
    const [searchItems, setSearchItems] = useState([]);

    const {t} = useTranslation();
    const theme = useMantineTheme();

    useEffect(() => {
        setSearch("");
    }, []);

    useEffect(() => {

        try {

            if (!search) {
                setSearchItems(molecule.odors);
            } else {
                setSearchItems(molecule.odors.filter(odor => localized(odor, 'name').toLowerCase().includes(search.toLowerCase())))
            }
        }
        catch(ignored) {
            // noop
        }

    }, [search]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
    }

    return (!molecule.odors ? null :
            <>
                <Stack justify="space-between" gap={"0"}>
                    <Stack justify="flex-start" gap="0">
                        <VisuallyHidden>
                            <Title order={2} pt={"lg"}>{t('molecule.odors')}</Title>
                        </VisuallyHidden>
                        <Space h="lg"/>
                        <StripedTable stripedColor={"tertiary"} style={{color: "var(--mantine-color-tertiary-9)"}}>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th p={0}>
                                        <SearchInput
                                            value={search}
                                            placeholder={t('molecule.odors')}
                                            onChange={(value) => onSearch(value)}
                                        />
                                    </Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>

                                {searchItems.length === 0 ? null :
                                    <Space h={"lg"}/>
                                }

                                {searchItems
                                    .sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
                                    .slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
                                    .map((item, index) => (
                                        <Table.Tr key={`molecule-odor-${index}`}>
                                            <Table.Td>
                                                <Group justify={"flex-start"} gap={2}>
                                                    <Highlight highlight={search}>
                                                        {localized(item, 'name')}
                                                    </Highlight>
                                                    <TooltipEn label={localized(item, 'name')} labelEn={localizedEn(item, 'name')}/>
                                                </Group>
                                            </Table.Td>
                                        </Table.Tr>
                                    ))}
                            </Table.Tbody>
                        </StripedTable>
                    </Stack>
                    <Space h={"lg"}/>
                    <Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.recipe.paginationSize}
                               selectedCount={searchItems.length} totalCount={molecule.odors.length}/>
                </Stack>
                <Space h="lg"/>
                <SimpleBox color="tertiary">
                    <Text size={"sm"}>{t("molecule.odorsDescription")}</Text>
                </SimpleBox>
                <FlavorDbCredits pl={"md"} pt={"md"} i18nKey={"common.flavordbMoleculesAttribution"}/>
            </>
    )
}

/**
 * Tastes
 *
 * @param molecule
 * @constructor
 */
const Tastes = ({molecule}) => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");
    const [searchItems, setSearchItems] = useState([]);

    const {t} = useTranslation();
    const theme = useMantineTheme();

    useEffect(() => {
        setSearch("");
    }, []);

    useEffect(() => {

        try {

            if (!search) {
                setSearchItems(molecule.tastes);
            } else {
                setSearchItems(molecule.tastes.filter(taste => localized(taste, 'name').toLowerCase().includes(search.toLowerCase())))
            }
        }
        catch(ignored) {
            // noop
        }

    }, [search]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
    }

    return (!molecule.tastes ? null :
            <>
                <Stack justify="space-between" gap={"0"}>
                    <Stack justify="flex-start" gap="0">
                        <VisuallyHidden>
                            <Title order={2} pt={"lg"}>{t('molecule.tastes')}</Title>
                        </VisuallyHidden>
                        <Space h="lg"/>
                        <StripedTable stripedColor={"tertiary"} style={{color: "var(--mantine-color-tertiary-9)"}}>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th p={0}>
                                        <SearchInput
                                            value={search}
                                            placeholder={t('molecule.tastes')}
                                            onChange={(value) => onSearch(value)}
                                        />
                                    </Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>

                                {searchItems.length === 0 ? null :
                                    <Space h={"lg"}/>
                                }

                                {searchItems
                                    .sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
                                    .slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
                                    .map((item, index) => (
                                        <Table.Tr key={`molecule-taste-${index}`}>
                                            <Table.Td>
                                                <Group justify={"flex-start"} gap={2}>
                                                    <Highlight highlight={search}>
                                                        {localized(item, 'name')}
                                                    </Highlight>
                                                    <TooltipEn label={localized(item, 'name')} labelEn={localizedEn(item, 'name')}/>
                                                </Group>
                                            </Table.Td>
                                        </Table.Tr>
                                    ))}
                            </Table.Tbody>
                        </StripedTable>
                    </Stack>
                    <Space h={"lg"}/>
                    <Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.recipe.paginationSize}
                               selectedCount={searchItems.length} totalCount={molecule.tastes.length}/>
                </Stack>
                <Space h="lg"/>
                <SimpleBox color="tertiary">
                    <Text size={"sm"}>{t("molecule.tastesDescription")}</Text>
                </SimpleBox>
                <FlavorDbCredits pl={"md"} pt={"md"} i18nKey={"common.flavordbMoleculesAttribution"}/>
            </>
    )
}

/**
 * Compounds
 *
 * @param molecule
 * @constructor
 */
const Compounds = ({molecule}) => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");
    const [searchItems, setSearchItems] = useState([]);

    const {t} = useTranslation();
    const theme = useMantineTheme();

    useEffect(() => {
        setSearch("");
    }, []);

    useEffect(() => {

        try {

            if (!search) {
                setSearchItems(molecule.compounds);
            } else {
                setSearchItems(molecule.compounds.filter(compound => localized(compound, 'name').toLowerCase().includes(search.toLowerCase())))
            }
        }
        catch(ignored) {
            // noop
        }

    }, [search]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
    }

    return (!molecule.compounds ? null :
            <>
                <Stack justify="space-between" gap={"0"}>
                    <Stack justify="flex-start" gap="0">
                        <VisuallyHidden>
                            <Title order={2} pt={"lg"}>{t('molecule.compounds')}</Title>
                        </VisuallyHidden>
                        <Space h="lg"/>
                        <StripedTable stripedColor={"tertiary"} style={{color: "var(--mantine-color-tertiary-9)"}}>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th p={0}>
                                        <SearchInput
                                            value={search}
                                            placeholder={t('molecule.compounds')}
                                            onChange={(value) => onSearch(value)}
                                        />
                                    </Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>

                                {searchItems.length === 0 ? null :
                                    <Space h={"lg"}/>
                                }

                                {searchItems
                                    .sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
                                    .slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
                                    .map((item, index) => (
                                        <Table.Tr key={`molecule-compound-${index}`}>
                                            <Table.Td>
                                                <Group justify={"flex-start"} gap={2}>
                                                    <Highlight highlight={search}>
                                                        {localized(item, 'name')}
                                                    </Highlight>
                                                    <TooltipEn label={localized(item, 'name')} labelEn={localizedEn(item, 'name')}/>
                                                </Group>
                                            </Table.Td>
                                        </Table.Tr>
                                    ))}
                            </Table.Tbody>
                        </StripedTable>
                    </Stack>
                    <Space h={"lg"}/>
                    <Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.recipe.paginationSize}
                               selectedCount={searchItems.length} totalCount={molecule.compounds.length}/>
                </Stack>
                <Space h="lg"/>
                <SimpleBox color="tertiary">
                    <Text size={"sm"}>{t("molecule.compoundsDescription")}</Text>
                </SimpleBox>
                <FlavorDbCredits pl={"md"} pt={"md"} i18nKey={"common.flavordbMoleculesAttribution"}/>
            </>
    )
}

/**
 * Scientific
 *
 * @returns {Element}
 * @constructor
 */
const Scientific = ({molecule}) => {

    const { t } = useTranslation();

    return (
        <Stack justify="space-between" gap={"0"}>
            <Stack justify="flex-start" gap="0">
                <VisuallyHidden>
                    <Title order={2} pt={"lg"}>{t('molecule.scientific')}</Title>
                </VisuallyHidden>
                <Space h="lg"/>

                <StripedTable stripedColor={"secondary"} highlightOnHover highlightOnHoverColor={"secondary"} style={{color: "var(--mantine-color-secondary-9)"}}>
                    <Table.Tbody>
                        {!molecule.moleculeId ? null :
                            <Table.Tr style={{cursor: "pointer"}} onClick={() => open(`https://pubchem.ncbi.nlm.nih.gov/compound/${molecule.moleculeId}`, "_blank")}>
                                <Table.Td>
                                    <Text>{t('molecule.pubchemId')}</Text>
                                </Table.Td>
                                <Table.Td ta={"right"}>
                                    <Text>
                                        {molecule.moleculeId}
                                    </Text>
                                </Table.Td>
                                <Table.Td w={"30px"} pt={5}>
                                    <FontAwesomeIcon icon={faUpRightFromSquare}/>
                                </Table.Td>
                            </Table.Tr>
                        }

                        {!molecule.fooddbId ? null :
                            <Table.Tr style={{cursor: "pointer"}} onClick={() => open(`https://foodb.ca/compounds/${molecule.fooddbId}`, "_blank")}>
                                <Table.Td>
                                    <Text>{t('molecule.fooddbId')}</Text>
                                </Table.Td>
                                <Table.Td ta={"right"}>
                                    <Text>
                                        {molecule.fooddbId}
                                    </Text>
                                </Table.Td>
                                <Table.Td w={"30px"}>
                                    <FontAwesomeIcon icon={faUpRightFromSquare}/>
                                </Table.Td>
                            </Table.Tr>
                        }
                    </Table.Tbody>
                </StripedTable>
                <StripedTable stripedColor={"tertiary"} style={{color: "var(--mantine-color-tertiary-9)"}}>
                    <Table.Tbody>
                        {!molecule.bitterdbId ? null :
                            <Table.Tr>
                                <Table.Td>
                                    <Text>{t('molecule.bitterdbId')}</Text>
                                </Table.Td>
                                <Table.Td ta={"right"}>
                                    <Text>
                                        {molecule.bitterdbId}
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        }

                        {!molecule.femaId ? null :
                            <Table.Tr>
                                <Table.Td>
                                    <Text>{t('molecule.femaId')}</Text>
                                </Table.Td>
                                <Table.Td ta={"right"}>
                                    <Text>
                                        {molecule.femaId}
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        }

                        {!molecule.supersweetdbId ? null :
                            <Table.Tr>
                                <Table.Td>
                                    <Text>{t('molecule.supersweetdbId')}</Text>
                                </Table.Td>
                                <Table.Td ta={"right"}>
                                    <Text>
                                        {molecule.supersweetdbId}
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        }

                        {!molecule.smile ? null :
                            <Table.Tr>
                                <Table.Td>
                                    <Text>{t('molecule.smile')}</Text>
                                </Table.Td>
                                <Table.Td ta={"right"}>
                                    <Text>
                                        {molecule.smile}
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        }
                        {!molecule.casId ? null :
                            <Table.Tr>
                                <Table.Td>
                                    <Text>{t('molecule.casId')}</Text>
                                </Table.Td>
                                <Table.Td ta={"right"}>
                                    <Text>
                                        {molecule.casId}
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        }
                        {/*{!molecule.type ? null :*/}
                        {/*    <Table.Tr>*/}
                        {/*        <Table.Td>*/}
                        {/*            <Text>{t('common.type')}</Text>*/}
                        {/*        </Table.Td>*/}
                        {/*        <Table.Td ta={"right"}>*/}
                        {/*            <Text>*/}
                        {/*                {molecule.type}*/}
                        {/*            </Text>*/}
                        {/*        </Table.Td>*/}
                        {/*    </Table.Tr>*/}
                        {/*}*/}
                        {!molecule.mass ? null :
                            <Table.Tr>
                                <Table.Td>
                                    <Text>{t('molecule.mass')}</Text>
                                </Table.Td>
                                <Table.Td ta={"right"}>
                                    <Text>
                                        {molecule.mass}g/mol
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        }
                        {!molecule.weight ? null :
                            <Table.Tr>
                                <Table.Td>
                                    <Text>{t('molecule.weight')}</Text>
                                </Table.Td>
                                <Table.Td ta={"right"}>
                                    <Text>
                                        {molecule.weight}g/mol
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        }

                        {!molecule.inchi ? null :
                            <Table.Tr>
                                <Table.Td>
                                    <Text>{t('molecule.inchi')}</Text>
                                </Table.Td>
                                <Table.Td ta={"right"}>
                                    <Text>
                                        {molecule.inchi}
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        }
                    </Table.Tbody>
                </StripedTable>
            </Stack>
            <FlavorDbCredits pl={"md"} pt={"md"} i18nKey={"common.flavordbMoleculesAttribution"}/>
        </Stack>
    );
}

/**
 * MoleculeCard
 *
 * @returns {Element}
 * @constructor
 */
const MoleculeCard = ({molecule, ingredientIds, previousTotalCountRecipes, recipeTotalCount}) => {

    const { t } = useTranslation();

    return (
        <PageCard>
            <StripedTable stripedColor={"tertiary"} className={classes.moleculecardtable}>
                <Table.Tbody>

                    {!molecule.flavors || molecule.flavors.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <Text size={"sm"} fw={700}>
                                    {t('molecule.flavors')}
                                </Text>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {molecule.flavors.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }

                    {!molecule.odors || molecule.odors.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <Text size={"sm"} fw={700}>
                                    {t('molecule.odors')}
                                </Text>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {molecule.odors.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }

                    {!molecule.tastes || molecule.tastes.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <Text size={"sm"} fw={700}>
                                    {t('molecule.tastes')}
                                </Text>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {molecule.tastes.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }

                    {!molecule.compounds || molecule.compounds.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <Text size={"sm"} fw={700}>
                                    {t('molecule.compounds')}
                                </Text>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {molecule.compounds.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }

                    {ingredientIds.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <IngredientIconTitle textKey={'molecule.foundInIngredients'}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {ingredientIds.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }

                    {previousTotalCountRecipes === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <RecipeIconTitle textKey={'molecule.foundInRecipes'}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {previousTotalCountRecipes}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                </Table.Tbody>
            </StripedTable>
        </PageCard>
    );
}

/**
 * MoleculePage
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const MoleculePage = (props) => {

    const {paramLng} = useParams();
    const lng = parameterLanguage(paramLng);

    const { paramMoleculeId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const moleculeId = location.state?.moleculeId || paramMoleculeId;

    const [pageRecipes, setPageRecipes] = useState(1);
    const [previousTotalCountRecipes, setPreviousTotalCountRecipes] = useState(0);

    const { loaded, isLoading, isLoaded } = useLoadingContext();

    const [tabValue, setTabValue] = useState("0");
    const { t } = useTranslation();

    const {key, stateKey, random} =
        useKey({
            isLoading: isLoading,
            onKeyChange: () => {
                console.debug(`MoleculePage`);

                setPageRecipes(1);
                setPreviousTotalCountRecipes(0);

                resetRecipes();
                resetEntityIngredients();
                resetEntities();

                refetchItem();
            }
        });

    useEffect(() => {
        if(lng !== undefined && lng !== i18n.language) {
            i18n.changeLanguage(lng)
                .then(value => window.location.reload());
        }
    }, [lng]);

    const {	data: dataItem, isLoaded: isLoadedItem,
        	reset: resetItem, refetch: refetchItem} =
        useMolecule({
            moleculeId: moleculeId,
            incrementViews: true,
            onError: (error) => {
                homeNavigate(navigate);
            }
        })

    const {	data: dataInsights, isLoaded: isLoadedInsights,
        reset: resetInsights, refetch: refetchInsights} =
        useInsights({
            enabled: isLoadedItem,
            collectionName: "molecule",
            id: dataItem.moleculeId
        })

    const isEnabledEntities = isLoadedItem && isLoadedItem && !!dataItem?.entityIds.length > 0;
    const {	data: dataEntities, isLoaded: isLoadedEntities, totalCount: totalCountEntities, ingredientIds,
        	reset: resetEntities, refetch: refetchEntities} =
        useEntityAll({
            entityIds: dataItem.entityIds,
            enabled: isEnabledEntities
        })

    /**
     * Ingredients
     */
    const isEnabledEntityIngredients = isLoadedEntities && ingredientIds?.length > 0;
    const {	data: dataEntityIngredients, isLoaded: isLoadedEntityIngredients,
        reset: resetEntityIngredients, refetch: refetchEntityIngredients} =
        useIngredientAll({
            ingredientIds: ingredientIds,
            enabled: isEnabledEntityIngredients
        })

    const isEnabledRecipes = isLoadedEntities && ingredientIds?.length > 0;
    const {	data: dataRecipes, isLoaded: isLoadedRecipes, totalCount: totalCountRecipes,
        reset: resetRecipes, refetch: refetchRecipes} =
        useRecipeAll({
            enabled: isLoadedEntities && ingredientIds?.length > 0,
            page: pageRecipes,
            ingredientIds: ingredientIds,
            onSuccess: (data, totalCount, count) => {
                setPreviousTotalCountRecipes(totalCount);
            }
        })

    useEffect(() => {
        refetchRecipes();
    }, [pageRecipes]);

    const {isSuccess, isError} =
        useResult({
            isLoaded: isLoaded,
            isSuccess: isLoadedItem &&
                (isEnabledEntities ? isLoadedEntities : true) &&
                (isEnabledEntityIngredients ? isLoadedEntityIngredients : true)
                // (isEnabledRecipes ? isLoadedRecipes : true)
        })

    useEffect(() => {

        if(dataItem.flavors?.length > 0) {
            setTabValue("0");
        }
        else if(dataItem.odors?.length > 0) {
            setTabValue("1");
        }
        else if(dataItem.tastes?.length > 0) {
            setTabValue("2");
        }
        else if(dataItem.compounds?.length > 0) {
            setTabValue("3");
        }
        else {
            setTabValue("4");
        }

    }, [dataItem]);

    /**
     * DisplayTitle
     */
    const DisplayTitle = ({molecule}) => {

        const { isAuthenticated, userAccount, updateUserAccountSettings } = useAccountContext();
        const [settings] = useState(isAuthenticated ? userAccount.getSettings() : new Settings({}));
        const [favorite, setFavorite] = useState(settings.isFavoriteMolecule(molecule.moleculeId));

        const { Features } = useFeatures();

        /**
         * handleFavorite
         */
        function handleFavorite() {
            settings.setFavoriteMolecule(molecule.moleculeId);
            updateUserAccountSettings(userAccount);
            setFavorite(settings.isFavoriteMolecule(molecule.moleculeId));
        }

        return <Group align={"flex-start"} gap={6} wrap={"nowrap"}>
                    {Features.account.features.moleculeFavorites.plan.enabled &&
					    <FontAwesomeIcon icon={settings.isFavoriteMolecule(molecule.moleculeId) ? faHeartSolid : faHeart} size={"md"} color={"white"} opacity={0.25}
									 style={{cursor: "pointer", paddingTop: "8px", height: "38px"}}
									 onClick={(event) => {event.stopPropagation(); event.preventDefault(); handleFavorite()}}/>
                    }
					<Stack gap={0} align="flex-start" justify="flex-start">
						<Text lineClamp={2} inherit>
							{localized(molecule, 'name')}
						</Text>
						<Text size={"xs"} pl={2} pt={"xs"} ff={theme.fontFamily}><NumberFormatter thousandSeparator="'" value={dataInsights.views}/> {t("common.views")}</Text>
					</Stack>
				</Group>
    }

    return (!isSuccess ? null :

        <Page
            pageTitle={localized(dataItem, 'name')}
            pageDescription={localized(dataItem, 'name')}
            pageKeywords={`molecule,${dataItem.name},${localized(dataItem, 'name')}`}
            pageUrl={`/molecule/${dataItem.moleculeId}`}
            hasTab
            pageTitles={[
                <PageTitle
                    displayTitle={<DisplayTitle molecule={dataItem}/>}
                    pt={"60px"}
                    titleIcon={<Icon name={"physics"}/>}
                    withCard={true}
                    cardComponent = {<MoleculeCard molecule={dataItem} ingredientIds={ingredientIds} previousTotalCountRecipes={previousTotalCountRecipes} recipeTotalCount={totalCountRecipes}/>}
                    hasTabs
                    />
            ]}>

            <Tabs defaultValue="0" variant="outline" value={tabValue}>

                <Container>

                    <ScrollTab>

                        {!dataItem.flavors || dataItem.flavors.length === 0 ? null :
                            <ScrollTab.Tab selected={tabValue === "0"} onClick={() => setTabValue("0")}>
                                <Text size={"sm"} fw={700}>
                                    {t('molecule.flavors')}
                                </Text>
                            </ScrollTab.Tab>
                        }

                        {!dataItem.odors || dataItem.odors.length === 0 ? null :
                            <ScrollTab.Tab selected={tabValue === "1"} onClick={() => setTabValue("1")}>
                                <Text size={"sm"} fw={700}>
                                    {t('molecule.odors')}
                                </Text>
                            </ScrollTab.Tab>
                        }

                        {!dataItem.tastes || dataItem.tastes.length === 0 ? null :
                            <ScrollTab.Tab selected={tabValue === "2"} onClick={() => setTabValue("2")}>
                                <Text size={"sm"} fw={700}>
                                    {t('molecule.tastes')}
                                </Text>
                            </ScrollTab.Tab>
                        }

                        {!dataItem.compounds || dataItem.compounds.length === 0 ? null :
                            <ScrollTab.Tab selected={tabValue === "3"} onClick={() => setTabValue("3")}>
                                <Text size={"sm"} fw={700}>
                                    {t('molecule.compounds')}
                                </Text>
                            </ScrollTab.Tab>
                        }

                        <ScrollTab.Tab selected={tabValue === "4"} onClick={() => setTabValue("4")}>
                            <Text size={"sm"} fw={700}>
                                {t('molecule.scientific')}
                            </Text>
                        </ScrollTab.Tab>

                        {!dataEntityIngredients || dataEntityIngredients.length === 0 ? null :
                            <ScrollTab.Tab selected={tabValue === "5"} onClick={() => setTabValue("5")}>
                                <IngredientIconTitle textKey='molecule.foundInIngredients'/>
                            </ScrollTab.Tab>
                        }

                        {previousTotalCountRecipes === 0 ? null :
                            <ScrollTab.Tab selected={tabValue === "6"} ml={"xs"} onClick={() => setTabValue("6")}>
                                <RecipeIconTitle textKey='molecule.foundInRecipes'/>
                            </ScrollTab.Tab>
                        }

                    </ScrollTab>

                    <AdvertisingGrid>
                        {!dataItem.flavors || dataItem.flavors.length === 0 ? null :
                            <Tabs.Panel value="0" pb="xs">
                                <Flavors molecule={dataItem}/>
                            </Tabs.Panel>
                        }

                        {!dataItem.odors || dataItem.odors.length === 0 ? null :
                            <Tabs.Panel value="1" pb="xs">
                                <Odors molecule={dataItem}/>
                            </Tabs.Panel>
                        }

                        {!dataItem.tastes || dataItem.tastes.length === 0 ? null :
                            <Tabs.Panel value="2" pb="xs">
                                <Tastes molecule={dataItem}/>
                            </Tabs.Panel>
                        }

                        {!dataItem.compounds || dataItem.compounds.length === 0 ? null :
                            <Tabs.Panel value="3" pb="xs">
                                <Compounds molecule={dataItem}/>
                            </Tabs.Panel>
                        }

                        <Tabs.Panel value="4" pb="xs">
                            <Scientific molecule={dataItem}/>
                        </Tabs.Panel>

                        {!dataEntityIngredients || dataEntityIngredients.length === 0 ? null :
                            <Tabs.Panel value="5" pb="xs">
                                <Ingredient09 title=
                                                  {
                                                      <>
                                                          <VisuallyHidden>
                                                              <Title order={2} pt={"lg"}>{t('molecule.foundInIngredients')}</Title>
                                                          </VisuallyHidden>
                                                          <Space h="lg"/>
                                                      </>
                                                  }
                                              ingredients={dataEntityIngredients.sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))}
                                              ingredientTarget="_self" categoryTarget="_self"/>
                            </Tabs.Panel>
                        }

                        {previousTotalCountRecipes === 0 ? null :
                            <Tabs.Panel value="6" pb="xs">
                                <Recipe09 title={
                                                <>
                                                    <VisuallyHidden>
                                                        <Title order={2} pt={"lg"}>{t('molecule.foundInRecipes')}</Title>
                                                    </VisuallyHidden>
                                                    <Space h="lg"/>
                                                </>
                                            }
                                          recipes={dataRecipes}
                                          page={pageRecipes}
                                          onPageChange={setPageRecipes}
                                          totalCount={totalCountRecipes}
                                          recipeTarget="_self"
                                          categoryTarget="_self"/>
                            </Tabs.Panel>
                        }

                        <Space h={"lg"}/>
                        <Sponsors section={"molecule"} id={dataItem.moleculeId}/>

                    </AdvertisingGrid>
                </Container>
            </Tabs>
        </Page>
    );
}

export default MoleculePage;