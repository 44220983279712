import React from 'react';
import {
	useReactFlow,
	getNodesBounds,
	getViewportForBounds, Panel,
} from '@xyflow/react';
import {toSvg} from "html-to-image";
import {useTranslation} from "react-i18next";
import {ActionIcon, Group, Paper, Text} from "@mantine/core";
import {Icon} from "../../components/icons/Icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {useStudioStore} from "./StudioPage";

/**
 * downloadImage
 */
function downloadImage(dataUrl) {
	const a = document.createElement('a');

	a.setAttribute('download', 'reactflow.svg');
	a.setAttribute('href', dataUrl);
	a.click();
}

/**
 * DownloadButton
 */
const DownloadButton = () => {
	const { getNodes } = useReactFlow();

	const onClick = () => {
		const reactFlowViewport = document.querySelector('.react-flow__viewport');
		const container = document.querySelector('.react-flow');

		if (!reactFlowViewport || !container) return;

		const { width, height } = container.getBoundingClientRect();
		const nodesBounds = getNodesBounds(getNodes());
		const viewport = getViewportForBounds(nodesBounds, width, height, 0.5, 2);

		toSvg(reactFlowViewport, {
			background: 'transparent',
			width: width,
			height: height,
			style: {
				width: width,
				height: height,
				transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
			},
			// Includi il font manualmente tramite un CSS inline
			fonts: [
				{
					name: 'Noto',
					css: `
						@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
						body {
							font-family: 'Noto Sans', sans-serif;
						}
					`,
				},
			],
		}).then(downloadImage);
	};

	return (
		<ActionIcon variant={"subtle"} color="white" onClick={onClick} radius={4}>
			<FontAwesomeIcon icon={faImage} style={{ width: "18px" }} />
		</ActionIcon>
	);
};

/**
 * HeaderPanel
 */
export const HeaderPanel = ({position, showGroup, onGroupClick = () => {}}) => {

	const { zoomIn, zoomOut , fitView} = useReactFlow();

	let studioHeatmapEnabled = useStudioStore((state) => state.studioHeatmapEnabled);
	const setStudioHeatmapEnabled = useStudioStore((state) => state.setStudioHeatmapEnabled);

	const { t } = useTranslation();

	return <Panel position={position}>
		<Paper bg={"var(--mantine-color-primary-6)"} radius={4} pt={7} pb={7} pl={16} pr={16}>
			<Group gap={"md"}>
				<Group gap={4}>
					<Icon name={"logo"} style={{marginBottom: "-4px"}}/>
					<Text size={"sm"} fw={700} c={"white"}>FP-Studio</Text>
				</Group>
				<Group gap={4}>
					<Text onClick={zoomIn}>+</Text>
					<Text onClick={zoomOut}>-</Text>
					<Text onClick={fitView}>[]</Text>
				</Group>
				<Group gap={4}>
					<DownloadButton />
					{showGroup &&
						<Text onClick={onGroupClick}>Group</Text>
					}
					<Text onClick={() => setStudioHeatmapEnabled(!studioHeatmapEnabled)}>HM</Text>
				</Group>
			</Group>
		</Paper>
	</Panel>
}