/**
 * Adjusts the brightness of an RGB color string by a percentage.
 * @param {string} rgbString - RGB color string in "rgb(r, g, b)" format.
 * @param {number} percent - Percentage to adjust brightness (-100 to 100).
 * @returns {string} - Adjusted RGB color string in "rgb(r, g, b)" format.
 */
export function adjustRgbBrightness(rgbString, percent) {

    // Step 1: Extract R, G, B values from the input string using regex
    const match = rgbString.match(/\d+/g);

    if (!match || match.length !== 3) {
        throw new Error("Invalid RGB string format");
    }

    let [r, g, b] = match.map(Number); // Convert to numbers

    // Step 2: Clamp the percentage between -100 and 100
    const clampPercent = Math.max(-100, Math.min(100, percent)) / 100;

    // Step 3: Adjust the brightness of each component
    const adjustComponent = (component) => {
        return Math.round(
            component + (clampPercent > 0
                ? (255 - component) * clampPercent // Lighter
                : component * clampPercent)       // Darker
        );
    };

    return {
        r: Math.max(0, Math.min(255, adjustComponent(r))),
        g: Math.max(0, Math.min(255, adjustComponent(g))),
        b: Math.max(0, Math.min(255, adjustComponent(b)))
    }
}

/**
 * Helper function to convert HEX color to RGB.
 * @param {string} hex - The HEX color code.
 * @returns {object} - An object with r, g, b values.
 */
export function hexToRgb(hex) {

    let parsedHex = hex.replace("#", "");

    if (parsedHex.length === 3) {
        parsedHex = parsedHex.split("").map((char) => char + char).join("");
    }

    const bigint = parseInt(parsedHex, 16);

    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
    };
}

/**
 * Helper function to interpolate between two colors based on a factor.
 * @param {string} color1Hex - The starting HEX color.
 * @param {string} color2Hex - The ending HEX color.
 * @param {number} factor - Interpolation factor between 0 and 1.
 * @returns {string} - Interpolated RGB color in CSS format.
 */
export function interpolateColor(color1Hex, color2Hex, factor) {

    const color1 = hexToRgb(color1Hex);
    const color2 = hexToRgb(color2Hex);

    return {
        r: Math.round(color1.r + (color2.r - color1.r) * factor),
        g: Math.round(color1.g + (color2.g - color1.g) * factor),
        b: Math.round(color1.b + (color2.b - color1.b) * factor)
    }
}

/**
 * toRgb
 */
export function toRgb(color) {
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
}